import type { AnyObject, Component, ComponentTagDictionary, ComponentUIDDictionary, SetProperties, UID, ComponentsStore } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { defineStore } from 'pinia'
import { useLogger, ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { cast, collectByTagAndUID, difference, forEachObject, forEachSlotOfComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { useProtocolStore } from '@/stores/protocol'
import { computed, ref, toRef } from 'vue'

const log = useLogger('stores/components', useLogger.COLOR_STORE)

type TagRewriteDictionary = {
	[tag: string]: string | ((component: Component) => string)
}

/**
 * Object for storing tag name rewrites (when a component was renamed, staying compatible with older backups)
 * Every renamed components needs both the full tag and the normalized tag!
 */
let TAG_REWRITES: TagRewriteDictionary = {
	'acquisit-product-person-chooser': (component) => {
		if (!component.properties?.component_uids?.length) {
			return 'acquisit-product-person-chooser-legacy'
		}
		
		return component.tag
	},
	
	'product-person-chooser': (component) => {
		if (!component.properties?.component_uids?.length) {
			return 'product-person-chooser-legacy'
		}
		
		return component.tag_normalized
	},
	
	'acquisit-person-collection': 'acquisit-persons-editor',
	'person-collection': 'persons-editor'
}

if (import.meta.env.MODE == 'test') {
	TAG_REWRITES = {
		'acquisit-rename-test-fn': (component) => {
			return 'acquisit-done-test-fn'
		},
		
		'rename-test-fn': (component) => {
			return 'done-test-fn'
		},
		
		'acquisit-rename-test-string': 'acquisit-done-test-string',
		'rename-test-string': 'done-test-string',
	}
}

/**
 * Object for storing reserved UIDs. Must use UID as key and true as value for faster access
 * @type {{[uid: string]: true}}
 */
const RESERVED_UIDS = {
	'_page-help': true
}

export const isUIDReserved = uid => !!RESERVED_UIDS[uid]

/*
	Tag Renames
 */

/**
 * Get the new tag after a rename for a tag
 * If there was no rename, the original tag is returned
 *
 * @param {string} originalTag
 * @param {Component} component
 *
 * @returns {string}
 */
export const getTagRename = (originalTag: string, component: Component) => {
	if (typeof(TAG_REWRITES[originalTag]) === 'function') {
		return (TAG_REWRITES[originalTag] as Function)(component)
	}

	return TAG_REWRITES[originalTag] ?? originalTag
}

/**
 * Apply any renames to a component and return the result new tag
 *
 * @param {Component}   component
 * @param {boolean}     normalized  If true, uses normalized tag (and returns it), otherwise regular tag
 * @param {boolean}     recursive   If true, applies renames to children as well
 * @returns {*}
 */
export const applyTagRenamesForComponent = (component, normalized = false, recursive: boolean = false) => {
	let tag = normalized ? component.tag_normalized : component.tag,
		newTag = getTagRename(tag, component)

	if (tag != newTag) {
		log.debugFrom('applyTagRenamesForComponent', 'Renaming', tag, 'to', newTag)

		if (normalized) {
			component.tag_normalized = newTag
		} else {
			component.tag = newTag
		}
	}
	
	if (recursive) {
		forEachSlotOfComponent(component, (children) => {
			applyTagRenames(children)
		})
	}

	return newTag
}

export const applyTagRenames = components => {
	for (let component of components) {
		applyTagRenamesForComponent(component, false, true)
		applyTagRenamesForComponent(component, true, true)
	}
}

/*
 Backup Cleaning
 */

export const cleanComponentsForBackup = (components, withChildren = true) => {
	for (let component of components) {
		cleanComponentForBackup(component, withChildren)
	}
}

export const cleanComponentForBackup = (component, withChildren = true) => {
	component.properties.errors = null
	
	switch (component.tag_normalized) {
		case 'persons-layout':
			// Clean value
			if (typeof(component.properties.modelValue) == 'object') {
				for (let personID in component.properties.modelValue) {
					if (component.properties.modelValue.hasOwnProperty(personID)) {
						cleanComponentsForBackup(component.properties.modelValue[personID].components, true)
					}
				}
			}
			
			break
		
		case 'items-input':
		case 'items-editor':
			// Clean items
			if (Array.isArray(component.properties.modelValue)) {
				for (let item of component.properties.modelValue) {
					cleanComponentsForBackup(item.components, true)
				}
			}
			
			break
	}
	
	if (withChildren) {
		forEachSlotOfComponent(component, (children) => {
			cleanComponentsForBackup(children, true)
		})
	}
}

// Input cleaning

/**
 * A default SetProperties function
 *
 * @param {Component} component
 * @param {AnyObject} properties
 */
export const setProperties: SetProperties = (component: Component, properties: AnyObject) => {
	forEachObject(properties, (val, key) => {
		component.properties[key] = val
	})
}

// Store

export interface ComponentsStoreState {
	value_updates_disabled: string[]|boolean
}

export const useComponentsStore = defineStore({
	id: 'components',
	
	state: () => cast<ComponentsStoreState>({
		value_updates_disabled: false
	}),
	
	getters: {
		by_tag_and_uid: () => {
			const protocolStore = useProtocolStore()
			let allComponents: Component[] = []
			
			const findAllComponents = (components: Component[]) => {
				let all: Component[] = components
				
				for (let component of components) {
					if (Array.isArray(component.children?.default)) {
						all = all.concat(findAllComponents(component.children.default))
					}
				}
				
				return all
			}
			
			for (let page of protocolStore.pages) {
				allComponents = allComponents.concat(findAllComponents(page.components))
			}
			
			return collectByTagAndUID(allComponents)
		},
		
		/**
		 * Get all components by UID
		 */
		by_uid(): ComponentUIDDictionary {
			let byUID = {}
			
			for (let tag in this.by_tag_and_uid) {
				if (this.by_tag_and_uid.hasOwnProperty(tag)) {
					for (let uid in this.by_tag_and_uid[tag]) {
						if (this.by_tag_and_uid[tag].hasOwnProperty(uid)) {
							byUID[uid] = this.by_tag_and_uid[tag][uid]
						}
					}
				}
			}
			
			return byUID
		},
		
		/**
		 * Get all components by normalized tag name
		 */
		by_tag(): ComponentTagDictionary {
			let byTag = {}
			
			for (let tag in this.by_tag_and_uid) {
				if (this.by_tag_and_uid.hasOwnProperty(tag)) {
					if (!(tag in byTag)) {
						byTag[tag] = []
					}
					
					for (let uid in this.by_tag_and_uid[tag]) {
						if (this.by_tag_and_uid[tag].hasOwnProperty(uid)) {
							byTag[tag].push(this.by_tag_and_uid[tag][uid])
						}
					}
				}
			}
			
			return byTag
		},
		
		signature(): Component|null {
			const signatureComponents = this.by_tag['signature'] ?? []
			
			if (signatureComponents.length) {
				return signatureComponents[0]
			}
			
			return null
		},
		
		/**
		 * Get a backup of this state
		 */
		backup: () => {
			// do not back up
			return {}
		}
	},
	
	actions: {
		/**
		 * Restore a backup
		 *
		 * @param backup
		 */
		restoreBackup(backup) {
			// do nothing
		},
		
		/**
		 * Check whether a component's tag and UID exists in this store
		 *
		 * @param {string} tag
		 * @param {UID} uid
		 *
		 * @returns {boolean}
		 */
		componentExists(tag: string, uid: UID): boolean {
			return !!this.by_tag_and_uid[tag]?.[uid]
		},
		
		/**
		 * Set properties for a given tag and UID
		 *
		 * @param {string} tag
		 * @param {UID} uid
		 * @param {AnyObject} properties
		 */
		setPropertiesForTagAndUID(tag: string, uid: UID, properties: AnyObject) {
			if (!this.componentExists(tag, uid)) {
				log.always.warnFrom('setPropertiesForTagAndUID', 'Could not find component', tag, uid)
				return
			}
			
			setProperties(this.by_tag_and_uid[tag][uid], properties)
		},
		
		/**
		 * Set a property for a given tag and UID
		 *
		 * @param {string} tag
		 * @param {UID} uid
		 * @param {string} property
		 * @param value
		 */
		setPropertyForTagAndUID(tag: string, uid: UID, property: string, value: any) {
			this.setPropertiesForTagAndUID(tag, uid, {
				[property]: value
			})
		},
		
		/**
		 * Set a property for a given component
		 *
		 * @param {Component} component
		 * @param {string} property
		 * @param value
		 */
		setPropertyForComponent(component: Component, property: string, value: any) {
			setProperties(component, {
				[property]: value
			})
		},
		
		/**
		 * Set the errors for a given tag and UID
		 *
		 * @param {string} tag
		 * @param {UID} uid
		 * @param {ValidationError[]} errors
		 */
		setErrorsForTagAndUID(tag: string, uid: UID, errors: ValidationError[]) {
			if (!this.componentExists(tag, uid)) {
				log.always.warnFrom('setErrorsForTagAndUID', 'Could not find component', tag, uid)
				return
			}
			
			this.setErrorsForComponent(this.by_tag_and_uid[tag][uid], errors)
		},
		
		
		/**
		 * Set the errors for a given component
		 *
		 * @param {Component} component
		 * @param {ValidationError[]} errors
		 */
		setErrorsForComponent(component: Component, errors: ValidationError[]) {
			this.setPropertyForComponent(component, 'errors', errors)
		},
		
		/**
		 * Enable value updates
		 *
		 * @param {string[] | true} tags    Either an array of tags to enable or true to enable all
		 */
		enableValueUpdates(tags: string[]|true) {
			if (this.value_updates_disabled === true) {
				this.value_updates_disabled = false
			} else if (Array.isArray(this.value_updates_disabled) && Array.isArray(tags)) {
				this.value_updates_disabled = difference(this.value_updates_disabled, tags)
			} else {
				this.value_updates_disabled = []
			}
		},
		
		/**
		 * Disable value updates
		 *
		 * @param {string[] | true} tags    Either an array of tags to disable or true to disable all
		 */
		disableValueUpdates(tags: string[]|true) {
			if (Array.isArray(tags)) {
				const base = Array.isArray(this.value_updates_disabled) ? this.value_updates_disabled : []
				this.value_updates_disabled = base.concat(tags)
			} else {
				this.value_updates_disabled = true
			}
		},
		
		/**
		 * Mark a component as validated by its tag and UID
		 *
		 * @param {string} tag
		 * @param {UID} uid
		 */
		validateTagAndUID(tag: string, uid: UID) {
			if (!this.componentExists(tag, uid)) {
				log.always.warnFrom('validateTagAndUID', 'Could not find component', tag, uid)
				return
			}
			
			this.validateComponent(this.by_tag_and_uid[tag][uid])
		},
		
		/**
		 * Mark a component as validated
		 *
		 * @param {Component} component
		 */
		validateComponent(component: Component) {
			component.validated = true
		},
		
		/**
		 * Invalidate a component by its tag and UID
		 *
		 * @param {string} tag
		 * @param {UID} uid
		 */
		invalidateTagAndUID(tag: string, uid: UID) {
			if (!this.componentExists(tag, uid)) {
				log.always.warnFrom('invalidateTagAndUID', 'Could not find component', tag, uid)
				return
			}
			
			this.invalidateComponent(this.by_tag_and_uid[tag][uid])
		},
		
		/**
		 * Invalidate a component
		 *
		 * @param {Component} component
		 */
		invalidateComponent(component: Component) {
			component.validated = false
		}
	}
})

export const createAcquisitUIComponentsStoreAdapter = (): ComponentsStore => {
	return {
		byUID: toRef(() => useComponentsStore().by_uid)
	}
}