import { defineStore } from 'pinia'
import { cast } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

export interface UIState {
	loader: {
		loading: boolean
		label: string|null
	}
	
	open_modals: number
	
	is_mobile: boolean
	is_landscape: boolean
	
	meta_title: string
	meta_apple_title_element: HTMLMetaElement|null
}

const isMobile = (): boolean => {
	if (typeof window !== 'undefined') {
		return window.matchMedia?.('screen and (max-width: 600px), (max-width: 820px) and (max-height: 700px)').matches ?? false
	}
	
	return false
}

const isLandscape = (): boolean => {
	if (typeof window !== 'undefined') {
		return window.matchMedia?.('screen and (orientation: landscape)').matches ?? false // have to use matchMedia because Safari lags behind
	}
	
	return false
}

export const useUIStore = defineStore({
	id: 'ui',
	
	state: () => cast<UIState>({
		loader: {
			loading: false,
			label: null
		},
		
		open_modals: 0,
		
		is_mobile: isMobile(),
		is_landscape: isLandscape(),
		
		meta_title: document.title,
		meta_apple_title_element: document.head.querySelector('meta[name=apple-mobile-web-app-title]')
	}),
	
	getters: {
		// All values in here are to be re-generated, so don't back them up
		backup: () => {}
	},
	
	actions: {
		setLoader(config: boolean|string|{ state: boolean, label: string|null }) {
			if (typeof(config) === 'boolean') {
				this.loader.loading = config
				this.loader.label = null
			} else if (typeof(config) === 'string') {
				this.loader.loading = true
				this.loader.label = config
			} else {
				this.loader.loading = config.state
				this.loader.label = config.label
			}
		},
		
		/**
		 * Enable the loader UI
		 *
		 * @param {string} label
		 */
		enableLoader(label: string|null) {
			this.setLoader(label ?? true)
		},
		
		/**
		 * Disable the loader UI
		 */
		disableLoader() {
			this.setLoader(false)
		},
		
		increaseOpenModals() {
			this.open_modals += 1
		},
		
		decreaseOpenModals() {
			this.open_modals = Math.max(0, this.open_modals - 1)
		},
		
		registerResizeListener() {
			window.addEventListener('resize', () => {
				this.is_mobile = isMobile()
				this.is_landscape = isLandscape()
			})
		},
		
		setMetaTitle(title: string) {
			document.title = title
			
			if (this.meta_apple_title_element) {
				this.meta_apple_title_element.content = title
			}
		},
		
		resetMetaTitle() {
			document.title = this.meta_title
			
			if (this.meta_apple_title_element) {
				this.meta_apple_title_element.content = this.meta_title
			}
		},
		
		restoreBackup() {
			this.meta_title = document.title
			this.meta_apple_title_element = document.head.querySelector('meta[name=apple-mobile-web-app-title]')
		}
	}
})