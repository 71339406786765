import { createApp, defineComponent, h, ref } from 'vue'
import { easeInOutQuad } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import Button from '@ui/core/Button.vue'
import VSvg from '@/components/VSvg.vue'
import { Spinner, String, Modal, SVG_LOAD, LANGUAGE, FRONTEND, type Frontend } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { loadSVG } from '@/lib/svg'
import { useProtocolStore } from '@/stores/protocol'

export const errorMessage = (
	content: string,
	title: string|undefined = undefined,
	timer: number = 0,
	showCancelButton = true,
	showCloseButton = false,
	ultrawide: boolean = false
) => new Promise(async (resolve, reject) => {
	const component = defineComponent({
		name: 'Error',
		
		mounted() {
	        (this.$refs.$modal as any).openModal()
		},
		
		render() {
			return h(Modal, {
				title,
				
				full_page: ultrawide,
				show_close_button: showCloseButton,
				// title_icon: 'acq/cross',
				color: 'red',
				ref: '$modal',
				
				onAccept: () => {
					resolve(true)
				}
			}, {
				accept: () => showCancelButton ? h('span', 'OK') : null,
				
				default: () => {
					return [
						/*h(VSvg, {
							file: 'acq/cross',
							color: 'red',
							style: {
								width: '100%',
								height: '64px',
								marginBottom: '24px'
							}
						}),*/
						
						h('div', {
							class: 'global-error-content',
							innerHTML: content,
							style: {
								textAlign: ultrawide ? null : 'center',
								lineHeight: '1.5'
							}
						})
					]
				}
			})
		}
	})
	
	let app = createApp(component)
	
	app.use((<any> window).__pinia)
	
	app.provide(SVG_LOAD, loadSVG)
	app.provide(LANGUAGE, ref(useProtocolStore().language))
	app.provide<Frontend>(FRONTEND, {
		getRootContainer(): HTMLElement {
			return document.querySelector('#independent-modals')!
		},
		
		getRootHeader(): HTMLElement | undefined {
			return undefined
		},
		
		getRootFooter(): HTMLElement | undefined {
			return undefined
		},
	})
	
	// Bootstrap necessary UI components
	app.component('VSvg', VSvg)
	app.component('acquisit-button', Button)
	app.component('acquisit-string', String)
	app.component('acquisit-spinner', Spinner)
	app.component('acquisit-modal', Modal)
	
	// Run the instance, open the modal
	// The element can't be named "#global-modals", because Vite replaces *any* occurrence of "global" with "window". Boo.
	const instance = app.mount('#independent-modals')
	
	if (!instance?.$refs?.$modal) {
		console.error(content, title)
		throw new Error('Could not find error modal')
	}
	
	if (timer > 0) {
		setTimeout(() => {
			(instance.$refs.$modal as any).closeModal()
		}, timer)
	}
})

export const scrollElementTo = (element: HTMLElement, to: number, direction: 'top'|'left' = 'top', duration: number = 300) => {
	let scrollProperty
	
	switch (direction) {
		case 'top':
			scrollProperty = 'scrollTop'
			break
		
		case 'left':
			scrollProperty = 'scrollLeft'
			break
		
		default:
			throw new Error('Unsupported direction: ' + direction)
	}
	
	let start = element[scrollProperty],
		change = to - start,
		currentTime = 0,
		increment = 20
	
	if (duration === 0) {
		element[scrollProperty] = to
	} else {
		let animateScroll = () => {
			currentTime += increment
			element[scrollProperty] = easeInOutQuad(currentTime, start, change, duration)
			
			if (currentTime < duration) {
				setTimeout(animateScroll, increment)
			}
		}
		
		animateScroll()
	}
}