import type { Person } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { labelFor, ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { usePersonsStore } from '@/stores/persons'
import { useComponentsStore } from '@/stores/components'
import { useProtocolStore } from '@/stores/protocol'

export const validatePerson = async (person: Person): Promise<boolean> => {
	const personsStore = usePersonsStore(),
		componentsStore = useComponentsStore(),
		protocolStore = useProtocolStore()
	
	const signatureComponents = componentsStore.by_tag['signature'],
		signatureComponent = signatureComponents[0] ?? null
	
	if (person.signature) {
		if (signatureComponent?.properties.contents_agreement_label && !person.content_agreed) {
			throw new ValidationError(
				labelFor(signatureComponent.validation_error_labels?.person_contents_agreement_missing, protocolStore.language, 'Contents have not been agreed to')!,
				signatureComponent,
				'content_agreed',
				{
					component_uid: signatureComponent.properties.uid,
					person_id: person.id,
				},
				Boolean(signatureComponent.validation_error_labels?.person_contents_agreement_missing)
			)
		}
		
		personsStore.validatePerson(person)
		return true
	} else {
		if (signatureComponent) {
			throw new ValidationError(
				labelFor(signatureComponent.validation_error_labels?.person_signature_missing, protocolStore.language, 'Signature missing')!,
				signatureComponent,
				'signature',
				{
					component_uid: signatureComponent.properties.uid,
					person_id: person.id,
				},
				Boolean(signatureComponent.validation_error_labels?.person_signature_missing),
			)
		} else {
			throw new Error('Signature missing for ' + person.id)
		}
	}
}

