<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-markdown-tester default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>Markdown Tester</h2>
			</template>
			
			<template #actions>
			
			</template>
		</StateInspectorHeader>
		
		<div class="two-pane">
		   <div class="editor">
			   <textarea v-model="value"
						 placeholder="Markdown" />
		   </div>
			
			<div class="preview">
				<acquisit-string :source="value || '– Enter Markdown on the left –'" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { ref } from 'vue'
	
	const value = ref<string>('')
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-markdown-tester {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;
		
		.two-pane {
			flex-grow: 1;
			
			display: flex;
			align-items: stretch;
			justify-content: stretch;
			gap: 16px;
			
			.editor {
				width: 50%;
				
				textarea {
					box-sizing: border-box;
					width: 100%;
					height: 100%;
					resize: none;
					background: transparent;
					color: #fff;
					border: 0px;
					padding: 20px;
					outline: 0;
					
					position: relative;
					z-index: 2;
					
					border-radius: 6px;
					
					min-height: 240px;
					
					&:focus {
						box-shadow: 0px 0px 0px 2px color("blue");
					}
				}
			}
			
			.preview {
				box-sizing: border-box;
				width: 50%;
				padding: 20px;
				background: color("dark-background");
				border-radius: 6px;
				
				position: relative;
				z-index: 1;
			}
		}
	}
</style>