<!--
	@author bluefirex
-->
<template>
	<section :class="['acquisit-state-inspector-menu-box', { open: collapsible && isOpen }]">
		<header @click="onHeaderClick">
			<v-svg :file="icon" v-if="icon" class="icon" />
			<h3>{{ label }}</h3>
			<acquisit-button :icon="isOpen ? 'chevrons/up' : 'chevrons/down'" type="icon" color="lightgreyblue" v-if="collapsible" />
		</header>
		
		<transition name="move-fade-top">
			<div class="children" v-show="isOpen">
				<slot></slot>
			</div>
		</transition>
	</section>
</template>

<script setup lang="ts">
	import { ref } from 'vue'
	
	interface Props {
		label: string
		icon?: string|null
		collapsible?: boolean|null
	}
	
	const props = withDefaults(defineProps<Props>(), {
		collapsible: false
	})
	
	const emit = defineEmits([ 'click' ])
	
	const isOpen = ref(!props.collapsible)
	
	const onHeaderClick = (e) => {
		if (props.collapsible) {
			isOpen.value = !isOpen.value
		} else {
			emit('click', e)
		}
	}
	
	const open = () => {
		isOpen.value = true
	}
	
	const close = () => {
		isOpen.value = false
	}
	
	defineExpose({ open, close })
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-menu-box {
	
		header {
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 12px;
			
			h3 {
				flex-grow: 1;
			}
			
			.icon {
				width: 20px;
				height: 20px;
			}
			
			&:hover {
				color: color("blue");
			}
		}
		
		&.active header {
			color: color("blue");
		}
		
		.children {
			margin-top: 8px;
		}
	}
</style>