import type { Component } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { fetchIcon, PDF } from '@/lib/pdf/pdf'
import { find } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

/**
 * Render a component into a PDF
 *
 * @param context
 */
export const renderComponent = async (context: RendererContext): Promise<void> => {
	let renderer = renderers[context.component.tag_normalized] || renderDefault
	
	if (window.DEBUG) {
	    console.debug('%c[renderers.ts, renderComponent]', 'color:green', context.component.properties.uid, 'using renderer', renderer, context.previous, context.next)
	}
	
	await renderer(context)
}

/**
 * Render multiple components into a PDF
 *
 * @param {PDF}             pdf         PDF to render into
 * @param {Component[]}     components  Components to render
 * @param {Function}        filter      Filter to ignore certain components
 * @returns {Promise<void>}
 */
export const renderComponents = async (pdf: PDF, components: Component[], filter?: (component: Component) => boolean): Promise<void> => {
	let previousComponent: Component|null = null
	
	if (filter) {
		components = components.filter(filter)
	}
	
	for (let i = 0, max = components.length; i < max; i++) {
		let component = components[i],
			nextComponent = components[i + 1] ?? null
		
		await renderComponent({
			pdf,
			component,
			previous: previousComponent,
			next: nextComponent
		})
		
		previousComponent = component
	}
}

export const renderDefault = async ({ pdf, component }: RendererContext): Promise<void> => {
	if (!component.properties.modelValue) {
		return
	}
	
	pdf.setBodyFont()
	
	let value = component.properties.modelValue
	
	if (typeof(value) == 'object') {
		value = JSON.stringify(value) // TODO Find better way to render generic objects
		pdf.drawText(value)
	} else if (Array.isArray(value)) {
		for (let val in value) {
			pdf.drawText(val)
		}
	} else {
		pdf.drawText(value)
	}
}

export const renderInput = async ({ pdf, component }: RendererContext): Promise<void> => {
	pdf.setBodyFont()
	
	if (component.properties.label) {
		pdf.drawKeyValueLabel(
			pdf.trimStringForDisplay(pdf.parseLabel(component.properties.label) ?? ''),
			pdf.trimStringForDisplay(component.properties.modelValue || '–')
		)
	} else {
		pdf.drawText(pdf.trimStringForDisplay(pdf.parseLabel(component.properties.label) || ''))
	}
}

export const renderTextarea = async (context: RendererContext): Promise<void> => {
	// Same formatting, actually…
	await renderInput(context)
}

export const renderSpan = async ({ pdf, component }: RendererContext): Promise<void> => {
	let label = pdf.trimStringForDisplay(pdf.parseLabel(component.properties.label) || '')
	
	if (!label) {
		return
	}
	
	if (component.properties.bold || (component.properties.weight ?? 400) >= 600) {
		pdf.setBoldFont()
	} else {
		pdf.setBodyFont()
	}
	
	pdf.drawText(label)
}

export const renderItemsInput = async ({ pdf, component }: RendererContext): Promise<void> => {
	pdf.setBodyFont()
	
	let i = 0,
		itemsLength = (component.properties.modelValue || []).length
	
	pdf.drawLine({
		x: pdf.sizes.headerX,
		y: pdf.y
	}, {
		x: pdf.pageWidth - pdf.sizes.headerX,
		y: pdf.y
	})
	
	for (let item of component.properties.modelValue || []) {
		await renderComponents(pdf, item.components)
		
		if (i < itemsLength - 1) {
			pdf.drawLine({
				x: pdf.sizes.headerX,
				y: pdf.y
			}, {
				x: pdf.pageWidth - pdf.sizes.headerX,
				y: pdf.y
			})
		}
		
		i++
	}
}

export const renderItemsEditor = async ({ pdf, component }: RendererContext): Promise<void> => {
	let items = component.properties.modelValue || {},
		i = 0,
		itemsLength = items.length
	
	if (!itemsLength) {
		return
	}
	
	const iconIndent = 28
	
	for (let item of items) {
		let title: string|null = null,
			subtitle: string|null = null,
			icon = item.icon
		
		if (item.title_reference_uid) {
			let titleComponent = find(item.components, item.title_reference_uid)
			
			if (titleComponent) {
				title = pdf.trimStringForDisplay(titleComponent.properties.modelValue)
				subtitle = pdf.trimStringForDisplay(item.title) || null
				
				if (title == subtitle) {
					subtitle = null
				}
			} else {
				title = item.title || null
			}
		} else {
			title = item.title || null
		}
		
		if (icon) {
			pdf.moveCursorRightBy(iconIndent)
			icon = await fetchIcon(icon)
			
			pdf.drawImageAt({
				x: pdf.x - iconIndent,
				y: pdf.y - 5
			}, icon, {
				width: 20,
				height: 20
			})
		}
		
		if (subtitle) {
			pdf.setSubtextFont(pdf.sizes.smallFont)
			pdf.drawText(subtitle, {
				indent: 0,
				lineGap: 4
			})
		}
		
		if (title) {
			pdf.setBoldFont(pdf.sizes.headerFont)
			pdf.drawText(title, {
				indent: 0
			})
		}
		
		if (icon) {
			pdf.moveCursorRightBy(-iconIndent)
		}
		
		pdf.moveCursorDownBy(4)
		await renderComponents(pdf, item.components, c => c.properties.uid != item.title_reference_uid)
		pdf.moveCursorDown(i < itemsLength - 1 ? 2 : 1)
		
		i++
	}
}

export interface RendererContext {
	/**
	 * PDF to render into
	 */
	pdf: PDF
	/**
	 * Component to render
	 */
	component: Component
	/**
	 * Component preceding this one
	 */
	previous?: Component|null
	/**
	 * Component succeeding this one
	 */
	next?: Component|null
}

/**
 * All available custom renderers
 * If a tag does not appear here, default renderer should be used
 *
 * @type {RendererDictionary}
 */
export const renderers: RendererDictionary = {
	'input': renderInput,
	'textarea': renderTextarea,
	'span': renderSpan,
	'items-editor': renderItemsEditor,
	'items-input': renderItemsInput,
}

export type RendererDictionary = {[tag: string]: typeof renderDefault}
