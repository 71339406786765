<!--
    @author bluefirex
    @date 24.06.21
-->
<template>
	<div :class="['acquisit-custom-signature-person-chooser']">
		<div class="header">
			<slot name="header"></slot>
		</div>
		
		<ul>
			<template v-for="group in personsGrouped">
				<li :class="['person-type', { validated: group.validated }]" v-if="group.label"><h2>{{ language.parse(group.label) }}</h2></li>
			
				<li v-for="person in group.persons"
				    @click="selectPerson(person)"
				    :tabindex="-1"
				    :class="['person', {
				    	signed: colorize_signature_status && (personIsSignedForThemselves(person)) || personIsSignedOnBehalf(person),
				    	'on-behalf': personIsSignedOnBehalf(person),
				    	error: erroring && personHasErrors(person),
				    	'has-errors': personHasErrors(person),
				    	'selecting-multiple': selecting_multiple,
				    	disabled: selecting_multiple && personIsSignedForThemselves(person)
				    }]">
					<transition-group class="person-wrapper" name="person-selection-transition" tag="div" :tabindex="0" @keyup.enter="selectPerson(person)">
						<div v-if="selecting_multiple && !personIsSignedForThemselves(person)" class="checkbox" key="checkbox">
							<acquisit-checkbox :modelValue="Boolean(selectedPersonsByID[person.id])"
							                   :color="personHasErrors(person) && erroring ? 'red' : null"
							                   :disabled="personIsSignedForThemselves(person)" />
						</div>
						
						<div v-else class="list-icon" key="icon">
							<v-svg :file="getIconForPerson(person)" class="person-icon" />
						</div>
						
						<div class="meta" key="meta">
							<span class="name">{{ formatPersonName(person) }}</span>
							
							<template v-if="visibleErrorsByPerson[person.id]?.length">
								<ComponentError v-for="error in visibleErrorsByPerson[person.id]"
								                :error="error" />
							</template>
							
							<template v-else-if="person.validated && person.signature && person.signature_type">
								<span class="sign-status signed on-behalf" v-if="person.on_behalf && person.on_behalf.myself === false && signed_on_behalf_label">
									{{ language.parse(signed_on_behalf_label) }} ({{ person.on_behalf.name }})
								</span>
								
								<span class="sign-status signed" v-else-if="signed_label">
									{{ language.parse(signed_label) }}
								</span>
							</template>
							
							<span class="sign-status absent" v-else-if="person.validated && person.absent && absent_label">
								{{ language.parse(absent_label) }}
							</span>
						
							<span class="sign-status unsigned" v-else-if="unsigned_label">
								{{ language.parse(unsigned_label) }}
							</span>
						</div>
						
						<div class="indicator" v-if="!selecting_multiple" key="indicator">
							<v-svg file="chevrons/right" class="indicator-icon" />
						</div>
					</transition-group>
				</li>
			</template>
		</ul>
		
		<slot></slot>
	</div>
</template>

<script setup lang="ts">
	import type { PropType } from 'vue'
	import type { Person, UILabelOptional, ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { ComponentError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { createRecord } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useBaseComponent, useBaseComponentEmits, useBaseComponentProps } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { computed, onMounted, ref, watch } from 'vue'
	import type { ParsedSignature } from '@/lib/types'
	import { parseSignature } from '@/stores/persons'
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		persons: {
			type: Array as PropType<Person[]>,
			required: true
		},
		
		person_type_labels: {
			type: Object,
			required: false,
			default: null
		},
		
		colorize_signature_status: {
			type: Boolean,
			required: false,
			default: false
		},
		
		unsigned_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		signed_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		signed_on_behalf_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		absent_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		selecting_multiple: {
			type: Boolean,
			required: false,
			default: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'select-single',
		'select-multiple'
	])
	
	const base = useBaseComponent(props, emit)
	const { color, erroring, language } = base
	
	const selectedPersons = ref<Person[]>([])
	
	const parsedSignatures = computed(() => {
		let signatures: Record<string, ParsedSignature|null> = {}
		
		for (let person of props.persons) {
			signatures[person.id] = parseSignature(person.signature)
		}
		
		return signatures
	})
	
	const errorsMapped = computed(() => {
		let mapped = {
			on_behalf: [] as ValidationError[],
			not_validated: [] as ValidationError[],
			signature_missing: [] as ValidationError[],
			duplicate_signatures: {} as Record<string, ValidationError>
		}
		
		const errors = Array.isArray(props.errors) ? props.errors : []
		
		for (let error of errors) {
			switch (error.key) {
				case 'all_required_validated':
					mapped.not_validated.push(error)
					break
				
				case 'required_signatures':
					mapped.signature_missing.push(error)
					break
				
				case 'duplicate_signature':
					mapped.duplicate_signatures[error.owner.person_id] = error
					break
				
				default:
					mapped.on_behalf.push(error)
			}
		}
		
		return mapped
	})
	
	const selectedPersonsByID = computed(() => createRecord(selectedPersons.value, p => p.id))
	
	const personsGrouped = computed(() => {
		let groups: Record<string, { label: UILabelOptional, persons: Person[], validated: boolean }> = {}
		
		for (let person of props.persons) {
			if (!groups[person.type]) {
				groups[person.type] = {
					label: props.person_type_labels?.[person.type] || null,
					persons: [],
					validated: false
				}
			}
			
			groups[person.type].persons.push(person)
		}
		
		for (let type in groups) {
			if (groups.hasOwnProperty(type)) {
				let allValidated = true
				
				for (let person of groups[type].persons) {
					if (!person.validated) {
						allValidated = false
						break
					}
				}
				
				groups[type].validated = allValidated
			}
		}
		
		return groups
	})
	
	const visibleErrorsByPerson = computed(() => {
		const byPerson: Record<string, ValidationError[]> = {}
		const allErrors: ValidationError[] = props.errors ?? []
		
		for (let error of allErrors) {
			if (error.owner?.person_id && error.isUserVisible) {
				const personID = error.owner.person_id
				
				if (!Array.isArray(byPerson[personID])) {
					byPerson[personID] = []
				}
				
				byPerson[personID].push(error)
			}
		}
		
		return byPerson
	})
	
	const emitSingle = () => {
		emit('select-single', selectedPersons.value)
	}
	
	const emitMultiple = () => {
		emit('select-multiple', selectedPersons.value)
	}
	
	const selectPerson = (person: Person) => {
		if (selectedPersonsByID.value[person.id]) {
			// Unselect
			selectedPersons.value = selectedPersons.value.filter(p => p.id != person.id)
			
			if (props.selecting_multiple) {
				emitMultiple()
			} else {
				emitSingle()
			}
		} else if (props.selecting_multiple) {
			// Add selection
			selectedPersons.value.push(person)
			emitMultiple()
		} else {
			// Absolute selection
			selectedPersons.value = [ person ]
			emitSingle()
		}
	}
	
	const formatPersonName = (person: Person|null|undefined) => {
		if (!person || (!person.firstname && !person.name)) {
			return null
		}
		
		return [ person.firstname, person.name ].filter(x => !!x).join(' ')
	}
	
	const personHasErrors = (person: Person) => {
		return (
			// Person not validated
			(!person.validated && errorsMapped.value.not_validated.length) ||
			// Person absent but signature required
			(person.absent && errorsMapped.value.signature_missing.length) ||
			// Person has a duplicate signature
			person.id in errorsMapped.value.duplicate_signatures
		)
	}
	
	const getIconForPerson = (person: Person) => {
		if (props.selecting_multiple) {
			return 'acq/person'
		} else if (personHasErrors(person)) {
			return 'acq/cross'
		} else if (person.validated && person.absent) {
			return 'acq/signature-absent'
		} else if (person.validated && person.signature) {
			return 'acq/checkmark'
		}
		
		return 'acq/person'
	}
	
	const personIsSignedForThemselves = (person: Person) => Boolean(person.validated && person.signature && person.on_behalf?.myself !== false && !person.absent)
	const personIsSignedOnBehalf = (person: Person) => Boolean(person.validated && person.signature && person.on_behalf?.myself === false)
	
	watch(() => props.selecting_multiple, (val) => {
		if (!val) {
			selectedPersons.value = []
			emitMultiple()
		}
	})
	
	onMounted(() => {
		emitMultiple()
	})
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-custom-signature-person-chooser {
		$offset: 24px;
		
		ul {
			margin: {
				left: -$offset;
				right: -$offset;
				bottom: 24px;
			}
			
			@include media(mobile) {
				margin-top: -20px;
			}
		}
		
		.person-type {
			padding: 12px $offset;
			transition: color 0.2s;
			border-bottom: 1px solid color("border");
			
			padding-top: 12px + 16px;
			
			h2 {
				font-weight: 600;
				font-size: 1.067rem;
			}
			
			&.validated {
				// color: color("green");
			}
			
			&:first-child {
				padding-top: 0;
			}
		}
		
		.person {
			line-height: 1.5;
			cursor: pointer;
			transition: background 0.2s, color 0.2s;
			
			@include unselectable;
			
			& + .person, & + .person-type {
				border-top: 1px solid color("border");
			}
			
			@include media(mobile) {
				&:last-child {
					border-bottom: 1px solid color("border");
				}
			}
			
			.person-wrapper {
				display: flex;
				align-items: center;
				
				padding: {
					top: 12px;
					bottom: 12px;
					left: $offset;
					right: $offset;
				}
			}
			
			.person-icon {
				width: 22px;
				height: 24px;
				vertical-align: middle;
				margin-top: -1px;
			}
			
			.list-icon {
				width: 36px;
				height: 36px;
				
				align-self: flex-start;
				margin-top: 4px;
				
				display: flex;
				align-items: center;
				justify-content: center;
				
				background: color("light-grey-blue");
				color: #fff;
				
				border-radius: 256px;
				
				transition: background 0.2s, color 0.2s;
			}
			
			.checkbox {
				width: 34px;
				margin-top: 2px;
				
				.acquisit-checkbox {
					margin: auto;
					
					.checkbox-box {
						margin: -3px auto auto auto !important;
					}
				}
				
				.person-icon {
					width: 31px;
					color: color("green");
				}
			}
			
			.list-icon,
			.checkbox,
			.indicator {
				transition: all 0.3s ease;
			}
			
			.meta {
				box-sizing: border-box;
				flex: 1;
				padding-left: 12px;
				
				.name {
					font-weight: 600;
				}
				
				.name,
				.sign-status {
					display: block;
					transition: color 0.2s;
				}
				
				.sign-status {
					transition: color 0.2s;
					
					.with-name {
						display: block;
						margin-top: 8px;
						
						/*&::before {
							content: '•';
							display: inline-block;
							padding: {
								left: 4px;
								right: 4px;
							}
						}*/
					}
					
					.with-name-icon {
						width: 16px;
						height: 16px;
						vertical-align: middle;
						display: inline-block;
						margin-top: -3px;
					}
					
					&.unsigned {
						color: color("light-grey-blue");
					}
					
					&.signed, &.absent {
						color: mix(color("light-grey-blue"), color("green"), 50%);
					}
				}
			}
			
			.indicator {
				color: color("text");
				text-align: right;
				width: 20px;
				
				.indicator-icon {
					width: 20px;
					height: 20px;
				}
			}
			
			&.signed {
				
				.name {
					color: color("green");
				}
				
				.list-icon {
					background: color("green");
					color: #fff;
				}
				
				&.selecting-multiple:not(.on-behalf) {
					background: mix(color("green"), #fff, 5%);
				}
			}
			
			/*&.error, &.has-errors {
				
				.name {
					color: color("red");
				}
				
				.list-icon {
					background: color("red");
					color: #fff;
				}
			}*/
			
			&.error {
				
				.sign-status.signed, .sign-status.unsigned, .sign-status.absent {
					color: color("red");
				}
			}
			
			&:last-child {
				border-bottom: 1px solid color("border");
			}
			
			@include media(mobile) {
				.person-wrapper {
					padding: {
						top: 16px;
						bottom: 16px;
					}
				}
				
				.meta {
					padding-left: 16px;
				}
			}
			
			@media (hover: hover) {
				&:hover {
					cursor: pointer;
					background: color("light-background");
				}
			}
			
			&.disabled {
				pointer-events: none;
			}
		}
	}
	
	.person-selection-transition-enter-from,
	.person-selection-transition-leave-to {
		opacity: 0;
		
		&.list-icon {
			transform: translateX(-24px);
		}
		
		&.checkbox {
			transform: translateX(16px);
		}
	}
	
	.person-selection-transition-leave-to {
		
		&.indicator {
			transform: translateX(100vw);
		}
	}
	
	.person-selection-transition-enter-from {
		
		&.indicator {
			transform: translateX(100px);
		}
	}
	
	.person-selection-transition-leave-active {
		position: absolute;
	}
</style>
