<!--
    @author bluefirex
    @date 24.08.20
-->
<template>
	<component :is="component.tag"
	           v-bind="component.properties"
	           :modelValue="modelValue || component.properties.modelValue"
	           :errors="errors"
	           :no-interact="noInteract"
	           ref="component"
	           @update:modelValue="onInput(component, $event)">
		<template v-if="component.children">
			<template v-for="(children, slot) in component.children">
				<acquisit-generic v-for="child in children"
				                  :slot="slot"
				                  :key="child.properties.uid"
				                  :component="child"
				                  :errors="errors"
				                  @update:modelValue="onInput(child, $event)"
				                  :no-interact="noInteract" />
			</template>
		</template>
	</component>
</template>

<script lang="ts">
	import { normalizeTag } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import type { PropType } from 'vue'
	import { defineComponent } from 'vue'
	import type { ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	
	/**
	 * @deprecated Use ComponentsContainer from AcquisitUI instead
	 */
	export default defineComponent({
		name: 'Generic',
		
		props: {
			component: {
				type: Object,
				required: true
			},
			
			noInteract: {
				type: Boolean,
				required: false,
				default: false
			},
			
			errors: {
				type: [Boolean, Array] as PropType<ValidationError[]|boolean>,
				required: false,
				default: null
			},
			
			modelValue: {
				required: false,
				default: null
			}
		},
		
		emits: [
			'update:modelValue'
		],
		
		data() {
			return {}
		},
		
		computed: {
			tag_normalized() {
				return normalizeTag(this.component.tag)
			}
		},
		
		methods: {
			onInput(component, data) {
				// Was emitted by another Generic, needs to be unwrapped first
				if (data.component && "value" in data) {
					data = data.value
				}
				
				this.$emit('update:modelValue', { component, value: data })
			},
			
			focus() {
				if ((<any> this).$refs.component?.focus) {
					(<any> this).$refs.component.focus()
				} else {
					console.info('No focus available for component', this.tag_normalized)
				}
			}
		},
	})
</script>
