/**
 * Is {@link crypto.subtle} available in this browser and context?
 */
export const isCryptoAvailable = (): boolean => typeof(window.crypto?.subtle) !== 'undefined'

const createBaseKey = async (password: string): Promise<CryptoKey> => {
	const encoder = new TextEncoder()
	
	return await crypto.subtle.importKey('raw', encoder.encode(password), 'PBKDF2', false, [
		'deriveKey'
	])
}

const deriveKey = async (baseKey: CryptoKey, salt: BufferSource, usage: any[]): Promise<CryptoKey> => {
	return await crypto.subtle.deriveKey(
		{
			name: "PBKDF2",
			salt: salt,
			iterations: 250000,
			hash: "SHA-256",
		},
		baseKey,
		{ name: "AES-GCM", length: 256 },
		false,
		usage
	)
}

/**
 * Encrypt a string with a password, using AES-GCM
 *
 * @param {string} str          String to encrypt
 * @param {string} password     Password to encrypt with
 *
 * @returns {Promise<string>}
 */
export const encryptAES_GCM = async (str: string, password: string): Promise<string> => {
	const salt = crypto.getRandomValues(new Uint8Array(16))
	const iv = crypto.getRandomValues(new Uint8Array(12))
	const baseKey = await createBaseKey(password)
	const encryptionKey = await deriveKey(baseKey, salt, ['encrypt'])
	
	const encryptedBuffer = await crypto.subtle.encrypt({
		name: 'AES-GCM',
		iv,
	}, encryptionKey, new TextEncoder().encode(str))
	
	const encryptedBufferArr = new Uint8Array(encryptedBuffer)
	
	let buff = new Uint8Array(
		salt.byteLength + iv.byteLength + encryptedBufferArr.byteLength
	)
	
	buff.set(salt, 0)
	buff.set(iv, salt.byteLength)
	buff.set(encryptedBufferArr, salt.byteLength + iv.byteLength)
	
	return bufferToBase64(buff)
}

/**
 * Decrypt a string with a password, using AES-GCM
 *
 * @param {string} encrypted    Encrypted message
 * @param {string} password     Password to decrypt with
 * @returns {Promise<string>}
 */
export const decryptAES_GCM = async (encrypted: string, password: string): Promise<string> => {
	const encryptedDataBuffer = base64ToBuffer(encrypted)
	const salt = encryptedDataBuffer.slice(0, 16)
	const iv = encryptedDataBuffer.slice(16, 16 + 12)
	const data = encryptedDataBuffer.slice(16 + 12)
	
	const baseKey = await createBaseKey(password)
	const decryptionKey = await deriveKey(baseKey, salt, ['decrypt'])
	const decryptedBuffer = await crypto.subtle.decrypt({
		name: 'AES-GCM',
		iv
	}, decryptionKey, data)
	
	const decoder = new TextDecoder()
	return decoder.decode(decryptedBuffer)
}

const bufferToBase64 = (buffer: Uint8Array): string => window.btoa(
	new Uint8Array(buffer).reduce(
		(data, byte) => data + String.fromCharCode(byte), ''
	)
)

const base64ToBuffer = (base64: string): Uint8Array => Uint8Array.from(window.atob(base64), (c) => c.charCodeAt(null as any))