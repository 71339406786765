import { merge } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

export interface Config {
	/**
	 * Environment acquisit is running in
	 * Either 'development', 'staging' or 'production'
	 */
	environment?: 'development'|'staging'|'production',
	
	/**
	 * Options to configure the signature page
	 */
	signature?: {
		/**
		 * Whether to validate all pages before allowing to sign
		 * Set to "false" for faster debugging of the signature page.
		 */
		validate_pages?: boolean
	},
	
	/**
	 * Options to configure the summary page
	 */
	summary?: {
		/**
		 * Whether to validate all pages before allowing to submit
		 * Set to "false" for faster debugging of summary page children.
		 */
		validate_pages?: boolean
	},
	
	/**
	 * Options to configure the connection to the backend
	 */
	backend?: {
		/**
		 * Whether to override the backend being used
		 * Set to "pylon" or "asgard" to always use a specific backend, set to "default" to use auto-detection
		 */
		override: 'pylon'|'asgard'|'default'
	},
	
	maintenance?: {
		/**
		 * Start date of the maintenance
		 */
		start?: number|null
		/**
		 * End date of the maintenance
		 */
		end?: number|null
		/**
		 * Message to show to the user for maintenance
		 * Leave undefined to disable maintenance
		 */
		message?: string
	}
}

export const defaultConfig: Config = {
	environment: 'development',
	
	signature: {
		validate_pages: true
	},
	
	summary: {
		validate_pages: true
	},
	
	backend: {
		override: 'default'
	},
	
	maintenance: {
		message: undefined,
		start: null,
		end: null
	}
}

export const defineConfig = (config: Config) => merge(defaultConfig, config)