<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-state-inspector-page-view default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2 :class="{ validated: page.data.validated }">
					{{ pageName }}
					<v-svg file="acq/checkmark" v-if="page.data.validated" title="Page is validated" />
				</h2>
			</template>
			
			<template #info>
				<span class="flag uid">{{ page.data.uid }}</span>
				<span class="flag components">{{ componentsCount }} component{{ componentsCount != 1 ? 's' : '' }}</span>
			</template>
			
			<template #actions>
				<template v-if="currentTab?.uid == 'json'" key="json">
					<!-- <Button type="flat" bordered color="green" theme="dark" icon="acq/edit">Edit</Button> -->
				</template>
				
				<template v-else-if="currentTab?.uid == 'values'">
					<acquisit-button @click="extractValues"
					                 type="flat"
					                 bordered
					                 color="blue"
					                 theme="dark"
					                 icon="dev/export">Extract values</acquisit-button>
				</template>
			</template>
		</StateInspectorHeader>
		
		<acquisit-tabs theme="integrated-dark" color="blue" @change="currentTab = $event">
			<acquisit-tab title="JSON" icon="dev/json" uid="json">
				<StateInspectorJsonView :json="page" class="json" />
			</acquisit-tab>
			
			<acquisit-tab title="Conditions" icon="dev/conditions" class="tab-conditions" uid="conditions">
				<acquisit-accordion-item v-if="extractedConditions?.page?.display"
				                         color="light-grey-blue"
				                         title="Page: display"
				                         theme="dark">
					<h5>String:</h5>
					<pre class="condition-string">{{ expressionToString(extractedConditions.page.display) }}</pre>
					
					<h5>JSON:</h5>
					<StateInspectorJsonView :json="extractedConditions.page.display" class="json" />
				</acquisit-accordion-item>
				
				<template v-for="(conditions, componentUID) in extractedConditions?.components || {}">
					<template v-for="type in ['display', 'disabled']">
						<acquisit-accordion-item v-if="conditions[type]"
						                         :title="'**' + componentUID + '**: ' + type"
						                         color="light-grey-blue"
						                         theme="dark">
							<StateInspectorJsonView :json="conditions[type]" class="json" />
							
							<div class="condition-evaluation">
								<acquisit-button @click="evaluateCondition(componentUID + '-' + type, conditions[type])"
								                 color="blue"
								                 type="raised">Evaluate</acquisit-button>
								
								<div :class="['result', String(evaluatedConditions.conditions[componentUID + '-' + type] ?? 'neutral')]">
									Result:&nbsp;&nbsp;&nbsp;<span>{{ evaluatedConditions.conditions[componentUID + '-' + type] ?? '–' }}</span>
								</div>
							</div>
						</acquisit-accordion-item>
					</template>
				</template>
				
				<template v-if="!containsConditions(extractedConditions)">
					<div class="empty">
						No conditions found.
					</div>
				</template>
				
				<acquisit-info-box type="info" theme="dark" class="nested-conditions-info">
					Nested conditions, e.g. from ItemsInput or PersonsLayout, are not supported, yet.
				</acquisit-info-box>
			</acquisit-tab>
			
			<acquisit-tab title="Values" class="tab-values" uid="values">
				<StateInspectorJsonView :json="extractedValues.values" class="extracted-values" v-if="extractedValues.values !== null" />
				<div class="empty" v-else>Click "Extract values" to see the JSON output.</div>
			</acquisit-tab>
		</acquisit-tabs>
	</div>
</template>

<script setup lang="ts">
	import { computed, reactive, ref, watch } from 'vue'
	import { isMultiLanguageLabel, multiLanguageLabelToRaw, extractValuesFromComponents, type Component } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import StateInspectorJsonView from '@ui/dev/StateInspectorJsonView.vue'
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { forEachComponent, forEachObject, objectSize } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { expressionToString, extractConditionsForPage } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
	import type { Page, ExtractedValues } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import type { EvaluationContext, Expression, ExtractedPageConditions } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
	import { useComponentsStore } from '@/stores/components'
	import { useProductsStore } from '@/stores/products'
	import { evaluateCondition as evalCondition } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
	import { useProtocolStore } from '@/stores/protocol'
	import { usePersonsStore } from '@/stores/persons'
	
	const props = defineProps({
		page: {
			type: Object,
			required: true
		}
	})
	
	const productsStore = useProductsStore()
	const personsStore = usePersonsStore()
	
	const componentsCount = computed(() => {
		const countIn = (components: Component[]): number => {
			let count = 0
			
			forEachComponent(components, (_, component: Component) => {
				count++
				
				if (Array.isArray(component.properties.items)) {
					for (let item of component.properties.items) {
						if (Array.isArray(item.components)) {
							count += countIn(item.components)
						}
					}
				}
				
				if (Array.isArray(component.properties.components)) {
					count += countIn(component.properties.components)
				}
			})
			
			return count
		}
		
		return countIn(props.page.components)
	})
	const pageUID = computed(() => props.page.data.uid)
	
	const extractedConditions = computed(() => extractConditionsForPage(props.page as Page))
	let evaluatedConditions = reactive<{ conditions: Record<string, boolean> }>({
		conditions: {}
	})
	
	const evaluateCondition = (key: string, condition: Expression) => {
		const context: EvaluationContext = {
			componentsByUID: useComponentsStore().by_uid,
			pagesByUID: useProtocolStore().pages_by_uid,
			productsByUID: useProductsStore().by_uid,
			personsByID: usePersonsStore().by_id,
			scope: {}
		}
		
		const result = evalCondition(condition, context)
		evaluatedConditions.conditions[key] = result
		
		return result
	}
	
	const containsConditions = (extractedConditions: ExtractedPageConditions) => {
		if (extractedConditions.page?.display) {
			return true
		}
		
		const contains = (components) => {
			for (let uid in components) {
				if (components.hasOwnProperty(uid)) {
					const d = components[uid]
					
					if (d.disabled || d.display) {
						return true
					}
					
					if (d.nested && contains(d.nested)) {
						return true
					}
				}
			}
			
			return false
		}
		
		return contains(extractedConditions.components)
	}
	
	const extractedValues = reactive<{ values: ExtractedValues|null }>({
		values: null
	})
	
	const extractValues = () => {
		extractedValues.values = extractValuesFromComponents({
			componentsByUID: props.page.components,
			personsByID: personsStore.by_id,
			productsByUID: productsStore.by_uid
		}, false)
	}
	
	const currentTab = ref<any>(null)
	
	watch(pageUID, () => {
		extractedValues.values = null
		evaluatedConditions.conditions = {}
	}, {
		deep: true
	})
	
	const pageName = computed(() => {
		if (isMultiLanguageLabel(props.page.data.name)) {
			const raw = multiLanguageLabelToRaw(props.page.data.name),
				length = objectSize(raw)
			
			let name = '',
				i = 0
			
			forEachObject(raw, (string, language) => {
				name += string + ' (' + language + ')'
				
				if (i < length - 1) {
					name += ' / '
				}
				
				i++
			})
			
			return name
		} else {
			return props.page.data.name
		}
	})
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-page-view {
		
		.header .actions {
			align-self: flex-start;
		}
		
		.condition-string {
			font-family: font(monospace);
			white-space: pre;
			overflow-x: auto;
			padding: 16px 0px;
			padding-top: 12px;
			user-select: all;
		}
		
		h5 {
			color: color("light-grey-blue");
			font-size: 1rem;
			font-weight: 600;
			margin-bottom: 4px;
		}
		
		.condition-evaluation {
			display: flex;
			align-items: center;
			gap: 20px;
			margin-top: 20px;
			
			.result {
				display: flex;
				align-items: center;
				align-self: stretch;
				
				// border: 1px solid rgba(#fff, 0.3);
				color: color("light-grey-blue");
				border-radius: 6px;
				
				padding: {
					left: 16px;
					right: 16px;
				}
				
				span {
					font-weight: 600;
				}
				
				&.true {
					border-color: color("green");
					color: #fff;
					
					span {
						color: color("green");
					}
				}
				
				&.false {
					border-color: color("red");
					color: #fff;
					
					span {
						color: color("red");
					}
				}
			}
		}
		
		.nested-conditions-info {
			margin-top: 24px;
		}
		
		.json {
			overflow-x: auto;
		}
		
		.empty {
			color: color("light-grey-blue");
			padding: 16px 20px;
			text-align: center;
		}
	}
</style>