import type { ValidationContext } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { validateAddress, validatePostcode } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { usePersonsStore } from '@/stores/persons'

export const validateProductPersonChooserLegacy = ({ component, owner }: ValidationContext): Promise<boolean> => new Promise((resolve, reject) => {
	let rolesToCheck = {},
		errors: ValidationError[] = [],
		personsStore = usePersonsStore()

	if (component.properties.role_seller_label && personsStore.sellers.length) {
		rolesToCheck[1] = false
	}

	if (component.properties.role_buyer_label && personsStore.buyers.length) {
		rolesToCheck[2] = false
	}
	
	let determineMultipleAddressOptions = person => [
		component.properties.use_person_address_label && person.address.address,
		component.properties.use_estate_address_label
	].filter(x => !!x).length > 0

	for (let role in rolesToCheck) {
		if (rolesToCheck.hasOwnProperty(role)) {
			let persons = personsStore.list.filter(p => p.role === Number(role))

			for (let person of persons) {
				if (person.marked) {
					rolesToCheck[role] = true
					let hasMultipleAddressOptions = determineMultipleAddressOptions(person)
					
					let errorOwner = {
						owner,
						person
					}
					
					if (hasMultipleAddressOptions) {
						let hasOptionSelected = person.billing_address.use_person_address ||
						                        person.billing_address.use_estate_address ||
						                        person.billing_address.use_custom_address
						
						if (!hasOptionSelected) {
							errors.push(new ValidationError('Billing Address option not chosen', component, 'billing_address', errorOwner))
							continue
						}
					}
					
					// If component has a billing address label and has chosen a custom address, verify address
					if (component.properties.billing_address_label && (!hasMultipleAddressOptions || person.billing_address.use_custom_address !== false)) {
						if (!person.billing_address) {
							errors.push(new ValidationError('Billing Address missing completely', component, 'billing_address.address', errorOwner))
							errors.push(new ValidationError('Billing Address missing completely', component, 'billing_address.postcode', errorOwner))
							errors.push(new ValidationError('Billing Address missing completely', component, 'billing_address.city', errorOwner))
						} else {
							if (!person.billing_address.address) {
								errors.push(new ValidationError('Billing Address missing', component, 'billing_address.address', errorOwner))
							} else if (!validateAddress(person.billing_address.address)) {
								errors.push(new ValidationError('Billing Address invalid', component, 'billing_address.address', errorOwner))
							}

							if (!person.billing_address.postcode) {
								errors.push(new ValidationError('Billing Postcode missing', component, 'billing_address.postcode', errorOwner))
							} else if (!validatePostcode(person.billing_address.postcode)) {
								errors.push(new ValidationError('Billing Postcode invalid', component, 'billing_address.postcode', errorOwner))
							}

							if (!person.billing_address.city) {
								errors.push(new ValidationError('Billing City missing', component, 'billing_address.city', errorOwner))
							}
						}
					}
				}
			}

			if (!rolesToCheck[role]) {
				// No one marked?
				errors.push(new ValidationError('Marking missing', component, null, {
					owner,
					role: Number(role)
				}))
			}
		}
	}

	if (errors.length) {
		reject(errors)
	} else {
		resolve(true)
	}
})