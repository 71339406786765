import type { FieldFunction } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
import { noExtractUID, noRenameUID, registerFieldFunction } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
import { deepValue, forEachObject } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { DateTimeExt } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useProtocolStore } from '@/stores/protocol'

const createProtocolStoreAccessor = (property: string, transform?: (val: any) => any): FieldFunction => ({
	evaluate() {
		const val = useProtocolStore()[property]
		
		if (transform) {
			return transform(val)
		}
		
		return val
	},
	
	extractUIDs: noExtractUID,
	renameUID: noRenameUID
})

/**
 * OFFICE() = office object
 * OFFICE(property) = office's property, e.g. OFFICE("id")
 */
export const OFFICE: FieldFunction = {
	evaluate(args, context) {
		const protocolStore = useProtocolStore()
		return deepValue(protocolStore.office ?? {}, args)
	},
	
	extractUIDs: noExtractUID,
	renameUID: noRenameUID
}

/**
 * TASK_TYPE() = the protocol's task type
 */
export const TASK_TYPE: FieldFunction = createProtocolStoreAccessor('task_type')

/**
 * PROPERTY_TYPE() = the property's task type
 */
export const PROPERTY_TYPE: FieldFunction = createProtocolStoreAccessor('property_type')

/**
 * TAKEOVER_DATE() = the takeover date of the estate
 * Use DATE_* functions to work with this date
 */
export const TAKEOVER_DATE: FieldFunction = createProtocolStoreAccessor('takeover_date', (date) => DateTimeExt.fromTimestamp(date))

export const FUNCTIONS: Record<string, FieldFunction> = {
	OFFICE,
	TASK_TYPE,
	PROPERTY_TYPE,
	TAKEOVER_DATE
}

export const registerCustomFieldFunctions = () => {
	forEachObject(FUNCTIONS, (fn, name) => {
		registerFieldFunction(name, fn)
	})
}