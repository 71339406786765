import { defineStore } from 'pinia'
import { cast, forEachObject, merge, createRecord, normalizeProduct } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { isProduct, useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import type { ProductsStore } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import type { Page, Product, ProductUIDDictionary, UID } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { computed } from 'vue'

const log = useLogger('stores/products', useLogger.COLOR_STORE)

const getProductIDFromArg = (arg: ProductSelectionQuery) => {
	if (isProduct(arg)) {
		return arg.uid
	} else {
		return arg
	}
}

export type ProductSelectionQuery = Product|UID

export interface ProductsStoreState {
	products: ProductUIDDictionary
	current: Product[]
}

export const useProductsStore = defineStore({
	id: 'products',
	
	state: () => cast<ProductsStoreState>({
		// All available products
		products: {},
		// Currently visible products, should be objects taken from this state
		current: []
	}),
	
	getters: {
		/**
		 * All products that have been selected
		 * @param state
		 * @returns {object[]}
		 */
		selected: state => Object.values<Product>(state.products || {}).filter(product => product.selected),
		
		/**
		 * All products
		 * @returns {Product[]}
		 */
		list: (state): Product[] => Object.values(state.products || {}),
		
		/**
		 * All products by their UID
		 * @return {{[key: string]: Product}}
		 */
		by_uid: state => state.products,
		
		forPage: state => (page: Page): Product[] => {
			let findProductChildrenRecursively = children => {
				let ret: Product[] = []
				
				for (let productUID of children) {
					ret.push(state.products[productUID])
					
					if (Array.isArray(productUID.children)) {
						ret = ret.concat(findProductChildrenRecursively(productUID.children))
					}
				}
				
				return ret
			}
			
			let products: Product[] = []
			
			for (let component of page.components) {
				if (component.properties.products) {
					for (let product of component.properties.products) {
						products.push(state.products[product.uid])
					}
				}
			}
			
			for (let productUID in state.products) {
				if (state.products.hasOwnProperty(productUID)) {
					let product = state.products[productUID]
					
					if (Array.isArray(product.children) && product.linked_page && product.linked_page == page.data.uid) {
						for (let childProductUID in product.children) {
							if (product.children.hasOwnProperty(childProductUID)) {
								products.push(state.products[childProductUID])
							}
						}
					}
				}
			}
			
			return products
		}
	},
	
	actions: {
		set(products: Product[]) {
			const harvestProductsRecursively = products => {
				let tempMap = {}
				
				for (let product of products) {
					const normalized = normalizeProduct(product)
					tempMap[normalized.uid] = normalized
					
					if (Array.isArray(normalized.children)) {
						tempMap = merge(tempMap, harvestProductsRecursively(normalized.children))
						// Save some memory, ensure root object is the one being modified
						tempMap[normalized.uid].children = tempMap[normalized.uid].children.map(p => p.uid)
					}
				}
				
				return tempMap
			}
			
			this.products = harvestProductsRecursively(products)
		},
		
		setCurrent(products: Product[]) {
			this.current = products
		},
		
		setProductSelection(arg: ProductSelectionQuery, selected: boolean, componentUID?: string|null) {
			const productID = getProductIDFromArg(arg)
			
			if (productID && this.products[productID]) {
				if (selected && !componentUID) {
					console.warn('[setProductSelection] Product ' + productID + ' selected without component UID')
				}
				
				this.products[productID].selected = selected
				this.products[productID].selected_component_uid = componentUID || null
			} else {
				log.warnFrom('setProductSelection', 'Could not find product', arg)
			}
		},
		
		select(arg: ProductSelectionQuery, componentUID?: string|null) {
			this.setProductSelection(arg, true, componentUID)
		},
		
		bulkSelect(select: ProductSelectionQuery[], unselect: ProductSelectionQuery[], componentUID?: string|null) {
			for (let query of unselect) {
				this.unselect(query)
			}
			
			for (let query of select) {
				this.select(query, componentUID)
			}
		},
		
		unselect(arg: ProductSelectionQuery) {
			this.setProductSelection(arg, false, null)
		},
		
		setTermsAgreed(arg: ProductSelectionQuery, agreed: boolean) {
			const productID = getProductIDFromArg(arg)
			
			if (productID && this.products[productID]) {
				this.products[productID].terms_agreed = agreed
			} else {
				log.warnFrom('setTermsAgreed', 'Could not find product', arg)
			}
		},
		
		setCheckboxValue(arg: ProductSelectionQuery, uid: UID, value: boolean): void {
			const productID = getProductIDFromArg(arg)
			
			if (productID && this.products[productID]) {
				this.products[productID].checkboxes_values![uid] = value
			} else {
				log.warnFrom('setCheckboxValue', 'Could not find product', arg)
			}
		},
		
		findByProductID(productID: UID) {
			for (let uid in this.by_uid) {
				if (this.by_uid.hasOwnProperty(uid) && this.by_uid[uid]?.product_id == productID) {
					return this.by_uid[uid]
				}
			}
			
			return null
		},
		
		restoreBackup(backup: Record<string, any>) {
			const products = {}
			let current: Product[] = []
			
			forEachObject(backup.products, (product) => {
				product = normalizeProductWithoutChildren(product)
				products[product.uid] = product
			})
			
			if (Array.isArray(backup.current) && backup.current.length) {
				current = backup.current.map(p => normalizeProductWithoutChildren(p))
			}
			
			this.products = products
			this.current = current
		}
	}
})

/**
 * Normalize a product without normalizing its children
 * This is necessary because the normalization process assumes children to be full Product objects
 * but the store only stores the IDs to save on memory and potential cyclic references.
 *
 * @param product
 *
 * @returns {Product}
 */
const normalizeProductWithoutChildren = (product: any): Product => {
	const childrenBackup = product.children
	delete product.children
	
	const normalized = normalizeProduct(product)
	normalized.children = childrenBackup
	
	return normalized
}

export const createAcquisitUIProductsStoreAdapter = (): ProductsStore => {
	const productsStore = useProductsStore()
	
	return {
		products: computed<Product[]>({
			get() {
				return Object.values(productsStore.products)
			},
			
			set(value) {
				productsStore.products = createRecord(value, p => p.uid)
			}
		}),
		
		byUID: productsStore.by_uid,
		setTermsAgreed: productsStore.setTermsAgreed,
		setCheckboxValue: productsStore.setCheckboxValue,
		setCurrent: productsStore.setCurrent,
		bulkSelect: productsStore.bulkSelect,
	}
}