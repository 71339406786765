<template>
	<span :class="['svg', { colored: !multiColor }]" v-if="svg" v-html="svg" :style="style" />
</template>

<script lang="ts" setup>
	import {
		computed,
		watch,
		ref,
		toRefs,
	} from 'vue'
	
	import { loadSVG } from '@/lib/svg'
	import type { Ref } from 'vue'
	import { Color } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	
	/**
	 * Get an SVG file from a string
	 *
	 * @param {string} file Filepath, if begins with / taken relative to assets/images, otherwise relative to assets/images/icons, without .svg
	 *
	 * @returns {Promise<string>}
	 */
	const get = async (file: string): Promise<string> => {
		const config = file.split('/')
		const pack = config.slice(0, config.length - 1) as any
		const icon = config[config.length - 1] as string
		
		return (await loadSVG(pack, icon))
			.replace('<svg', '<svg role="img"')
	}
	
	interface Props {
		file: string
		color?: any
		multiColor?: boolean
	}
	
	const props = withDefaults(defineProps<Props>(), {
		color: 'inherit',
		multiColor: false
	})
	
	let svg: Ref<string|null> = ref(null),
		{ file } = toRefs(props)
	
	const style = computed(() => ({
		color: (props.color instanceof Color ? props.color.toString() : (props.color ?? ''))
	}))
	
	watch(file, async file => {
		svg.value = await get(file)
	}, {
		immediate: true
	})
</script>

<style lang="scss">
	.svg {
		display: flex;
		align-items: center;
		justify-content: center;
		
		width: 100%;
		height: 100%;
		
		svg {
			width: inherit;
			height: inherit;
		}
		
		&.colored {
			
			svg,
			svg path,
			svg polygon,
			svg rect,
			svg circle {
				fill: currentColor;
				transition: fill 0.2s;
			}
		}
	}
</style>
