import 'pinia'
import type { PiniaPluginContext } from 'pinia'
import { useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

declare module 'pinia' {
	export interface DefineStoreOptionsBase<S, Store> {
		/**
		 * Optional prefix for the store
		 */
		logPrefix?: string|null
	}
	
	export interface PiniaCustomProperties {
		/**
		 * A logger to log something somewhere
		 */
		log: ReturnType<typeof useLogger>
	}
}

export const usePiniaLog = (context: PiniaPluginContext) => {
	context.store.log = useLogger(context.options.logPrefix ?? 'store-' + context.store.$id, useLogger.COLOR_STORE)
}