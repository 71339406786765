/*
 * UI definition file
 * Auto-loads all components listed here with Acquisit prefix, e.g. "AcquisitAddress", "AcquisitToast" etc.
 */
import AddressLabel from '@ui/core/AddressLabel.vue'
import Generic from '@ui/core/Generic.vue'
import Toast from '@ui/core/Toast.vue'

import BankId from '@ui/extended/BankId.vue'
import ContentSummary from '@ui/extended/ContentSummary.vue'
import PagesErrorSummary from '@ui/extended/PagesErrorSummary.vue'
import ProductSummaryPage from '@ui/extended/ProductSummaryPage.vue'
import Signature from '@ui/extended/Signature.vue'
import SummaryPage from '@ui/extended/SummaryPage.vue'

import DevMenu from '@ui/dev/DevMenu.vue'

import ProductPersonChooserLegacy from '@ui/person/ProductPersonChooserLegacy.vue'

import SignaturePad from '@ui/signature/SignaturePad.vue'
import { registerCustomComponent, useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { normalizeTag } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

const ui = {
	core: [
		AddressLabel,
		Generic,
		Toast
	],
	
	extended: [
		BankId,
		ContentSummary,
		PagesErrorSummary,
		ProductSummaryPage,
		Signature,
		SummaryPage,
	],
	
	dev: [
		DevMenu,
	],
	
	person: [
		ProductPersonChooserLegacy
	],
	
	signature: [
		SignaturePad
	],
	
	_root: [
	
	]
}

const directory = {}
const log = useLogger('plugins/ui-loader', 'cyan')

export default {
	
	/**
	 * Install all UI components as "Acquisit*", e.g. "AcquisitButton"
	 */
	async install(app) {
		// Rest with prefix
		for (let pack in ui) {
			for (let component of ui[pack]) {
				const name = component.name || component.__name
				
				app.component('Acquisit' + name, component)
				directory[name] = true
				
				if (name === undefined) {
					log.warn('Undefined component name', component)
				} else {
					registerCustomComponent('acquisit-' + normalizeTag('Acquisit' + name))
				}
				
				// import(`../components/ui/${pack}/${component}`).then(result => app.component('Acquisit' + component, result.default))
			}
		}
	}
}