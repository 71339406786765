import { computed, useSlots } from 'vue'

export const useUI = () => {
	const slots = useSlots()
	
	const focusFirstComponent = () => {
		let $firstComponent = document.querySelector('.acquisit-components-wrapper .acquisit-component:first-of-type')
		
		if ($firstComponent) {
			// Some components work a bit differently and can't be focused as a whole
			let focus = {
				'acquisit-persons'() {
					let $firstEditButton = $firstComponent?.querySelector('.edit-person-button:first-of-type')
					
					if ($firstEditButton) {
						;(<any> $firstEditButton).focus()
					}
				},
				
				'acquisit-checkbox'() {
					let $wrapper = $firstComponent?.querySelector('.checkbox-wrapper')
					
					if ($wrapper) {
						;(<any> $wrapper).focus()
					}
				},
				
				'acquisit-persons-address'() {
					let $firstInput = $firstComponent?.querySelector('.acquisit-input:first-of-type input')
					
					if ($firstInput) {
						;(<any> $firstInput).focus()
					}
				},
				
				default() {
					;(<any> $firstComponent)?.focus()
				}
			}
			
			let focused = false
			
			for (let className in focus) {
				if (focus.hasOwnProperty(className) && $firstComponent.classList.contains(className)) {
					focus[className]()
					focused = true
					break
				}
			}
			
			if (!focused) {
				focus.default()
			}
			
			return true
		}
		
		return false
	}
	
	const focusPageTitle = () => {
		let $pageTitle = document.querySelector('.acquisit-page-title')
		
		if ($pageTitle) {
			;(<any> $pageTitle).focus()
			
			return true
		}
		
		return false
	}
	
	const hasChildren = computed(() => {
		for (const slot in slots) {
			if (slots.hasOwnProperty(slot)) {
				const children = slots[slot]?.()
				
				if (children?.length) {
					return true
				}
			}
		}
		
		return false
	})
	
	return {
		focusFirstComponent,
		focusPageTitle,
		hasChildren
	}
}