<template>
	<div class="product-summary-page">
		<header v-if="header_text">
			<span>
				{{ language.parse(header_text) }}
				
				<acquisit-modal :style="{ display: 'inline' }" uid="conditions" :button="{ type: 'text', label: conditions_modal.title }" v-if="conditions_modal">
					<template #header>
						<acquisit-string :source="conditions_modal.title" />
					</template>
					
					<acquisit-string :source="conditions_modal.body" />
					
					<template #accept><acquisit-string :source="conditions_modal.button_accept_label" /></template>
				</acquisit-modal>
			</span>
		</header>
		
		<acquisit-pages-error-summary v-if="shouldValidatePages" class="error-summary" />
		
		<template v-if="isReadyForSubmission">
			<div v-if="!productsStore.selected.length && no_products_chosen_label" class="no-products-chosen-wrapper">
				<acquisit-string class="no-product-chosen-label" :source="no_products_chosen_label" />
			</div>
			
			<ul class="items" v-if="isReadyForSubmission">
				<li class="validated-wrapper">
					<div class="flex">
						<v-svg class="icon" file="acq/document" :size="32" color="#4CAF50" />
						
						<div class="message-wrapper">
							<acquisit-string class="page-title" :source="ready_text.title" />
							<acquisit-string class="page-message" :source="ready_text.body" />
						</div>
					</div>
				</li>
				
				<template v-for="by_page in productsByPage">
					<router-link :to="{ name: 'page', params: { page: by_page.page.data.uid, token: $route.params.token }}" custom v-slot="{ navigate }">
						<li class="product-page" @click="navigate()" @keyup.enter="navigate()">
							<div class="flex">
								<div class="title">
									<h3>{{ language.parse(by_page.page.data.name) }}</h3>
								</div>
								
								<ul class="products" v-if="by_page.products.length">
									<li class="product" v-for="product in by_page.products">
										<span class="provider">{{ product.provider_name }}</span>
										<span class="product-title">{{ product.title }}</span>
									</li>
								</ul>
								
								<ul v-else class="products">
									<li class="product none">
										<acquisit-string :source="no_product_label" />
									</li>
								</ul>
							</div>
						</li>
					</router-link>
				</template>
			</ul>
			
			<transition name="move-fade-top">
				<acquisit-info-box class="error-message offline" v-if="summaryPage.isOffline.value" :label="offline_text" type="error" />
			</transition>
			
			<div class="send-in-wrapper">
				<acquisit-pill-button :class="['send-in-button', { pulsating: summaryPage.online.isOnline.value }]"
				                      uid="send-in-protocol"
				                      :color="summaryPage.sendInButtonColor.value"
				                      icon="arrows/right-thick"
				                      side="right"
				                      :bordered="summaryPage.isOffline.value"
				                      :no-interact="summaryPage.isOffline.value"
				                      :label="send_button_label"
				                      @click="sendIn" />
			</div>
		</template>
	</div>
</template>

<script setup lang="ts">
	/**
	 * A summary that can submit the protocol but is focused on
	 * selected products (single or multi) per page instead of the
	 * pages itself.
	 *
	 * It can submit the protocol *even if not all pages were saved* but at
	 * least 1 product must be selected.
	 */
	
	import type { PropType } from 'vue'
	import { useProductsStore } from '@/stores/products'
	import type { UILabelOptional, Page, Product } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { pageHasSemantic } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useSummaryPage, useSummaryPageProps } from '@/helpers/summary-page'
	import { computed, ref, watchEffect } from 'vue'
	import { useProtocolStore } from '@/stores/protocol'
	import { useRoute, useRouter } from 'vue-router'
	
	const props = defineProps({
		...useBaseComponentProps(),
		...useSummaryPageProps(),
		
		/**
		 * Label to show when no product has been chosen in a given category, defaults to '–'
		 */
		no_product_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: '–'
		},
		
		/**
		 * Label to show when no product has been chosen on any page (globally)
		 */
		no_products_chosen_label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: null
		},
		
		offline_text: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: 'Your device is currently offline. Please check your WiFi or mobile data connection.'
		},
		
		validate_pages: {
			type: Boolean,
			required: false,
			default: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits()
	])
	
	const base = useBaseComponent(props, emit)
	const { language } = base
	
	const summaryPage = useSummaryPage(props)
	const $router = useRouter()
	const $route = useRoute()
	
	const productsStore = useProductsStore()
	const protocolStore = useProtocolStore()
	
	const shouldValidatePages = computed(() => summaryPage.shouldValidatePages.value && props.validate_pages)
	
	const isValidated = ref(true)
	const isReadyForSubmission = computed(() => !shouldValidatePages.value || (isValidated.value && productsStore.selected.length))
	
	const productsByPage = computed(() => {
		let products: { page: Page, products: Product[] }[] = []
		
		for (let page of protocolStore.pages) {
			if (pageHasSemantic(page, ['product', 'products'], 'OR')) {
				products.push({
					page,
					products: productsStore.forPage(page).filter(p => p.selected)
				})
			}
		}
		
		return products
	})
	
	const sendIn = async () => {
		const result = await summaryPage.submit()
		
		if (result) {
			await $router.push({
				name: 'outro'
			})
		}
	}
	
	watchEffect(() => {
		isValidated.value = true
		
		for (let page of protocolStore.pages) {
			if (!page.data.validated && !pageHasSemantic(page, 'summary')) {
				isValidated.value = false
				break
			}
		}
	})
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss" scoped>
	@import '@/assets/mixins.scss';
	
	.checkbox-wrapper {
		padding: 26px 52px;
		margin-bottom: -26px;
		border-top: 1px solid #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
		
		.modal {
			margin: 0 26px;
		}
	}

	.product-summary-page {
		display: flex;
		height: 100%;
		width: 100%;
		flex-direction: column;
		color: rgba(0, 0, 0, .87);
		margin-bottom: -52px;

		header {
			font-size: 18px;
			margin: 0 auto;
			line-height: 1.5;
			text-align: center;
		}
		
		.flex {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		ul.items {
			justify-content: center;
			width: 100%;

			> li {
				margin: auto;
				cursor: pointer;
				padding: 20px;

				.icon {
					width: 42px;
					margin-right: 16px;
					border-radius: 100%;
				}
				
				& + li {
					border-top: 1px solid color("border");
				}
				
				&:last-child {
					border-bottom: 1px solid color("border");
					// padding-bottom: 4px;
				}
			}

			.validated-wrapper {
				padding: {
					top: 12px;
					bottom: 32px;
				}
				
				.flex {
					justify-content: center;
				}
				
				@media screen and (max-width: 340px) {
					
					.flex {
						display: block;
						text-align: center;
					}
					
					span.icon {
						display: inline-block;
						margin-bottom: 8px;
					}
				}
			}
		}
		
		header + ul.items li:first-child {
			border-top: 1px solid color("border");
			margin-top: 12px;
		}
		
		.product-page {
			transition: background 0.2s;
			will-change: background;
			line-height: 1.3;
			
			&:hover {
				background: color("light-background");
			}
			
			.flex {
				align-items: flex-start;
			}
			
			.title {
				@include border-box;
				
				width: 164px;
				flex-grow: 0;
				align-self: flex-start;
				text-align: right;
				padding-right: 16px;
				
				h3 {
					font-size: 1rem;
					font-weight: 600;
				}
			}
			
			.products {
				
				li {
					
					.provider {
						// font-weight: 300;
					}
					
					.product-title {
						color: color("light-grey-blue");
						display: none;
					}
					
					&.none {
						color: color("light-grey");
					}
					
					& + li {
						margin-top: 8px;
					}
				}
			}
			
			@media screen and (max-width: 420px) {
				
				.flex {
					display: block;
				}
				
				.title {
					text-align: left;
					width: 100%;
					margin-bottom: 8px;
				}
			}
		}
		
		.message-wrapper {
			display: flex;
			flex-direction: column;
			justify-content:  center;
			line-height: 1.3;

			.page-title {
				font-weight: 600;
				font-size: 16px;
				color: #222222;
			}
			
			.page-title + .page-message {
				margin-top: 2px;
			}

			.page-message {
				font-size: 14px;
				color: color("light-grey")
			}
		}
		
		.no-products-chosen-wrapper {
			text-align: center;
			border-bottom: 1px solid color("border");
			
			padding: {
				bottom: 20px;
			}
			
			.no-product-chosen-label {
				font-size: 0.9rem;
				color: color("light-grey");
			}
		}
		
		.error-message {
			margin-top: 20px;
		}

		.send-in-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			
			// border-top: 1px solid color("border");
			// margin-top: 20px - 4px;
			
			padding: {
				top: 32px;
				bottom: 24px;
			}

			.send-in-button {
				padding-left: 32px;
				padding-right: 32px;
				
				.acquisit-string {
					font-size: 20px;
					font-weight: 700;
				}
				
				&.pulsating {
					animation-name: scale;
					animation-duration: 2s;
					animation-iteration-count: infinite;
					animation-timing-function: ease-in-out;
				}
			}
		}

		.modal-buttons {
			display: flex;
			justify-content: flex-end;
		}
	}
	
	.error-modal {
		
		pre.stacktrace {
			white-space: pre;
			tab-size: 4;
			font-family: font(monospace);
			font-size: 14px;
			line-height: 1.5;
			overflow-x: auto;
		}
	}

	@keyframes scale {
		0% {
			transform: scale(1);
		}
		
		50% {
			transform: scale(1.2);
		}
		
		100% {
			transform: scale(1);
		}
	}
</style>
