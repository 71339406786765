export enum ResponseType {
	UnknownError = 'unknown',
	NetworkError = 'network_error',
	NoResponseError = 'no_response',
	BackendError = 'backend_error',
	JSError = 'js_error'
}

export interface BasicResponse {
	readonly type: ResponseType
	readonly status?: number
	readonly message: string
	readonly headers?: {[key: string]: string}
	readonly data?: any
	readonly request?: XMLHttpRequest
	readonly code?: number
}

export interface NetworkError extends BasicResponse {
}

export interface NoResponseError extends BasicResponse {
}

export interface BackendError extends BasicResponse {
	readonly details?: any
}

export interface JSError extends BasicResponse {
	readonly error: Error
}
