import type { Address, Intro, ModalCompound, Outro, Page, Person, UILabelOptional, UILabel, ComponentValueAvailableImport, ComponentValueAvailableExport } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

export interface Branding {
	color: {
		primary: string
		secondary: string
	}
	
	logo: {
		primary: string
		secondary: string
	}
}

export interface Client {
	name: string
	short?: string
}

export interface Office {
	id: number|string
	name: string
}

export interface OrderResponse {
	token: string
	ticket_id: string|number
	persons: Person[]
	client: Client
	office?: Office|null
	task_type: string|null|undefined
	property_type: string|null|undefined
	takeover_date: number|null|undefined
	branding: Branding
	intro?: Intro
	outro?: Outro
	address: Address
	type: string
	external_id: string|number
	imports?: ComponentValueAvailableImport[]
	exports?: ComponentValueAvailableExport[]
}

export type UIResponse = UIResponseV2 | UIResponseV3

export interface UIResponseV2 {
	style: string
	language: string
	available_languages?: string[]
	pages: Page[]
	validate_page_label: UILabel
	back_button_label: UILabel
	jump_to_content_label?: UILabelOptional
	menu_button_label?: UILabelOptional
	create_pages_pdf?: boolean|null
	reset_protocol_label?: UILabelOptional
	reset_protocol_modal?: ModalCompound|null
	additional_information_label?: UILabelOptional
	additional_information_modal?: ModalCompound|null
	title?: UILabelOptional
	copyright_visible?: boolean
}

export const isUIResponseV2 = (sth: any): sth is UIResponseV2 => {
	if (!sth || typeof(sth) != 'object') {
		return false
	}
	
	return !isUIResponseV3(sth)
}

export interface UIResponseV3 {
	protocol_type: string
	style: string
	available_languages: string[]
	default_language: string
	create_pages_pdf: boolean
	labels: {
		validate_page: string
		back_button: string
		menu_button: string
		jump_to_content?: UILabelOptional
		// title: string
	}
	reset_protocol: {
		label: UILabel
		modal: ModalCompound
	} | null
	additional_information: {
		label: UILabel
		modal: ModalCompound
	} | null
	intro: Intro
	pages: Page[]
	outro: Outro
	copyright_visible: boolean
}

export const isUIResponseV3 = (sth: any): sth is UIResponseV3 => {
	if (!sth || typeof(sth) != 'object') {
		return false
	}
	
	return typeof(sth.labels) == 'object' && typeof(sth.intro) == 'object' && typeof(sth.outro) == 'object'
}

export interface CreateImageCollectionResponse {
	hash_id: string|number
	title?: string|null
	metadata?: Record<string, any>|null
	semantic?: string|null
}

export interface CreateImageResponse {
	hash_id: string|number
	url: string
	title?: string|null
	uploaded_at?: {
		timestamp: number
		iso: string
	} | null
}

export interface BankIDDataResponse {
	common_name: string
	person_number: string
	timestamp: number
}