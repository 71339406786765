import type { ValueHandler } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

export const handleSignature: ValueHandler = async ({
	component,
	value,
	setProperties
}): Promise<void> => {
	// Point is to avoid invalidations
	
	setProperties(component, {
		modelValue: value
	})
}