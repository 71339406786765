import { computed, type PropType, ref } from 'vue'
import type { ModalCompound, UILabelOptional } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useRaygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { sleep } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import config from '@config'
import { useOnline } from '@/helpers/online'
import { useUIStore } from '@/stores/ui'
import { useAPIStore } from '@/stores/api'
import { useProtocolStore } from '@/stores/protocol'
import { useBackend } from '@/lib/backend'

export const useSummaryPageProps = () => ({
	conditions_modal: {
		type: Object as PropType<ModalCompound|null>,
		required: false,
		default: null
	},
	
	header_text: {
		type: [String, Object] as PropType<UILabelOptional>,
		required: false,
		default: null,
	},
	
	ready_text: {
		type: Object,
		required: false,
		default: null
	},
	
	send_button_label: {
		type: [String, Object] as PropType<UILabelOptional>,
		required: false,
		default: "SEND IN"
	},
	
	offline_text: {
		type: [String, Object] as PropType<UILabelOptional>,
		required: false,
		default: 'Your device is currently offline. Please check your WiFi or mobile data connection.'
	}
})

export const useSummaryPage = (props) => {
	const apiStore = useAPIStore()
	const online = useOnline()
	const shouldValidatePages = computed(() => (config.summary?.validate_pages ?? true) && !apiStore.isPreview)
	const isOffline = computed(() => !online.isOnline.value)
	const isSending = ref(false)
	
	const sendInButtonColor = computed(() => {
		if (isOffline.value || apiStore.isPreview) {
			return 'light-grey-blue'
		}
		
		return '#4caf50'
	})
	
	const submit = async () => {
		if (!online.isOnline.value || apiStore.isPreview || isSending.value) {
			return false
		}
		
		const uiStore = useUIStore(),
			protocolStore = useProtocolStore()
		
		useRaygun().recordBreadcrumb('Submit', { location: 'summary-page.ts:submit' })
		
		isSending.value = true
		uiStore.setLoader('Sender inn…')
		await sleep(200)
		
		try {
			await protocolStore.submit(apiStore.token!)
			return true
		} catch (e: any) {
			useBackend().handleError(e)
			return false
		} finally {
			uiStore.setLoader(false)
			isSending.value = false
		}
	}
	
	return {
		shouldValidatePages,
		online,
		isOffline,
		isSending,
		sendInButtonColor,
		submit
	}
}