<!--
	A component that sums up error states for all relevant pages

	@author bluefirex
	@date 05.07.21
-->
<template>
	<ul class="acquisit-pages-error-summary">
		<li v-for="p in pagesWithErrors"
			:key="p.data.uid"
			@click="onClickPage(p)"
			@keydown.enter="onClickPage(p)"
		   :tabindex="0">
			<v-svg class="icon" file="info/warning-rhombus" color="#f44336" />
			
			<div class="message-wrapper">
				<span class="page-title">{{ language.parse(p.data.name) }}</span>
				<span class="page-message" v-if="p.data.error_message">{{ p.data.error_message }}</span>
			</div>
			
			<div class="page-action">&rsaquo;</div>
		</li>
	</ul>
</template>

<script setup lang="ts">
	import type { Page } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { pageHasSemantic } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { usePageStore } from '@/stores/page'
	import { useProtocolStore } from '@/stores/protocol'
	import { computed } from 'vue'
	import { usePageRouter, usePageRouterProps } from '@/helpers/page-router'
	import { useUI } from '@/helpers/ui'
	
	const props = defineProps({
		...useBaseComponentProps(),
		...usePageRouterProps(),
		
		except_signature: {
			type: Boolean,
			required: false,
			default: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits()
	])
	
	const base = useBaseComponent(props, emit)
	const { language } = base
	
	const pageRouter = usePageRouter(props)
	const ui = useUI()
	
	const pageStore = usePageStore()
	const protocolStore = useProtocolStore()
	
	const pagesWithErrors = computed(() => protocolStore.pages.filter(p =>
		p.enabled &&
		!p.data.validated &&
		!pageHasSemantic(p, 'summary') &&
		(props.except_signature ? !pageHasSemantic(p, 'signature') : true)
	))
	
	const onClickPage = async (page: Page) => {
		let nextPageUIDs: string[] = [],
			pageIndex = 0,
			clickedPageIndex: number|null = null
		
		for (let p of protocolStore.pages) {
			if (p.data.uid == page.data.uid) {
				clickedPageIndex = pageIndex
			}
			
			let pageIsRelevant =
				// Index of the next page must be higher than the clicked page
				(clickedPageIndex !== null && pageIndex > clickedPageIndex) &&
				// Must not be the clicked page, as we're already navigating there
				page.data.uid != p.data.uid &&
				// Must not be signature or summary
				!pageHasSemantic(p, ['signature', 'summary'], 'OR')
			
			if (pageIsRelevant && !p.data.validated) {
				nextPageUIDs.push(p.data.uid)
			}
			
			pageIndex++
		}
		
		if (pageStore.current) {
			nextPageUIDs.push(pageStore.current.data.uid)
		}
		
		await pageRouter.navigateToRoute(page.data.uid, nextPageUIDs)
		
		setTimeout(_ => {
			ui.focusPageTitle()
		}, 300)
	}
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-pages-error-summary {
		box-sizing: border-box;
		justify-content: center;
		
		li {
			transition: background 0.2s, color 0.2s;
			display: flex;
			margin: auto;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;
			border-bottom: 1px solid #e0e0e0;
			
			padding: {
				top: 16px;
				bottom: 16px;
				left: 24px;
				right: 32px;
			}
			
			.icon {
				width: 32px;
				margin-right: 16px;
				border-radius: 100%;
			}
			
			&:first-child {
				border-top: 1px solid #e0e0e0;
			}
			
			&:hover {
				background: color("light-background");
			}
			
			.page-action {
				width: 42px;
				text-align: right;
				font-size: 24px;
				font-weight: 900;
			}
			
			.message-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				line-height: 1.3;
				width: calc(100% - 2 * 42px);
				
				.page-title {
					font-weight: 600;
					font-size: 16px;
					color: #222222;
				}
				
				.page-title + .page-message {
					margin-top: 2px;
				}
				
				.page-message {
					font-size: 14px;
					color: color("light-grey")
				}
			}
		}
	}
</style>
