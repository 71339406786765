import { defineStore } from 'pinia'
import { cast, mappedObject, pageHasSemantic } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useBrandingStore } from '@/stores/branding'
import { fetchImage, PDF } from '@/lib/pdf'
import { useProtocolStore } from '@/stores/protocol'
import { usePersonsStore } from '@/stores/persons'
import domtoimage from 'dom-to-image-more'

const log = useLogger('stores/screenshots', 'magenta')

export interface ScreenshotsStoreState {
	screenshots: { [uid: string]: string[] }
	logo: string|null
}

export const useScreenshotsStore = defineStore({
	id: 'screenshots',
	
	state: () => cast<ScreenshotsStoreState>({
		screenshots: {},
		logo: null
	}),
	
	getters: {
		counts_by_page_uid: state => mappedObject(state.screenshots, (images) => images.length)
	},
	
	actions: {
		add(key: string, image: string) {
			this.screenshots[key] = [ image ]
		},
		
		delete(key: string) {
			delete this.screenshots[key]
		},
		
		/**
		 * Take a screenshot of a page and save it for the given UID
		 * Requires a rendered page currently being displayed
		 *
		 * @param {string} pageUID
		 *
		 * @returns {Promise<void>}
		 */
		async takeForPage(pageUID: string): Promise<void> {
			const $el = document.querySelector('.page')
			
			if (!$el) {
				throw new Error('Could not store page screenshot, root element .page not found')
			}
			
			const $wrapper = $el.querySelector('.acquisit-components-wrapper')
			
			if (!$wrapper) {
				throw new Error('Could not store page screenshot, components element .acquisit-components-wrapper not found')
			}
			
			const options = {
				// Don't screenshot buttons
				filter: node => {
					if (node.classList) {
						if (node.classList.contains('pdf-hidden')) {
							return false
						}
						
						if (node.classList.contains('acquisit-button')) {
							let isBlacklisted = node.classList.contains('bordered') ||
							                    node.classList.contains('type-raised') ||
							                    node.classList.contains('add-image-btn')
							
							if (isBlacklisted) {
								return false
							}
						}
					}
					
					return true
				},
				
				cacheBust: true,
				
				style: {
					backgroundColor: '#ffffff',
					marginLeft: 0,
					marginRight: 0,
					maxWidth: 'none',
					paddingBottom: 0
				}
			}
			
			const dataURI = await domtoimage.toPng($wrapper, options)
			this.add(pageUID, dataURI)
		},
		
		async fetchPDFLogo() {
			const brandingStore = useBrandingStore(),
				logoURL = brandingStore.logo.secondary
			
			if (!logoURL) {
				throw new Error('Secondary logo missing')
			}
			
			const logoDataURI = await fetchImage(logoURL)
			this.logo = logoDataURI
			
			return logoDataURI
		},
		
		async createPDF(): Promise<string|ArrayBuffer> {
			if (window.DEBUG) {
				const screenshotsSize = Object.values<string[]>(this.screenshots)
				                              .map(s => s.reduce((acc, i) => acc + i.length, 0))
				                              .reduce((acc, i) => acc + i, 0)
				
				log.infoFrom('createPDF', 'Screenshots total size:', screenshotsSize / 1024, 'KB')
			}
			
			let logo: string|null = null
			
			try {
				logo = await this.fetchPDFLogo()
			} catch (e) {
				// Still render, just without logo
				log.always.warn('Error fetching logo', e)
			}
			
			const protocolStore = useProtocolStore(),
				personsStore = usePersonsStore()
			
			let pdf = new PDF(protocolStore.currentLanguageLabelFor, logo),
				pages = protocolStore.pages.filter(page => page.enabled && (this.screenshots.hasOwnProperty(page.data.uid) || pageHasSemantic(page, 'product'))),
				screenshots = this.screenshots,
				sections = pages.map(p => ({
					uiPage: p,
					screenshots: screenshots[p.data.uid] ?? [],
					title: protocolStore.currentLanguageLabelFor(p.data.name)!
				}))
			
			pdf.setup()
			
			if (protocolStore.intro) {
				pdf.addIntro(protocolStore.intro)
				pdf.addPage()
			}
			
			for (let section of sections) {
				await pdf.drawSection(section)
			}
			
			let signaturePage = protocolStore.pages.filter(page => pageHasSemantic(page, 'signature'))[0]
			
			if (signaturePage) {
				await pdf.drawSignatures(signaturePage, personsStore.required_list)
			}
			
			pdf.end()
			
			const dataURI = await pdf.outputDataURI()
			log.infoFrom('createPDF', 'PDF total size:', String(dataURI).length / 1024, 'KB', String(dataURI))
			
			return dataURI
		}
	}
})