<!--
	@author bluefirex
-->
<template>
	<transition name="dev-menu-zoom">
		<div class="acquisit-dev-state-inspector" v-show="isOpen">
			<header class="header">
				<div class="menu-button">
					<acquisit-button type="icon"
					                 class="menu-btn"
					                 :icon="menuIsOpen ? 'acq/clear' : 'acq/menu'"
					                 :icon-size="24"
					                 :size="26"
					                 color="white"
					                 @click="toggleMenu" />
				</div>
				
				<div class="title">
					<h2>Acquisit Inspector</h2>
				</div>
				
				<div class="close">
					<acquisit-button type="icon"
					                 icon="acq/cross"
					                 color="red"
					                 @click="close" />
				</div>
			</header>
			
			<div class="flex">
				<nav :class="['menu', { open: menuIsOpen }]">
					<template v-for="section in sections">
						<section class="divider" v-if="section.divider"></section>
						
						<StateInspectorMenuBox v-else
						                       :class="[section.uid, { active: currentSection?.uid == section.uid }]"
						                       :ref="el => { sectionRefs[section.uid] = el }"
						                       :label="section.label"
						                       :icon="section.icon"
						                       :collapsible="(section.children?.length ?? 0) > 0"
						                       @click="switchToSection(section)">
							<template v-if="section.children?.length">
								<ul>
									<template v-for="child in section.children">
										<li v-if="child.divider" class="divider"></li>
										
										<li v-else
										    :class="[{ active: currentChildSection?.uid == child.uid, validated: child.validated }]"
										    @click="switchToSection(section, child)">
											<a v-html="child.label"></a>
											<span class="badge" v-if="child.badge">{{ child.badge }}</span>
											<v-svg v-if="child.validated" file="acq/checkmark" title="Page is validated" />
										</li>
									</template>
								</ul>
							</template>
						</StateInspectorMenuBox>
					</template>
				</nav>
				
				<div class="content">
					<div class="edge-overlay"></div>
					
					<template v-if="currentSection?.uid == 'pages'">
						<div class="default-wrapper" v-if="currentChildSection?.uid == 'pages-intro'">
							<StateInspectorHeader>
								<template #default>
									<h2>Intro</h2>
								</template>
							</StateInspectorHeader>
							
							<StateInspectorJsonView :json="intro" />
						</div>
						
						<div class="default-wrapper" v-else-if="currentChildSection?.uid == 'pages-outro'">
							<StateInspectorHeader>
								<template #default>
									<h2>Outro</h2>
								</template>
							</StateInspectorHeader>
							
							<StateInspectorJsonView :json="outro" />
						</div>
						
						<template v-else-if="currentChildSection && currentChildSection.page_uid && pagesByUID[currentChildSection.page_uid]">
							<StateInspectorPageView :page="pagesByUID[currentChildSection.page_uid]" />
						</template>
						
						<template v-else>
							<div class="empty">
								Select a page to view its definition.
							</div>
						</template>
					</template>
					
					<div class="default-wrapper" v-if="currentSection?.uid == 'persons'">
						<StateInspectorHeader>
							<template #default>
								<h2>Persons</h2>
							</template>
						</StateInspectorHeader>
						
						<StateInspectorJsonView :json="personsByID" />
					</div>
					
					<div class="default-wrapper" v-if="currentSection?.uid == 'products'">
						<StateInspectorHeader>
							<template #default>
								<h2>Products</h2>
							</template>
						</StateInspectorHeader>
						
						<StateInspectorJsonView :json="productsByUID" />
					</div>
					
					<template v-if="currentSection?.uid == 'screenshots'">
						<StateInspectorScreenshotsView v-if="currentChildSection?.page_uid" :page-uid="currentChildSection.page_uid" />
						
						<div class="empty" v-else>
							<template v-if="createPagesPDF">
								Select a page to view its screenshots.
							</template>
							
							<template v-else>
								<p>The PDF and therefore screenshots are disabled for this protocol.</p>
								
								<acquisit-button @click="enablePagesPDF"
								                 color="green"
								                 type="flat"
								                 bordered
								                 theme="dark">Enable</acquisit-button>
							</template>
						</div>
					</template>
					
					<template v-if="currentSection?.uid == 'stores'">
						<template v-if="currentChildSection && currentChildSection.store_key">
							<StateInspectorStoreView :store-id="currentChildSection.store_key" :store="storesByKey[currentChildSection.store_key]" />
						</template>
						
						<template v-else>
							<div class="empty">
								Select a store to view its contents.
							</div>
						</template>
					</template>
					
					<div class="default-wrapper" v-else-if="currentSection?.uid == 'submission'">
						<StateInspectorHeader>
							<template #default>
								<h2>Submission Payload</h2>
								<span class="flag size">{{ submissionPayloadHumanSize }}</span>
								
								<span class="flag pdf-size" v-if="pdfSizeCalculating">
									PDF: <acquisit-spinner size="tiny" color="white" />
								</span>
								
								<span :class="['flag', 'pdf-size', { exceeded: pdfSize + submissionPayloadSize > 1024 * 1024 }]" v-else-if="pdfSize !== undefined">
									PDF: {{ pdfHumanSize }}
								</span>
								
								<span class="flag pdf-size" v-else>
									PDF: ?
									<acquisit-button label="Calculate" type="text" color="white" @click="calculatePDFSize" />
								</span>
							</template>
							
							<template #actions>
								<acquisit-checkbox v-model="showValidatedPayloadOnly"
								                   label="Show validated only" />
							</template>
						</StateInspectorHeader>
						
						<StateInspectorJsonView :json="submissionPayload" colored />
					</div>
					
					<template v-else-if="currentSection?.uid == 'backup'">
						<StateInspectorBackupView />
					</template>
					
					<template v-else-if="currentSection?.uid == 'logs'">
						<StateInspectorLogs />
					</template>
					
					<template v-else-if="currentSection?.uid == 'markdown-tester'">
						<MarkdownTester />
					</template>
					
					<template v-else-if="currentSection?.uid == 'condition-tester'">
						<ConditionTester />
					</template>
					
					<template v-else-if="currentSection?.uid == 'signature-session-decoder'">
						<SignatureSessionDecoder />
					</template>
					
					<template v-else-if="currentSection?.uid == 'imports-exports'">
						<StateInspectorImportsExports />
					</template>
				</div>
			</div>
			
			<transition name="fade">
				<div class="menu-overlay" v-show="menuIsOpen" @click="closeMenu"></div>
			</transition>
		</div>
	</transition>
</template>

<script setup lang="ts">
	import { computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch, watchEffect } from 'vue'
	import { type ProtocolSubmission, useProtocolStore } from '@/stores/protocol'
	import { storeToRefs } from 'pinia'
	import { useBackup } from '@/stores/backup'
	import { forEachObject, bytesToHuman } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import type { UID } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import StateInspectorMenuBox from '@ui/dev/StateInspectorMenuBox.vue'
	import { useGenericStore } from '@/stores/generic'
	import StateInspectorPageView from '@ui/dev/StateInspectorPageView.vue'
	import StateInspectorJsonView from '@ui/dev/StateInspectorJsonView.vue'
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { usePersonsStore } from '@/stores/persons'
	import { useProductsStore } from '@/stores/products'
	import StateInspectorStoreView from '@ui/dev/StateInspectorStoreView.vue'
	import StateInspectorBackupView from '@ui/dev/StateInspectorBackupView.vue'
	import { EventBus } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useScreenshotsStore } from '@/stores/screenshots'
	import StateInspectorScreenshotsView from '@ui/dev/StateInspectorScreenshotsView.vue'
	import MarkdownTester from '@ui/dev/MarkdownTester.vue'
	import ConditionTester from '@ui/dev/ConditionTester.vue'
	import SignatureSessionDecoder from '@ui/dev/SignatureSessionDecoder.vue'
	import StateInspectorLogs from '@ui/dev/StateInspectorLogs.vue'
	import StateInspectorImportsExports from '@ui/dev/StateInspectorImportsExports.vue'
	
	interface Section {
		uid: string
		label: string
		divider?: boolean
		icon?: string|null
		children?: ChildSection[]
	}
	
	interface Divider {
		divider: true
	}
	
	interface ChildSection {
		uid: string
		label: string
		icon?: string|null
		page_uid?: string|null
		store_key?: string|null
		badge?: number|null
		divider?: boolean
		validated?: boolean
	}
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		isOpen: {
			type: Boolean,
			required: false,
			default: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'open',
		'close'
	])
	
	const base = useBaseComponent(props, emit)
	const { language, color } = base
	
	const protocolStore = useProtocolStore(),
		protocolStoreRefs = storeToRefs(protocolStore),
		{ by_id: personsByID } = storeToRefs(usePersonsStore()),
		{ by_uid: productsByUID } = storeToRefs(useProductsStore()),
		storesByKey = useBackup().STORES
	
	// Open / Close
	const { isOpen: isOpenProp } = toRefs(props),
		isOpen = ref(isOpenProp.value),
		menuIsOpen = ref(false)
	
	const open = () => {
		isOpen.value = true
		emit('open')
	}
	
	const close = () => {
		isOpen.value = false
		emit('close')
	}
	
	const openMenu = () => {
		menuIsOpen.value = true
	}
	
	const closeMenu = () => {
		menuIsOpen.value = false
	}
	
	const toggleMenu = () => {
		menuIsOpen.value = !menuIsOpen.value
	}
	
	watch(isOpenProp, (o) => isOpen.value = o)
	watch(isOpen, (o) => {
		useGenericStore().setDevMenuOpen(o)
		switchToInitialSection()
	})
	
	// Stores
	const backup = useBackup(),
		stores: Record<string, any>[] = []
	
	forEachObject(backup.STORES, (store, key) => {
		stores.push({
			key,
			label: key,
			store
		})
	})
	
	// Submission
	const submissionPayload = ref<any>({})
	
	const createSubmissionPayload = async () => {
		try {
			submissionPayload.value = await protocolStore.createSubmissionPayload()
		} catch (e: any) {
			console.error('Error generating submission_payload', e)
			
			submissionPayload.value = {
				error: e.message,
				name: e.name,
				fileName: e.fileName,
				lineNumber: e.lineNumber,
				stack: e.stack
			}
		}
	}
	
	watch(submissionPayload, () => {
		pdfSize.value = undefined
	}, {
		deep: true
	})
	
	const submissionPayloadSize = computed(() => JSON.stringify(submissionPayload.value).length)
	const submissionPayloadHumanSize = computed(() => bytesToHuman(submissionPayloadSize.value))
	
	const pdfSize = ref<number|undefined>(undefined)
	const pdfSizeCalculating = ref(false)
	const pdfHumanSize = computed(() => pdfSize.value === undefined ? undefined : bytesToHuman(pdfSize.value))
	
	const calculatePDFSize = async () => {
		pdfSizeCalculating.value = true
		
		try {
			const pdf = await screenshotsStore.createPDF()
			pdfSize.value = String(pdf).length
		} finally {
			pdfSizeCalculating.value = false
		}
	}
	
	const showValidatedPayloadOnly = computed({
		get() {
			return useGenericStore().dev.submission_payload_validated_only
		},
		
		set(value) {
			useGenericStore().setDevSubmissionPayloadValidatedOnly(value)
			createSubmissionPayload()
		}
	})
	
	// Screenshots
	const screenshotsStore = useScreenshotsStore()
	
	const enablePagesPDF = () => {
		protocolStore.create_pages_pdf = true
	}
	
	// Navigation
	const currentSection = ref<Section|null>(null),
		currentChildSection = ref<ChildSection|null>(null)
	
	const switchToSection = (section: Section, child?: ChildSection|null) => {
		currentSection.value = section
		currentChildSection.value = child ?? null
		openSectionMenuBoxFor(section)
		closeMenu()
		
		if (section.uid == 'submission') {
			createSubmissionPayload()
		}
	}
	
	const openSectionMenuBoxFor = (section: Section) => {
		if (section.children?.length && sectionRefs[section.uid]) {
			sectionRefs[section.uid]?.open()
		}
	}
	
	const sectionRefs = reactive({})
	let sections = ref<Section[]>([])
	
	watchEffect(() => {
		sections.value = [
			{
				uid: 'pages',
				label: 'Pages',
				icon: 'dev/page-layout',
				
				children: [
					{
						uid: 'pages-intro',
						label: 'Intro'
					},
					
					...protocolStoreRefs.pages.value.map(page => ({
						uid: 'pages-page-' + page.data.uid,
						label: language.parse(page.data.name) + '<span class="secondary">' + page.data.uid + '</span>',
						page_uid: page.data.uid,
						validated: page.data.validated
					})),
					
					{
						uid: 'pages-outro',
						label: 'Outro'
					}
				]
			},
			
			{
				uid: 'persons',
				label: 'Persons',
				icon: 'acq/persons'
			},
			
			{
				uid: 'products',
				label: 'Products',
				icon: 'dev/basket'
			},
			
			{
				uid: 'screenshots',
				label: 'Screenshots',
				icon: 'dev/screenshot',
				
				children: protocolStoreRefs.create_pages_pdf.value ? protocolStoreRefs.pages.value.map(page => ({
					uid: 'screenshots-page-' + page.data.uid,
					label: language.parse(page.data.name) + '<span class="secondary">' + page.data.uid + '</span>',
					page_uid: page.data.uid,
					badge: screenshotsStore.counts_by_page_uid[page.data.uid] ?? null
				})) : []
			},
			
			{
				uid: 'stores',
				label: 'Stores',
				icon: 'dev/database',
				
				children: stores.map(store => ({
					uid: 'stores-' + store.key,
					store_key: store.key,
					label: store.label
				}))
			},
			
			{
				uid: 'imports-exports',
				label: 'Imports & Exports',
				icon: 'dev/import-export',
			},
			
			{
				uid: 'submission',
				label: 'Submission',
				icon: 'dev/json'
			},
			
			{
				uid: 'backup',
				label: 'Backup',
				icon: 'dev/export'
			},
			
			{
				uid: 'd2',
				label: '',
				divider: true
			},
			
			{
				uid: 'logs',
				label: 'Logs',
				icon: 'dev/log'
			},
			
			{
				uid: 'd1',
				label: '',
				divider: true
			},
			
			{
				uid: 'condition-tester',
				label: 'Condition Tester',
				icon: 'dev/condition',
			},
			
			{
				uid: 'markdown-tester',
				label: 'Markdown Tester',
				icon: 'dev/json'
			},
			
			{
				uid: 'signature-session-decoder',
				label: 'bankID Decoder',
				icon: 'acq/bankid'
			}
		]
	})
	
	const switchToPageDefinition = (pageUID: UID) => {
		for (let section of sections.value) {
			if (section.uid == 'pages') {
				for (let child of section.children || []) {
					if (child.page_uid == pageUID) {
						switchToSection(section, child)
						return true
					}
				}
			}
		}
		
		return false
	}
	
	const switchToSubmission = () => {
		for (let section of sections.value) {
			if (section.uid == 'submission') {
				switchToSection(section)
				return true
			}
		}
		
		return false
	}
	
	const switchToLogs = () => {
		for (let section of sections.value) {
			if (section.uid == 'logs') {
				switchToSection(section)
				return true
			}
		}
		
		return false
	}
	
	const switchToInitialSection = () => {
		if (currentSection.value) {
			switchToSection(currentSection.value, currentChildSection.value)
		} else {
			const firstSection = sections.value[0]
			
			if (firstSection.children?.length) {
				switchToSection(firstSection, firstSection.children[0])
			} else {
				switchToSection(firstSection)
			}
		}
	}
	
	const onInspectorCloseEvent = () => close()
	
	const intro = protocolStoreRefs.intro
	const outro = protocolStoreRefs.outro
	const pagesByUID = protocolStoreRefs.pages_by_uid
	const createPagesPDF = protocolStoreRefs.create_pages_pdf
	const pages = protocolStoreRefs.pages
	const pagesWithConditions = protocolStoreRefs.pages
	
	onMounted(() => {
		setTimeout(() => {
			switchToInitialSection()
		}, 800)
		
		EventBus.$on('inspector:close', onInspectorCloseEvent)
	})
	
	onBeforeUnmount(() => {
		EventBus.$off('inspector:close', onInspectorCloseEvent)
	})
	
	defineExpose({
		...base.expose,
		switchToSection,
		switchToPageDefinition,
		switchToSubmission,
		switchToLogs,
		open,
		close
	})
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-dev-state-inspector {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		overflow: hidden;
		z-index: 1002;
		
		$background: darken(color("dark-background"), 10%);
		
		background: $background;
		color: color("text-light");
		
		line-height: 1.3;
		
		> .flex {
			display: flex;
			align-items: stretch;
			height: calc(100% - 64px);
		}
		
		> header {
			display: flex;
			align-items: center;
			height: 64px;
			background: lighten($background, 4%);
			
			.menu-button,
			.close {
				width: 64px !important;
				text-align: center;
				
				.menu-btn {
					position: relative;
					z-index: 64;
				}
			}
			
			.menu-button {
				display: none;
			}
			
			.title {
				width: calc(100% - 1 * 64px); // desktop, mobile uses 2 (menu button)
				padding-left: 42px;
				
				h2 {
					font-weight: 600;
					font-size: 1.35rem;
				}
			}
			
			.close {
				/*position: absolute;
				top: 20px;
				right: 20px;*/
				width: 64px;
				text-align: center;
			}
		}
		
		$menuWidth: 256px + 2 * 16px;
		
		> .flex > .menu {
			box-sizing: border-box;
			height: 100%;
			overflow-y: auto;
			width: $menuWidth;
			background: lighten($background, 4%);
			
			padding: 16px;
			padding-top: 0;
			
			h3 {
				font-size: 1.3rem;
				font-weight: 700;
			}
			
			.acquisit-state-inspector-menu-box + .acquisit-state-inspector-menu-box {
				margin-top: 16px;
			}
			
			.acquisit-state-inspector-menu-box {
				border-radius: 12px;
				
				padding: {
					left: 24px;
					right: 16px;
					top: 8px;
					bottom: 8px;
				}
				
				&.open {
					background: darken($background, 0%);
				}
			}
			
			.divider {
				height: 1px;
				background: color("dark-border");
			}
			
			> .divider {
				margin: {
					top: 20px;
					bottom: 20px;
				}
			}
			
			ul {
				
				li {
					display: flex;
					align-items: center;
					font-weight: 600;
					cursor: pointer;
					
					padding: {
						left: 24px;
						right: 16px;
						top: 12px;
						bottom: 12px;
					}
					
					margin: {
						left: -24px;
						right: -16px;
					}
					
					a {
						flex-grow: 1;
					}
					
					span.secondary {
						display: block;
						color: color("light-grey-blue");
						font-weight: 500;
					}
					
					span.badge {
						margin-right: 8px;
						background: rgba(#fff, 0.17);
						color: rgba(#fff, 0.67);
						
						width: 24px;
						height: 24px;
						line-height: 24px;
						text-align: center;
						
						border-radius: 50%;
					}
					
					.svg {
						width: 20px;
						height: 20px;
						margin-top: -3px;
					}
					
					&:hover, &.active {
						background: mix(color("blue"), $background, 17%);
						color: lighten(color("blue"), 10%);
					}
				}
			}
		}
		
		> .flex > .content {
			height: 100%;
			overflow-y: auto;
			width: calc(100% - #{$menuWidth});
			position: relative;
			
			.edge-overlay {
				position: absolute;
				top: 0;
				left: 0;
				
				width: 12px;
				height: 12px;
				
				background: lighten($background, 4%);;
				overflow: hidden;
				
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					background: $background;
					width: 24px;
					height: 24px;
					border-top-left-radius: 12px;
				}
			}
			
			.default-wrapper {
				padding: 24px;
				
				.acquisit-state-inspector-header {
					margin-bottom: 24px;
				}
				
				.acquisit-tabs {
					
					/*.tab-navigation ul li:after {
						background: $background;
					}*/
				}
			}
			
			> .empty {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;
				color: color("light-grey-blue");
				gap: 16px;
			}
		}
		
		.menu-overlay {
			display: none;
		}
		
		@include media(mobile) {
			
			> header {
				
				.menu-button {
					display: block;
				}
				
				.title {
					width: calc(100% - 2 * 64px);
					padding-left: 0;
				}
			}
			
			> .flex > .menu {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 60;
				padding-top: 64px;
				
				transform: translateX(-100%);
				transition: transform 0.2s;
				
				&.open {
					@include box-elevated-shadow;
					transform: none;
				}
			}
			
			> .flex > .content {
				width: 100%;
			}
			
			.menu-overlay {
				display: block;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 55;
				background: rgba($background, 0.8);
				-webkit-backdrop-filter: blur(12px);
				backdrop-filter: blur(12px);
			}
		}
	}
</style>