<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-state-inspector-logs default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>Logs</h2>
			</template>
		
			<template #info>
				<span :class="['flag', 'count']">{{ logs.messages.length }} messages</span>
				<span :class="['flag', 'size']">{{ logSizeHuman }}</span>
			</template>
		</StateInspectorHeader>
		
		<template v-for="entry in logs.messages">
			<div v-if="isLogMessage(entry)"
			     class="log-entry">
				<time class="time">
					{{ formatTime(entry.time) }}
				</time>
				
				<div :class="['level', entry.level]">
					<span>{{ formatLevel(entry.level) }}</span>
				</div>
				
				<div class="args">
					{{ formatArgs(entry.args) }}
				</div>
			</div>
			
			<div v-else-if="isLogEvent(entry)"
			     class="log-event">
				<div class="event">
					{{ entry.event }}
				</div>
				
				<time class="time">
					{{ formatTime(entry.time) }}
				</time>
			</div>
		</template>
		
		<div class="empty" v-if="!logs.messages.length">
			No logs available.
		</div>
	</div>
</template>

<script setup lang="ts">
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { isLogEvent, isLogMessage, LogLevel, type LogMessageArgument, useLogsStore } from '@/stores/log'
	import { DateTimeExt, } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useColor, useColorProps } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { bytesToHuman } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { computed } from 'vue'
	
	const props = defineProps({
		...useColorProps()
	})
	
	const color = useColor(props)
	const logs = useLogsStore()
	
	const logSize = computed(() => JSON.stringify(logs.messages).length)
	const logSizeHuman = computed(() => bytesToHuman(logSize.value))
	
	const formatTime = (time: number): string => {
		return DateTimeExt.fromTimestamp(time).format('DD.MM.YY · HH:mm:ss')
	}
	
	const formatLevel = (level: LogLevel): string => {
		return {
			[LogLevel.WARNING]: 'warning',
			[LogLevel.ERROR]: 'error',
		}[level] ?? 'info'
	}
	
	const formatArgs = (args: LogMessageArgument[]): string => {
		return args.join(' ')
	}
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-logs {
		--small-text: 0.85rem;
	
		.log-event {
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			background: v-bind("color.base.value.withAlpha(0.33).cssHSLA");
			color: v-bind("color.textContrastFor(color.base.value).cssHSLA");
			
			padding: 12px 12px;
			transition: background 0.2s;
			
			margin: {
				left: -12px;
				right: -12px;
			}
			
			font-size: var(--small-text);
			
			.event {
				font-weight: 900;
			}
			
			.time {
				opacity: 0.67;
			}
			
			& + .log-event {
				border-top: 1px solid v-bind("color.base.value.withAlpha(0.4).cssHSLA");
			}
		}
		
		.log-entry + .log-event {
			margin-top: 24px;
		}
		
		.log-entry {
			display: flex;
			gap: 8px;
			user-select: text;
			flex-wrap: wrap;
			line-height: 1.5;
			cursor: text;
			
			padding: 12px 12px;
			transition: background 0.2s;
			
			margin: {
				left: -12px;
				right: -12px;
			}
			
			&:hover {
				background: rgba(#fff, 0.087);
			}
			
			.time {
				width: 118px;
				color: var(--text-secondary);
				font-size: var(--small-text);
			}
			
			.level {
				width: 84px;
				text-transform: uppercase;
				font-size: var(--small-text);
				
				span {
					display: inline-block;
				}
				
				&.w {
					color: var(--orange);
				}
				
				&.e {
					color: var(--red);
				}
				
				&.i {
					color: var(--cyan);
				}
			}
			
			.args {
				// flex: 1;
				width: 100%;
				flex-wrap: wrap;
				gap: 8px;
				font-family: font(monospace);
				font-size: 0.94rem;
			}
			
			& + .log-entry {
				border-top: 1px solid rgba(#fff, 0.167);
			}
			
			&:first-child {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
			
			&:last-child {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
		
		.empty {
			color: var(--text-secondary);
		}
	}
</style>