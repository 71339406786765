import type { ValueHandler } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { usePersonsStore } from '@/stores/persons'

export const handlePersonsAddress: ValueHandler = async ({
	component,
	value,
	setProperties,
	invalidateAll,
}): Promise<void> => {
	invalidateAll()
	
	if (Array.isArray(value)) {
		const personsStore = usePersonsStore()
		
		// If value is set, update the appropriate persons
		for (let change of value) {
			personsStore.setAddress(change.person_id, change.address)
		}
	}
	
	setProperties(component, {
		modelValue: value
	})
}