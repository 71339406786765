<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-state-inspector-backup-view default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>Backup</h2>
			</template>
			
			<template #info>
				<span :class="['flag', 'size', { exceeded: currentBackupSize > 4 * 1024 * 1024 }]">{{ currentBackupSizeHuman }}</span>
			</template>
			
			<template #actions>
				<template v-if="mode == 'view'">
					<acquisit-button type="flat"
					                 bordered
					                 color="green"
					                 theme="dark"
					                 icon="acq/upload"
					                 :loading="loading.storeBoolean['upload-backup']"
					                 :disabled="loading.storeBoolean['upload-backup']"
					                 @click="uploadBackup">Save to Backend</acquisit-button>
					
					<acquisit-button type="flat"
					                 bordered color="blue"
					                 theme="dark"
					                 icon="acq/edit"
					                 @click="switchToEdit">Edit</acquisit-button>
				</template>
				
				<template v-else>
					<acquisit-button type="flat"
					                 color="blue"
					                 theme="dark"
					                 icon="dev/jump-to"
					                 ref="$jumpToTrigger"
					                 @click="openJumpToMenu">Jump to…</acquisit-button>
					
					<acquisit-context-menu :items="jumpToDestinations"
					                       @click="onJumpToSelected"
					                       theme="dark"
					                       ref="$jumpToMenu"
					                       anchor="top-right" />
				</template>
			</template>
		</StateInspectorHeader>
		
		<template v-if="mode == 'edit'">
			<div class="json-editor">
				<div class="editor">
					<div class="gutter">
						<div class="line" v-for="i in currentBackupLineNumbers">{{ i + 1 }}</div>
					</div>
					
					<textarea v-model="backupMutable" ref="$jsonEditorTextarea" />
				</div>
				
				<div class="actions">
					<acquisit-button type="flat"
					                 color="red"
					                 theme="dark"
					                 icon="acq/cross"
					                 @click="cancelEdit">Cancel</acquisit-button>
					
					<acquisit-button type="raised"
					                 color="green"
					                 theme="dark"
					                 icon="acq/checkmark"
					                 :loading="loading.storeBoolean['save-local-backup']"
					                 :disabled="loading.storeBoolean['save-local-backup']"
					                 @click="saveEdit">Save</acquisit-button>
				</div>
			</div>
		</template>
		
		<template v-else>
			<StateInspectorJsonView :json="currentBackupJSON" class="json" />
		</template>
	</div>
</template>

<script setup lang="ts">
	import { computed, nextTick, ref } from 'vue'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent, useLoadingHelper } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import StateInspectorJsonView from '@ui/dev/StateInspectorJsonView.vue'
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import type { Backup } from '@/stores/backup'
	import { useBackup } from '@/stores/backup'
	import { bytesToHuman, forEachObject } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { scrollElementTo } from '@/lib/ui'
	import { useProtocolStore } from '@/stores/protocol'
	import { useAPIStore } from '@/stores/api'
	import type { Button, ContextMenu } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	
	interface JumpToDestination {
		label: string
		key: string
		search: string
	}
	
	const props = defineProps({
		...useBaseComponentProps(),
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'open',
		'close'
	])
	
	const base = useBaseComponent(props, emit)
	const { language, color } = base
	
	const loading = useLoadingHelper()
	
	const backup = useBackup(),
		backupMutable = ref<string|undefined>(undefined)
	
	const $jumpToTrigger = ref<typeof Button|null>(),
		$jumpToMenu = ref<typeof ContextMenu|null>(),
		$jsonEditorTextarea = ref<HTMLTextAreaElement|null>()
	
	const mode = ref<'view'|'edit'>('view')
	
	const currentBackup = ref<Backup>(backup.create())
	const currentBackupJSON = computed(() => JSON.stringify(currentBackup.value))
	const currentBackupJSONHuman = computed(() => JSON.stringify(currentBackup.value, null, 4).replace(/ {4}/g, '\t'))
	const currentBackupSize = computed(() => currentBackupJSON.value.length)
	const currentBackupSizeHuman = computed(() => bytesToHuman(currentBackupSize.value))
	const currentBackupLineNumbers = computed(() => currentBackupJSONHuman.value.match(/\n/g)?.length ?? 0)
	
	const jumpToDestinations = computed((): JumpToDestination[] => {
		const destinations: JumpToDestination[] = []
		const stores = useBackup().STORES
		
		forEachObject(stores, (store, key) => {
			destinations.push({
				label: key,
				key: key,
				search: `\t"${key}": {`
			})
		})
		
		return destinations
	})
	
	const sanitizeJSONNewlines = (json: string) => json.replace(/(.*?): ?("(.*?)\\n(.*?)")(,)?/gm, '$1: "$3\\\\n$4"$5')
	
	const switchToEdit = () => {
		mode.value = 'edit'
		backupMutable.value = currentBackupJSONHuman.value
	}
	
	const cancelEdit = () => {
		mode.value = 'view'
		backupMutable.value = undefined
	}
	
	const saveEdit = async () => {
		if (!backupMutable.value) {
			return
		}
		
		loading.set('save-local-backup')
		await nextTick()
		
		let parsed
		
		try {
			const sanitized = sanitizeJSONNewlines(backupMutable.value)
			parsed = JSON.parse(sanitized)
		} catch (e: any) {
			console.error(e)
			alert('Could not parse JSON: ' + e.message)
			loading.done('save-local-backup')
			return
		}
		
		// Try to restore from modified backup
		try {
			backup.restore(parsed)
		} catch (e: any) {
			console.error(e)
			alert('Could not restore from backup: ' + e.message)
			return
		} finally {
			loading.done('save-local-backup')
		}
		
		mode.value = 'view'
		backupMutable.value = undefined
		
		alert('Backup saved locally')
	}
	
	const uploadBackup = async () => {
		loading.set('upload-backup')
		
		try {
			await useProtocolStore().storeBackup(useAPIStore().token as string)
			alert('Backup saved to backend')
		} catch (e: any) {
			alert('Error saving backup to backend: ' + e.message)
		} finally {
			loading.done('upload-backup')
		}
	}
	
	const findLineOfString = (str: string): number => {
		const backup = currentBackupJSONHuman.value,
			position = backup.indexOf(str)
		
		if (position > -1) {
			let foundNewLines = 0
			
			for (let i = 0; i < position; i++) {
				if (backup[i] == '\n') {
					foundNewLines++
				}
			}
			
			return foundNewLines
		} else {
			alert('Could not find ' + str)
			return 0
		}
	}
	
	const findScrollTopOfString = (str: string): number => {
		if ($jsonEditorTextarea.value) {
			const textarea = $jsonEditorTextarea.value
			const style = document.defaultView!.getComputedStyle(textarea, null),
				lineHeight = Number(String(style.lineHeight).replace('px', '')),
				line = findLineOfString(str)
			
			console.log('line', line, '*', lineHeight, '=', line * lineHeight, 'px')
			
			return lineHeight * line
		}
		
		return 0
	}
	
	const openJumpToMenu = (e: MouseEvent) => {
		if ($jumpToTrigger.value) {
			const x = e.clientX,
				y = e.clientY
			
			$jumpToMenu.value?.open($jumpToTrigger.value.$el, { x, y })
		}
	}
	
	const onJumpToSelected = (item: JumpToDestination) => {
		console.log('jumping', $jsonEditorTextarea.value, 'to', item)
		
		if ($jsonEditorTextarea.value) {
			const scrollTop = findScrollTopOfString(item.search)
			scrollElementTo($jsonEditorTextarea.value, scrollTop, 'top', 0)
		}
	}
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-backup-view {
		height: calc(100% - 64px);
		
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;
		
		.json-editor {
			display: flex;
			align-items: stretch;
			flex-direction: column;
			width: 100%;
			flex-grow: 1;
			gap: 24px;
			margin-bottom: -20px;
			
			.editor {
				display: flex;
				flex-grow: 1;
				
				margin: {
					left: -24px;
					right: -24px;
				}
				
				.gutter {
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					display: none; // TODO Gutter currently always has full height, figure out how to fix that
					
					height: 100%;
					width: 64px;
					// background: darken(color("dark"), 6%);
					background: transparent;
					color: color("light-grey-blue");
					overflow: auto;
					
					padding-left: 20px;
					padding-right: 8px;
					text-align: right;
					
					.line {
						height: 22.5px;
						line-height: 22.5px;
						font-size: 0.8rem;
					}
				}
				
				textarea {
					box-sizing: border-box;
					resize: none;
					width: calc(100% + 2 * 24px);
					height: 100%;
					
					background: transparent;
					color: color("text-light");
					border: 1px solid transparent;
					border-radius: 12px;
					
					font-family: font(monospace);
					font-size: 1rem;
					line-height: 22.5px;
					tab-size: 4;
					outline: 0;
					white-space: pre;
					
					padding: {
						left: 24px;
						right: 24px;
					}
					
					&:focus {
						border: 1px solid rgba(color("blue"), 0.6);
						box-shadow: 0px 0px 16px rgba(color("blue"), 0.6);
					}
				}
			}
			
			.actions {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
</style>