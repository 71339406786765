import { defineStore } from 'pinia'
import { cast } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

export interface APIStoreState {
	/**
	 * live: A regular protocol being filled out. Production mode, basically.
	 * live-preview: A preview of a would-be live protocol from UI Builder.
	 * history-preview: A preview of a historic protocol from UI Builder.
	 */
	mode: 'live'|'live-preview'|'history-preview'
	protocol_token: string|null
}

export const useAPIStore = defineStore({
	id: 'api',
	
	state: () => cast<APIStoreState>({
		mode: 'live',
		protocol_token: null
	}),
	
	getters: {
		token: state => state.protocol_token,
		isLive: state => state.mode == 'live',
		isPreview: state => state.mode == 'live-preview' || state.mode == 'history-preview',
		isLivePreview: state => state.mode == 'live-preview',
		isHistoryPreview: state => state.mode == 'history-preview'
	},
	
	actions: {
		setProtocolToken(token: string) {
			this.protocol_token = token
		},
		
		setLive() {
			this.mode = 'live'
		},
		
		setPreview(type: 'live-preview'|'history-preview' = 'live-preview') {
			this.mode = type
		}
	}
})