import { useAPIStore } from '@/stores/api'
import { useBrandingStore } from '@/stores/branding'
import { applyTagRenames, useComponentsStore } from '@/stores/components'
import { useGenericStore } from '@/stores/generic'
import { usePageStore } from '@/stores/page'
import { usePersonsStore } from '@/stores/persons'
import { useProductsStore } from '@/stores/products'
import { useProtocolStore } from '@/stores/protocol'
import { useScreenshotsStore } from '@/stores/screenshots'
import { toRaw } from 'vue'
import { normalizeComponents, forEachObject, deepValue, getUIDForProduct, clone } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { CycleFinder, useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useLogsStore } from '@/stores/log'

const log = useLogger('stores/backup', useLogger.COLOR_STORE)

export interface Backup {
	build: number
	api: Record<string, any>
	branding: Record<string, any>
	components: Record<string, any>
	generic: Record<string, any>
	page: Record<string, any>
	persons: Record<string, any>
	products: Record<string, any>
	protocol: Record<string, any>
	screenshots: Record<string, any>
}

export const useBackup = () => {
	const STORES = {
		api: useAPIStore(),
		branding: useBrandingStore(),
		generic: useGenericStore(),
		page: usePageStore(),
		persons: usePersonsStore(),
		products: useProductsStore(), // Products need to be restored before protocol to link them properly
		protocol: useProtocolStore(),
		components: useComponentsStore(),
		screenshots: useScreenshotsStore(),
		logs: useLogsStore()
	}
	
	const createForStore = store => {
		if (store.backup) {
			return clone(toRaw(store.backup))
		} else {
			return clone(toRaw(store.$state))
		}
	}

	const create = (): Backup => {
		let backup: Record<string, any> = {
			build: import.meta.env.BUILD_NUMERIC
		}
		
		forEachObject(STORES, (store, key) => {
			backup[key] = createForStore(store)
		})
		
		if (window.DEBUG) {
			const cycles = new CycleFinder().findCycles(backup, 'backup')
			
			if (cycles.length) {
				console.warn('Cycles found:', cycles)
				
				for (let key of cycles) {
					let actualKey = key.substring('backup.'.length).replace(/\[(\d+)]/g, '.$1'),
						actualValue = deepValue(backup, actualKey)
					
					//log.debugFrom('createBackup', actualKey, actualValue)
				}
			}
		}
		
		return backup as Backup
	}

	const restore = (backup: Backup) => {
		// Migrate Acquisit 2.x backups
		if (!backup.generic) {
			backup = migrateAcquisit2Backup(backup)
			log.always.info('Migrated from Acquisit 2')
			log.debug(backup)
		}
		
		try {
			forEachObject(STORES, (store, key) => {
				if (backup[key]) {
					if (store.restoreBackup) {
						store.restoreBackup(backup[key], backup)
					} else {
						store.$state = backup[key]
					}
				}
			})
		} catch (e) {
			log.always.errorFrom('useBackup:restore', 'Could not restore backup', e)
			log.debugFrom('useBackup:restore', backup)
			
			forEachObject(STORES, (store) => {
				store.$reset()
			})
			
			throw e
		}
	}
	
	return {
		STORES,
		create,
		createForStore,
		restore
	}
}

// Acquisit 2.x Migration

const migrateAcquisit2Backup = (acq2: Record<string, any>): Backup & { migrated_from: number } => {
	if (acq2.protocol.outro.components) {
		normalizeComponents(acq2.protocol.outro.components)
	}
	
	return {
		build: import.meta.env.BUILD_NUMERIC,
		migrated_from: acq2.build,
		
		api: {
			protocol_token: acq2.api.token
		},
		
		branding: {
			color: {
				primary: acq2.branding.branding.color.primary,
				secondary: acq2.branding.branding.color.secondary,
			},
			
			logo: {
				primary: acq2.branding.branding.logo.primary,
				secondary: acq2.branding.branding.logo.secondary,
			}
		},
		
		components: {
			components: migrateAcquisit2Components(acq2.components.components, acq2),
			value_updates_disabled: acq2.components.value_updates_disabled
		},
		
		generic: {
			backup_enabled: true,
			build: import.meta.env.BUILD_NUMERIC,
			
			dev: {
				submission_payload_validated_only: acq2.dev_payload_validated_only || true,
				menu_open: false
			}
		},
		
		page: {
			current_page: {
				components: acq2.page.components,
				data: acq2.page.data,
				guards: acq2.page.guards,
			}
		},
		
		persons: acq2.persons,
		products: acq2.products,
		
		protocol: {
			client: acq2.protocol.client,
			order: acq2.protocol.order,
			ticket_id: acq2.protocol.ticket_id,
			start_date: acq2.protocol.start_date,
			intro: acq2.protocol.intro,
			outro: acq2.protocol.outro,
			pages: acq2.protocol.pages,
			components_dependencies: acq2.protocol.components_dependencies,
			style: acq2.protocol.style,
			language: acq2.protocol.language,
			validate_page_label: acq2.protocol.validate_page_label,
			back_button_label: acq2.protocol.back_button_label,
			menu_button_label: acq2.protocol.menu_button_label,
			jump_to_content_label: acq2.protocol.jump_to_content_label,
			reset_protocol_label: acq2.protocol.reset_protocol_label,
			reset_protocol_modal: acq2.protocol.reset_protocol_modal,
			additional_information_label: acq2.protocol.additional_information_label,
			additional_information_modal: acq2.protocol.additional_information_modal,
			create_pages_pdf: acq2.protocol.create_pages_pdf
		},
		
		screenshots: {
			screenshots: acq2.screenshots.screenshots,
			logo: acq2.screenshots.logo
		}
	}
}

type Acq2ComponentUIDDictionary = { [uid: string]: any }

type Acq2ComponentDictionary = {
	[tag: string]: Acq2ComponentUIDDictionary
}

const migrateAcquisit2Components = (components: Acq2ComponentDictionary, rootBackup: Record<string, any>) => {
	forEachObject(components, (componentsByUID: Acq2ComponentUIDDictionary) => {
		forEachObject(componentsByUID, (component: any) => {
			// The store will re-run normalizeComponents. This is just to clean up the basics so regular restore can do its job
			normalizeComponents([ component ], false)
			applyTagRenames([ component ])
			
			// Product overrides
			switch (component.tag_normalized) {
				case 'single-product-chooser-list':
				case 'single-product-chooser-carousel':
					if (Array.isArray(component.properties.modelValue)) {
						// Map old UID to new UID (type-UID)
						component.properties.modelValue = component.properties.modelValue.map(p => getUIDForProduct(p))
					}
					
					break
			}
		})
	})
	
	return components
}