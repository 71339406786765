export const PromiseAllSettled = ((promises) => Promise.all(promises.map(p => p
	.then(value => ({
		status: 'fulfilled',
		value
	}))
	.catch(reason => ({
		status: 'rejected',
		reason
	}))
)));

export const PromiseFinally = Promise.prototype.finally || {
	finally (fn) {
		const onFinally = callback => Promise.resolve(fn()).then(callback);
		return this.then(
			result => onFinally(() => result),
			reason => onFinally(() => Promise.reject(reason))
		);
	}
}.finally;

export const installPolyfills = () => {
	if (!Promise.allSettled) {
		Promise.allSettled = PromiseAllSettled
	}

	if (!Promise.prototype.finally) {
		Promise.prototype.finally = PromiseFinally
	}
}

import { Buffer } from 'buffer'
window.Buffer = Buffer
