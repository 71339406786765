import type { ValidationContext } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { validateComponents, extractValuesFromComponents, ValidationError, labelFor } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { collectByTag, collectByUID } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { parseSignature, usePersonsStore } from '@/stores/persons'
import { validatePerson } from '@/validators/person'
import type { ParsedBankIDSignature } from '@/lib/types'
import { useProtocolStore } from '@/stores/protocol'

export const SIGNATURE_MODE_PERSONS_SINGLE = 'persons_single'
export const SIGNATURE_MODE_PERSONS_MULTIPLE = 'persons_multiple'
export const SIGNATURE_MODE_ON_BEHALF = 'on_behalf'
export const SIGNATURE_MODE_SIGNING = 'signing'

export const validateSignature = ({ component, setProperties, productsByUID, personsByID, pagesByUID, owner }: ValidationContext): Promise<boolean> => new Promise((resolve, reject) => {
	const personsStore = usePersonsStore(),
		protocolStore = useProtocolStore()
	
	const createError = (message: string, isUserVisible: boolean = false, key = 'modelValue', owner: any = undefined) => new ValidationError(
		message,
		component,
		key,
		owner ?? {
			component_uid: component.properties.uid
		},
		isUserVisible
	)
	
	let value = component.properties.modelValue,
		mode = value?.mode
	
	// Check mode to see what to do
	if (mode == SIGNATURE_MODE_ON_BEHALF) {
		// Validate current components
		let components = value.on_behalf
		
		validateComponents({
			components,
			componentsByUID: collectByUID(components),
			componentsByTag: collectByTag(components),
			pagesByUID,
			productsByUID,
			personsByID,
			owner: {
				component_uid: component.properties.uid,
				path: (owner?.path || []).concat([ { component_uid: component.properties.uid } ])
			},
			setProperties: (component, properties) => {
				for (let key in properties) {
					if (properties.hasOwnProperty(key)) {
						component.properties[key] = properties[key]
					}
				}
			}
		}).then(_ => {
			setProperties(component, {
				mode: SIGNATURE_MODE_PERSONS_MULTIPLE
			})
			
			resolve(true)
		}).catch(e => {
			reject(e)
		})
	} else if (mode == SIGNATURE_MODE_SIGNING) {
		let validationPromises: Promise<any>[] = []
		
		for (let personID of value.person_ids) {
			validationPromises.push(validatePerson(personsStore.by_id[personID]))
		}
		
		// When all validations have run, collect errors and reject if there are any
		// @ts-ignore
		Promise.allSettled(validationPromises).then(results => {
			let errors: ValidationError[] = []
			
			for (let result of results) {
				if (result.status == 'rejected') {
					if (Array.isArray(result.reason)) {
						errors = errors.concat(result.reason)
					} else if (result.reason instanceof ValidationError) {
						errors.push(result.reason)
					} else {
						errors.push(createError(
							labelFor(component.validation_error_labels?.person_invalid_signature, protocolStore.language, result.reason)!.replace('%s', result.reason),
							Boolean(component.validation_error_labels?.person_invalid_signature)
						))
					}
				}
			}
			
			if (errors.length) {
				reject(errors)
			} else {
				setProperties(component, {
					mode: SIGNATURE_MODE_PERSONS_SINGLE
				})
				
				personsStore.select(null)
				resolve(true)
			}
		})
	} else if (mode == SIGNATURE_MODE_PERSONS_MULTIPLE) {
		if (!value.multi_selected_persons?.length) {
			reject([
				createError(
					labelFor(component.validation_error_labels?.persons_selection_missing, protocolStore.language, 'At least one person must be selected')!,
					Boolean(component.validation_error_labels?.persons_selection_missing),
					'multi_selected_persons'
				)
			])
			return
		}
		
		// Update each person's on behalf values
		let onBehalf = extractValuesFromComponents({
			componentsByUID: collectByUID(value.on_behalf),
			productsByUID,
			personsByID
		}, true)
		
		let values = onBehalf.values,
			images = (<any> onBehalf).images
		
		for (let person of value.multi_selected_persons) {
			personsStore.setOnBehalf(person, {
				name: values['on-behalf-name'],
				myself: String(values['on-behalf-name']).length == 0,
				powerOfAttorneyReceived: values['on-behalf-power-of-attorney-received-yes'] === true,
				images: images?.['on-behalf-power-of-attorney-images'] || []
			})
			
			personsStore.setSignature(person, null)
			personsStore.setSignatureType(person, null)
			
			if (person.validated) {
				personsStore.invalidatePerson(person)
			}
		}
		
		// User has selected multiple persons, select them all in the state and go to the next step
		personsStore.select(value.multi_selected_persons)
		
		setProperties(component, {
			mode: SIGNATURE_MODE_SIGNING
		})
		
		resolve(true)
	} else {
		// Check that all required persons have signed
		let persons = personsStore.required_list,
			allRequiredPersonsValidated = personsStore.all_required_validated,
			allRequiredSignaturesExist = false,
			signedCountsPerType: { [type: string]: number } = {},
			config = component.properties.required_signatures || null,
			allowAbsentAsValidSignature = component.properties.absent_as_required_signature ?? true,
			errors: ValidationError[] = []
		
		if (!persons.length) {
			reject([
				createError(
					labelFor(component.validation_error_labels?.no_persons, protocolStore.language, 'No persons available to sign for')!,
					Boolean(component.validation_error_labels?.no_persons),
					'persons'
				)
			])
			
			return
		}
		
		if (!allRequiredPersonsValidated) {
			const promises: Promise<any>[] = []
			
			for (let person of persons) {
				if (!person.validated) {
					// Re-run validation to get the specific error
					promises.push(validatePerson(person))
				}
			}
			
			Promise.allSettled(promises).then((results) => {
				for (let result of results) {
					if (result.status == 'rejected') {
						if (result.reason instanceof ValidationError) {
							errors.push(result.reason)
						}
					}
				}
				
				if (errors.length) {
					errors.push(createError(
						labelFor(component.validation_error_labels?.at_least_one_person_not_validated, protocolStore.language, 'At least one person not validated')!,
						Boolean(component.validation_error_labels?.at_least_one_person_not_validated),
						'all_required_validated'
					))
				}
				
				reject(errors)
			})
			
			return
		}
		
		for (let person of persons) {
			if (signedCountsPerType[person.type] == undefined) {
				signedCountsPerType[person.type] = 0
			}
			
			if (person.signature && !(!allowAbsentAsValidSignature && person.absent)) {
				signedCountsPerType[person.type]++
			}
		}
		
		if (!config) {
			// Default: One person of any type must sign
			for (let count of Object.values(signedCountsPerType)) {
				if (count > 0) {
					allRequiredSignaturesExist = true
					break
				}
			}
		} else {
			// Check that all forced person types exist
			for (let type in config) {
				if (config.hasOwnProperty(type)) {
					switch (config[type]) {
						case 'force-one':
						case 'force-two':
						case 'force-all':
							if (!signedCountsPerType[type]) {
								const personTypeLabel = component.properties.person_type_labels?.[type] ?? type
								
								reject([
									createError(
										labelFor(component.validation_error_labels?.no_persons_for_type, protocolStore.language, 'No persons available for type %s')!.replace('%s', personTypeLabel),
										Boolean(component.validation_error_labels?.no_persons_for_type),
										'person_types'
									)
								])
								
								return
							}
							
							break
					}
				}
			}
			
			let personsPerType = {}
			
			for (let person of persons) {
				if (!personsPerType[person.type]) {
					personsPerType[person.type] = 0
				}
				
				personsPerType[person.type]++
			}
			
			// Set signed to true, then try to break it
			allRequiredSignaturesExist = true
			
			// Check config
			for (let type in signedCountsPerType) {
				if (signedCountsPerType.hasOwnProperty(type)) {
					let count = signedCountsPerType[type]
					
					if (personsPerType[type] < 1) {
						continue
					}
					
					switch (config[type] || 'none') {
						case 'one':
						case 'force-one':
							if (count == 0) {
								allRequiredSignaturesExist = false
							}
							break
						
						case 'two':
						case 'force-two':
							if (count < 1) {
								allRequiredSignaturesExist = false
							}
							break
						
						case 'all':
						case 'force-all':
							if (count != personsPerType[type]) {
								allRequiredSignaturesExist = false
							}
							break
						
						default:
						// Nothing
					}
				}
			}
			
			// If there are no persons at all, that's not valid
			if (!persons.length) {
				reject([
					createError(
						labelFor(component.validation_error_labels?.no_persons, protocolStore.language, 'No persons available to sign for')!,
						Boolean(component.validation_error_labels?.no_persons),
						'persons'
					)
				])
				
				return
			}
		}
		
		if (!allRequiredSignaturesExist) {
			reject([
				createError(
					labelFor(component.validation_error_labels?.at_least_one_signature_missing, protocolStore.language, 'At least one signature missing')!,
					Boolean(component.validation_error_labels?.at_least_one_signature_missing),
					'required_signatures'
				)
			])
			
			return
		}
		
		// Find out if there's duplicate signatures
		let signatureHashes = {},
			duplicateSignaturePersonIDs: string[]|null = null
		
		for (let person of persons) {
			if (person.signature) {
				let signatureType = person.signature_type?.type
				
				switch (signatureType) {
					case 'bankid':
					case 'bankid_mobile':
						if (person.on_behalf?.myself === false) {
							// Not signing on behalf of himself, don't care whose signature this is
							continue
						}
						
						let parsed = parseSignature(person.signature) as ParsedBankIDSignature|null,
							hashed = signatureType + ':' + parsed?.name
						
						if (hashed in signatureHashes) {
							duplicateSignaturePersonIDs = [signatureHashes[hashed], person.id]
							break
						}
						
						signatureHashes[hashed] = person.id
						break
				}
				
				if (duplicateSignaturePersonIDs) {
					break
				}
			}
		}
		
		if (duplicateSignaturePersonIDs) {
			reject(duplicateSignaturePersonIDs.map(personID => new ValidationError(
				labelFor(component.validation_error_labels?.person_duplicate_signature, protocolStore.language, 'Duplicate signature')!,
				component,
				'duplicate_signature',
				{
					component_uid: component.properties.uid,
					person_id: personID
				},
				Boolean(component.validation_error_labels?.person_duplicate_signature)
			)))
			
			return
		}
		
		resolve(true)
	}
})