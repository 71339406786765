declare var process

/**
 * DomainRouter
 * Controller for routing domains based on protocol, path and environment.
 * Fully compatible with our DomainRouter implementation in Asgard
 * though this one was "JS-ified" (native getters and static typing).
 *
 * @author bluefirex
 * @version 1.0
 */
export class DomainRouter {
	protected static domains: Domain[] = []
	
	/**
	 * Initialize domain configuration
	 */
	public static init() {
		/**
		 * About this configuration format:
		 * It is basically the same as the PHP DomainRouter in Midgard.
		 * It has to be an object with objects in the following format:
		 *
		 * {
		 *     host: 'some.host.tld',
		 *     protocol: Domain.PROTOCOL_*,
		 *     mode: Domain.MODE_*
		 * }
		 */
		let domainConfig = {
			[Domain.IDFY_DIGITALOVERTAKELSE]: [
				{
					host: 'idfy.digitalovertakelse.no',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'idfy.digitalovertakelse.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'idfy.digitalovertakelse.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.IDFY_OPPGJORSSKJEMA]: [
				{
					host: 'idfy.oppgjorsskjema.no',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'idfy.oppgjorsskjema.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'idfy.oppgjorsskjema.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.IDFY_ENKLEREFLYTTING]: [
				{
					host: 'idfy.enklereflytting.no',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'idfy.enklereflytting.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'idfy.enklereflytting.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.ACQUISIT]: [
				{
					host: 'acquisit.meglerfront.xyz',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'acquisit.staging.meglerfront.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'acquisit.meglerfront.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.DIGITALOVERTAKELSE]: [
				{
					host: '*.digitalovertakelse.test',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'staging.digitalovertakelse.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: '*.digitalovertakelse.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.DIGITALDOKUMENT]: [
				{
					host: '*.digitaldokument.test',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'staging.digitaldokument.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: '*.digitaldokument.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.OPPGJORSSKJEMA]: [
				{
					host: '*.oppgjorsskjema.test',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'staging.oppgjorsskjema.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: '*.oppgjorsskjema.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.ENKLEREFLYTTING]: [
				{
					host: '*.enklereflytting.test',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'staging.enklereflytting.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: '*.enklereflytting.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.ASGARD]: [
				{
					host: 'asgard.meglerfront.xyz',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'asgard.staging.meglerfront.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'asgard.meglerfront.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.SWEETDESK]: [
				{
					host: 'sweetdesk.meglerfront.xyz',
					protocol: Domain.PROTOCOL_HTTP,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'sweetdesk.staging.meglerfront.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'sweetdesk.meglerfront.no',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			],
			
			[Domain.STATIC_ASSETS]: [
				{
					host: 'vre-mf-asg-assets-dev.s3-eu-central-1.amazonaws.com',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_DEV
				},
				
				{
					host: 'vre-mf-asg-assets.s3-eu-central-1.amazonaws.com',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_STAGING
				},
				
				{
					host: 'vre-mf-asg-assets.s3-eu-central-1.amazonaws.com',
					protocol: Domain.PROTOCOL_HTTPS,
					mode: Domain.MODE_PRODUCTION
				}
			]
		}
		
		for (let id in domainConfig) {
			let hosts = domainConfig[id]
			
			for (let host of hosts) {
				this.domains.push(new Domain(id, host.host, host.protocol, host.mode || Domain.MODE_PRODUCTION))
			}
		}
	}
	
	/**
	 * Check if the domain configuration has been initialized already.
	 *
	 * @return {boolean}
	 */
	public static isInitialized(): boolean {
		return this.domains.length > 0
	}
	
	/**
	 * Get all domain configurations
	 *
	 * @return {Domain[]}
	 */
	public static getAll(): Domain[] {
		return this.domains
	}
	
	/**
	 * Get a specific domain configuration
	 *
	 * @param  {string}                  id=null Domain.* constant or null to detect the current domain
	 * @param  {string = this.getMode()} mode    Mode to get the domain for. Use Domain::MODE_* here. Leave null for auto-detection.
	 *
	 * @return {Domain}
	 */
	public static get(id: string|null = null, mode: string = this.getMode()): Domain {
		if (!this.isInitialized()) {
			this.init()
		}
		
		let host = this.getHost(),
			topLevelHost = this.getTopLevelHost()
		
		for (let domain of this.domains) {
			if (id === null) {
				if (domain.host == host) {
					return domain
				}
				
				// Possible wildcard? Check further
				if (domain.host.indexOf('*') > -1 && Domain.cleanHost(domain.host) == topLevelHost) {
					return domain
				}
			} else {
				if (domain.mode == mode && domain.id == id) {
					return domain
				}
			}
		}
		
		return this.domains[0]
	}
	
	/**
	 * Get the current host.
	 * Returns unknown.visma.(dev|as) when host cannot be determined at all.
	 *
	 * @return {string}
	 */
	public static getHost(): string {
		return window.location.hostname || 'unknown.visma.' + Domain.TLD_DEV
	}
	
	/**
	 * Get the top-level host. That is domain host + TLD, e.g. "meglerfront.no"
	 * @return {string}
	 */
	public static getTopLevelHost(): string {
		return Domain.cleanHost(this.getHost())
	}
	
	/**
	 * Get the currently used port.
	 *
	 * @return {number}
	 */
	public static getPort(): number {
		return parseInt(window.location.port)
	}
	
	/**
	 * Check if the current host is using SSL/TLS.
	 *
	 * @return {boolean}
	 */
	public static isTLS(): boolean {
		return this.getPort() == 443
	}
	
	/**
	 * Get the current mode. Compare with Domain.MODE_*
	 *
	 * @return {string}
	 */
	public static getMode(): string {
		if (this.isDev()) {
			return Domain.MODE_DEV
		} else if (this.isStaging()) {
			return Domain.MODE_STAGING
		}
		
		return Domain.MODE_PRODUCTION
	}
	
	/**
	 * Check if the current mode is DEV.
	 *
	 * @return {boolean}
	 */
	public static isDev(): boolean {
		return this.getEnv() == 'development'
	}
	
	/**
	 * Check if the current mode is STAGING.
	 *
	 * @return {boolean}
	 */
	public static isStaging(): boolean {
		return this.getEnv() == 'staging'
	}
	
	/**
	 * Check if the current mode is PRODUCTION.
	 *
	 * @return {boolean}
	 */
	public static isProduction(): boolean {
		return this.getEnv() == 'production'
	}
	
	/**
	 * Get the current env variable injected by Webpack.
	 *
	 * @return {string}
	 */
	public static getEnv(): string {
		return process.env.NODE_ENV
	}
}

export class Domain {
	public static readonly ASGARD = 'asgard'
	public static readonly ACQUISIT = 'acquisit.meglerfront'
	public static readonly DIGITALOVERTAKELSE = 'digitalovertakelse'
	public static readonly DIGITALDOKUMENT = 'digitaldokument'
	public static readonly OPPGJORSSKJEMA = 'oppgjorsskjema'
	public static readonly ENKLEREFLYTTING = 'enklereflytting'
	public static readonly IDFY_DIGITALOVERTAKELSE = 'idfy.digitalovertakelse'
	public static readonly IDFY_OPPGJORSSKJEMA = 'idfy.oppgjorsskjema'
	public static readonly IDFY_ENKLEREFLYTTING = 'idfy.enklereflytting'
	public static readonly SWEETDESK = 'sweetdesk'
	public static readonly STATIC_ASSETS = 'assets.static'
	
	public static readonly PROTOCOL_HTTP = 'http'
	public static readonly PROTOCOL_HTTPS = 'https'
	
	public static readonly MODE_PRODUCTION = 'prod'
	public static readonly MODE_STAGING = 'staging'
	public static readonly MODE_DEV = 'development'
	
	public static readonly TLD_DEV = 'xyz'
	
	public cleanHost: string
	
	constructor(
		/**
		 * A stringified ID to help identify a domain internally.
		 * Good for comparing development to production domains.
		 *
		 * @type {string}
		 */
		public readonly id: string,
		
		/**
		 * The host. This will be used to detect the domain,
		 * i.e. 'acquisit.meglerfront.no'
		 *
		 * @type {string}
		 */
		public readonly host: string,
		
		/**
		 * The protocol to be used. Use Domain.PROTOCOL_*
		 *
		 * @type {string}
		 */
		public readonly protocol: string,
		
		/**
		 * The mode this domain is being used for. Use Domain.MODE_*
		 *
		 * @type {string}
		 */
		public readonly mode: string = Domain.MODE_PRODUCTION
	) {
		if (this.host.indexOf('*') > -1) {
			this.cleanHost = Domain.cleanHost(this.host)
		} else {
			this.cleanHost = this.host
		}
	}
	
	/**
	 * Get the base URL.
	 * This includes protocol, hostname+port (if port != 80/443) and trailing slash, i.e.:
	 * https://example.com:8080/
	 *
	 * @return {string}
	 */
	public get baseURL(): string {
		return this.protocol + '://' + this.cleanHost
	}
	
	/**
	 * Get the base URL with a path, i.e.:
	 * https://example.com:8080/echo/me
	 *
	 * @param  {string[]|string} path Array (without slashes) or string (including slashes)
	 *
	 * @return {string}
	 */
	public withPath(path: string[] | string): string {
		if (Array.isArray(path)) {
			path = '/' + path.join('/')
		}
		
		return this.baseURL + path
	}
	
	/**
	 * Check if this domain is a specific ID.
	 *
	 * @param  {string}  id Domain.*
	 *
	 * @return {boolean}
	 */
	public is(id: string): boolean {
		return this.id == id
	}
	
	/**
	 * Check if this domain is dev.
	 *
	 * @return {boolean}
	 */
	public isDev(): boolean {
		return this.mode == Domain.MODE_DEV
	}
	
	/**
	 * Check if this domain is staging
	 *
	 * @return {boolean}
	 */
	public isStaging(): boolean {
		return this.mode == Domain.MODE_STAGING
	}
	
	/**
	 * Check if this domain is production
	 *
	 * @return {boolean}
	 */
	public isProduction(): boolean {
		return this.mode == Domain.MODE_PRODUCTION
	}
	
	/**
	 * Clean the hostname from any subdomains
	 * @param {string} str
	 * @return {string}
	 */
	public static cleanHost(str): string {
		let parts = str.split('.')
		
		if (parts.length < 2) {
			return ''
		}
		
		return parts[parts.length - 2] + '.' + parts[parts.length - 1]
	}
	
	/**
	 * Alias for baseURL for your convenience.
	 *
	 * @return {string}
	 */
	public toString(): string {
		return this.baseURL
	}
}