import type { AxiosResponse, CancelToken } from 'axios'
import axios from 'axios'
import { useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

const log = useLogger('backend/vapor', useLogger.COLOR_BACKEND)

export interface VaporOptions {
	/**
	 * Which bucket to use
	 */
	bucket?: string
	/**
	 * Content type of the uploaded file
	 */
	contentType?: string
	/**
	 * Expiration date
	 */
	expires?: string
	/**
	 * Visibility in the bucket, leave undefined to leave private
	 */
	visibility?: 'public-read'
	/**
	 * @param {number} progress 0-1
	 */
	onProgress?: (progress: number) => void
	/**
	 * Signed URL to upload to, leave undefined to use default
	 */
	signedStorageURL?: string
	/**
	 * Token to cancel the upload
	 */
	cancelToken?: CancelToken
}

export interface CloudFile {
	uuid: string
	key: string
	bucket: string
}

/**
 * Store a file in the cloud
 */
export const storeFile = async (file: any, options: VaporOptions = {}): Promise<CloudFile> => {
	log.debugFrom('storeFile', 'Retrieving signed URL…')
	
	let response: AxiosResponse,
		headers: Record<string, any>
	
	try {
		response = await axios.post(options.signedStorageURL || import.meta.env.ACQUISIT_BACKEND_PYLON_VAPOR_SIGNED_STORAGE_URL, {
			bucket: options.bucket || '',
			content_type: options.contentType || 'text/plain',
			expires: options.expires || '',
			visibility: options.visibility || ''
		})
		
		headers = response.data.headers
		
		if (headers['Host']) {
			delete headers.Host
		}
		
		log.debugFrom('storeFile', 'Received', response)
	} catch (e) {
		log.always.errorFrom('storeFile', e)
		throw e
	}
	
	const cancelToken = options.cancelToken || undefined
	
	log.debugFrom('storeFile', 'Uploading…')
	
	const putResponse = await axios.put(response.data.url, file, {
		cancelToken,
		headers,
		onUploadProgress: (event) => {
			if (event.total !== undefined) {
				log.debugFrom('storeFile, onUploadProgress', event.loaded / event.total)
				options.onProgress?.(event.loaded / event.total)
			}
		}
	})
	
	log.debugFrom('storeFile', 'Done', putResponse)
	
	return response.data
}