import { computed, ref, watch } from 'vue'
import { useProtocolStore } from '@/stores/protocol'
import { useRoute, useRouter } from 'vue-router'
import { useRaygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { clone } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { useAPIStore } from '@/stores/api'

export const usePageRouterProps = () => ({
	nextPageUid: {
		type: [Array, String],
		required: false,
		default: null
	}
})

/**
 * Helper for working with navigation in a multipage UI
 */
export const usePageRouter = (props) => {
	const previousIndex = ref<number|null>(null)
	const currentIndex = ref<number|null>(null)
	
	const $router = useRouter()
	const $route = useRoute()
	const protocolStore = useProtocolStore()
	const apiStore = useAPIStore()
	
	const pagesByUID = computed(() => protocolStore.pages_by_uid)
	const pages = computed(() => protocolStore.enabled_pages)
	const maxIndex = computed(() => pages.value.length - 1)
	
	/**
	 * Update the current index to match the currently visited page (if applicable)
	 */
	const setRouteIndex = () => {
		if (!$route) {
			return
		}
		
		previousIndex.value = currentIndex.value
		
		// Find the index of the currently active page
		for (let index in pages.value) {
			if (pages.value.hasOwnProperty(index)) {
				const page = pages.value[index]
				
				if (page.data.uid == $route.params.page) {
					currentIndex.value = Number(index)
					break
				}
			}
		}
	}
	
	/**
	 * Navigate to a specific page
	 *
	 * @param {string}              pageID  Page's UID
	 * @param {any[] | null}        next    Page UIDs to navigate to afterwards
	 *
	 * @returns {Promise<void>}
	 */
	const navigateToRoute = async(pageID: string, next: any[]|null = null) => {
		if (!$router || !$route) {
			return
		}
		
		let query = $route.query
		
		if (Array.isArray(next) && next.length) {
			query.next = next
		} else if (next) {
			query.next = next
		} else {
			delete query.next
		}
		
		try {
			const routeName = apiStore.isLivePreview ? 'page-preview' :
			                  apiStore.isHistoryPreview ? 'page-history-preview' :
			                  'page'
			
			await $router.push({
				name: routeName,
				params: {
					page: pageID,
					token: $route.params.token
				},
				query
			})
			
			if (import.meta.env.DEV) {
				// Draw a new line as a better distinction
				console.log('—'.repeat(16))
			}
			
			useRaygun().recordBreadcrumb('Navigate to ' + pageID, { location: 'page-router.ts:navigateToRoute' })
		} catch (e: any) {
			if (e.name == 'NavigationDuplicated') {
				// ignore *yawn*
			} else {
				throw e
			}
		}
	}
	
	/**
	 * Navigate a page forward (or the last one if already on the last)
	 *
	 * @returns {Promise<void>}
	 */
	const navigateForward = async () => {
		// Update Router
		setRouteIndex()
		
		let page,
			nextPageUIDs
		
		// If next page is set, go there
		if (props.nextPageUid) {
			let nextPageUID
			
			if (Array.isArray(props.nextPageUid)) {
				nextPageUIDs = clone(props.nextPageUid)
				
				if (props.nextPageUid.length) {
					nextPageUID = nextPageUIDs.shift()
				}
			} else {
				nextPageUID = props.nextPageUid
			}
			
			if (nextPageUID && pagesByUID.value[nextPageUID]) {
				page = pagesByUID.value[nextPageUID]
			}
		}
		
		if (!page) {
			page = pages.value[Number(currentIndex.value) + 1] || pages.value[pages.value.length - 1]
		}
		
		return await navigateToRoute(page.data.uid, nextPageUIDs)
	}
	
	/**
	 * Navigate a page backwards (or the first one if already on the first)
	 *
	 * @returns {Promise<void>}
	 */
	const navigateBackwards = async () => {
		let page = pages.value[Number(currentIndex.value) - 1] || pages.value[0]
		return await navigateToRoute(page.data.uid)
	}
	
	/**
	 * Resolve a route to a page
	 *
	 * @param {string} pageID   Page's UID
	 *
	 * @returns {RouteLocation}
	 */
	const resolvePageRoute = (pageID: string) => {
		const routeName = apiStore.isLivePreview ? 'page-preview' :
		                  apiStore.isHistoryPreview ? 'page-history-preview' :
		                  'page'
		
		return resolveRoute(routeName, {
			page: pageID,
			token: $route.params.token
		})
	}
	
	/**
	 * Resolve a route
	 *
	 * @param {string}                  name    Route's name
	 * @param {Record<string, any>}     params  Params for the final route
	 *
	 * @returns {RouteLocation}
	 */
	const resolveRoute = (name: string, params: Record<string, any>) => $router.resolve({
		name,
		params
	})
	
	watch($route, () => {
		setRouteIndex()
	}, {
		immediate: true,
	})
	
	return {
		previousIndex,
		currentIndex,
		pagesByUID,
		pages,
		maxIndex,
		$route,
		$router,
		
		setRouteIndex,
		navigateToRoute,
		navigateForward,
		navigateBackwards,
		resolvePageRoute
	}
}