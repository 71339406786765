import type { BackendError, JSError, NetworkError, NoResponseError } from '@/lib/backend/responses'
import { ResponseType } from '@/lib/backend/responses'
import { errorMessage } from '@/lib/ui'
import { deepMerge, params } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import axios, { type AxiosResponse } from 'axios'
import { useLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

const log = useLogger('lib/backend/AxiosConnector')

export default abstract class AxiosConnector {
	public readonly NAME: string = ''
	
	/**
	 * Make a GET request
	 *
	 * @param {string}  path
	 * @param {object}  query           Query params, will be appended to the URL, supports arrays
	 * @param {boolean} handleErrors    If true, handle errors automatically
	 *
	 * @return {Promise<any>}
	 */
	public get(path: string, query: any = {}, handleErrors: boolean = true): Promise<AxiosResponse> {
		return this.call({
			method: 'get',
			url: path,
			params: query,
			handleErrors
		})
	}
	
	/**
	 * Make a PATCH request
	 *
	 * @param {string}                  path
	 * @param {object|string|FormData}  data            Data to send
	 * @param {boolean}                 handleErrors    If true, handle errors automatically
	 *
	 * @return {Promise<any>}
	 */
	public patch(path: string, data, handleErrors: boolean = false): Promise<AxiosResponse> {
		return this.call({
			method: 'patch',
			url: path,
			data,
			handleErrors
		})
	}
	
	/**
	 * Make a POST request
	 *
	 * @param {string}                  path
	 * @param {object|string|FormData}  data            Data to send
	 * @param {boolean}                 handleErrors    If true, handle errors automatically
	 *
	 * @return {Promise<any>}
	 */
	public post(path: string, data, handleErrors: boolean = false): Promise<AxiosResponse> {
		return this.call({
			method: 'post',
			url: path,
			data,
			handleErrors
		})
	}
	
	/**
	 * Make a PUT request
	 *
	 * @param {string}                  path
	 * @param {object|string|FormData}  data            Data to send
	 * @param {boolean}                 handleErrors    If true, handle errors automatically
	 *
	 * @return {Promise<any>}
	 */
	public put(path: string, data, handleErrors: boolean = false): Promise<AxiosResponse> {
		return this.call({
			method: 'put',
			url: path,
			data,
			handleErrors
		})
	}
	
	/**
	 * Make A DELETE request
	 *
	 * @param {string}  path
	 * @param {boolean} handleErrors    If true, handle errors automatically
	 *
	 * @return {Promise<any>}
	 */
	public delete(path: string, handleErrors: boolean = false): Promise<AxiosResponse> {
		return this.call({
			method: 'delete',
			url: path,
			handleErrors
		})
	}
	
	/**
	 * Make a custom HTTP request using Axios
	 *
	 * @param {string}                          method          Method to use
	 * @param {string}                          path
	 * @param {string|object|Array|FormData}    data
	 * @param {object}                          opts            Axios options, https://github.com/axios/axios#request-config
	 * @param {boolean}                         handleErrors    If true, handle errors automatically
	 *
	 * @return {Promise<any>}
	 */
	public custom(method: string, path: string, data, opts, handleErrors: boolean = true): Promise<AxiosResponse> {
		return this.call({
			method,
			url: path,
			data,
			...opts,
			handleErrors,
		})
	}
	
	/**
	 * Retry a request when the response data was null
	 *
	 * @param {() => Promise<any>}  requestPromise  Function returning a promise to retry
	 * @param {number}              times           Maximum number of times to try
	 * @param {number}              timeout         Time to wait between requests in ms
	 *
	 * @return {Promise<any>}   Returned promise either resolves with the same data the requestPromise returned or throws an NoResponseError or other error
	 */
	public retry<T = AxiosResponse>(requestPromise: () => Promise<T>, times: number, timeout: number = 350): Promise<T> {
		let go = (times: number) => {
			return requestPromise().then(response => {
				if (response === null || (<any> response).status && (<any> response).data === null) {
					throw new Error('data is null')
				}
				
				return response
			}).catch(e => {
				if (e.message == 'data is null') {
					if (times - 1 > 0) {
						return new Promise((resolve) => {
							setTimeout(_ => {
								resolve(null)
							}, timeout)
						}).then(_ => go(times - 1))
					} else {
						throw <NoResponseError> {
							type: ResponseType.NoResponseError,
							message: 'No response'
						}
					}
				}
				
				throw e
			})
		}
		
		return go(times)
	}
	
	/**
	 * Get the base URL to prepend before the path given some opts
	 *
	 * @param {Object} opts
	 */
	public abstract getBaseURL(opts)
	
	/**
	 * Get headers that should be set globally given some opts
	 *
	 * @param {Object} opts
	 */
	protected abstract getHeaders(opts): {}
	
	// noinspection JSMethodCanBeStatic
	public handleError(error) {
		log.always.errorFrom(this.NAME, error)
		
		if (error.type) {
			switch (error.type) {
				case ResponseType.NoResponseError:
					const message = 'No server response'
					errorMessage(message, 'Network Error')
					
					return message
				
				case ResponseType.NetworkError:
					errorMessage(error.message, 'Network Error')
					return error.message
				
				case ResponseType.BackendError:
					if (error.status == 500) {
						try {
							let errorJSON = {
								status: error.status,
								code: error.code,
								message: error.message,
								details: error.details
							}
							
							errorMessage('<pre style="text-align:left; overflow-x: auto; -webkit-overflow-scrolling: touch;">' + JSON.stringify(errorJSON, null, 4) + '</pre>', 'Internal Error', 0, true, false, true)
							
							return error.message
						} catch (e) {
							errorMessage(error, undefined, 0, true, false, true)
							return error
						}
					} else if (error.message) {
						errorMessage(error.message)
						return error.message
					} else {
						errorMessage('<pre style="text-align:left; overflow-x: auto; -webkit-overflow-scrolling: touch;">' + JSON.stringify(error, null, 4) + '</pre>', 'Internal Error', 0, true, false, true)
						return error.message
					}
				
				case ResponseType.JSError:
					console.error(error.error)
					errorMessage(error.message)
					
					return error.message
			}
		} else {
			return this.handleError(this.parseError(error))
		}
	}
	
	/**
	 * Make an HTTP call using Axios
	 * https://github.com/axios/axios#request-config
	 *
	 * @param {object} opts Axios options
	 * @return {Promise<any>}
	 */
	protected call(opts): Promise<AxiosResponse> {
		// Default options which can be overridden by opts
		let axiosOptions = deepMerge({
			method: 'get',
			baseURL: this.getBaseURL(opts),
			headers: this.getHeaders(opts)
		}, opts)
		
		// Figure out additional headers
		if (opts.data instanceof FormData) {
			axiosOptions.headers['Content-Type'] = 'multipart/form-data'
			axiosOptions.responseType = 'text' // No processing for FormData
		} else if (opts.data instanceof URLSearchParams) {
			axiosOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded'
		} else if (opts.headers?.['Content-Type'] == 'text/plain') {
			axiosOptions.responseType = 'text'
		} else {
			axiosOptions.headers['Content-Type'] = 'application/json; charset=utf8'
			axiosOptions.responseType = 'json'
		}
		
		// Default Error Handling?
		let promise = axios(axiosOptions)
		
		if (opts.handleErrors) {
			promise.catch(error => {
				this.handleError(this.parseError(error))
			})
		}
		
		return promise
	}
	
	// noinspection JSMethodCanBeStatic
	public parseError(error): BackendError|NoResponseError|JSError|NetworkError {
		if (typeof(error) == 'string') {
			return <BackendError> {
				type: ResponseType.BackendError,
				message: error
			}
		} else if (error && error.response) {
			let message: string,
				code: number|null = null,
				details = null
			
			if (typeof(error.response.data) == 'string') {
				message = error.response.data
			} else if (error.response.data && error.response.data.exception) {
				message = error.response.data.exception + (error.response.data.message ? ': ' + error.response.data.message : '')
				details = error.response.data.trace
			} else if (error.response.data) {
				message = error.response.data.message || JSON.stringify(error.response.data)
				code = error.response.data.code || null
				details = error.response.data.details || null
			} else if (error.response.statusText) {
				message = error.response.statusText + ' [' + JSON.stringify(error.response.data) + ']'
				code = error.response.status
				details = null
			} else {
				message = 'Unknown error: ' + JSON.stringify(error.response)
				code = null
				details = null
			}
			
			return <BackendError> {
				type: ResponseType.BackendError,
				status: error.response.status,
				headers: error.response.headers,
				message,
				code,
				details,
				request: error.request
			}
		} else if (error && error.request) {
			return <NoResponseError> {
				type: ResponseType.NoResponseError,
				message: 'Response missing',
				request: error.request
			}
		} else if (error instanceof Error) {
			return <JSError> {
				type: ResponseType.JSError,
				message: error.toString(),
				error
			}
		} else {
			return <NetworkError> {
				type: ResponseType.NetworkError,
				message: error && error.message ? error.message : error
			}
		}
	}
	
	public makeURL(path: string, query = {}): string {
		const opts = {
			method: 'get',
			url: path,
			params: query
		}
		
		let baseURL = this.getBaseURL(opts)
		let queryParams = deepMerge(this.getHeaders(opts), query)
		
		return baseURL + path + '?' + params(queryParams)
	}
}