<!--
	@author bluefirex
-->
<template>
	<header class="acquisit-state-inspector-header">
		<div class="title">
			<slot></slot>
			
			<div class="info" v-if="$slots.info">
				<slot name="info"></slot>
			</div>
		</div>
		
		<div class="actions">
			<slot name="actions"></slot>
		</div>
	</header>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-header {
		display: flex;
		align-items: center;
		
		.title {
			flex-grow: 1;
			line-height: 1.5;
			
			h2 {
				display: flex;
				align-items: center;
				gap: 8px;
				
				font-weight: 600;
				
				.svg {
					width: 20px;
					height: 20px;
					color: color("green") !important;
				}
			}
			
			.info {
				margin-top: 4px;
			}
			
			.flag {
				display: inline-flex;
				align-items: center;
				gap: 8px;
				vertical-align: middle;
				
				background: transparent;
				border: 1px solid color("dark-border");
				border-radius: 6px;
				color: color("light-grey-blue");
				padding: 4px 8px;
				
				.svg {
					width: 16px;
					height: 16px;
				}
				
				& + .flag {
					margin-left: 8px;
				}
				
				&.uid {
					border-color: rgba(color("amber"), 0.4);
					color: color("amber");
					font-weight: 600;
				}
				
				&.size {
					border-color: rgba(color("lime"), 0.4);
					color: color("lime");
					font-weight: 600;
					
					&.exceeded {
						border-color: rgba(color("red"), 0.4);
						color: color("red");
						font-weight: 900;
					}
				}
				
				&.pdf-size {
					border-color: rgba(color("white"), 0.4);
					color: color("white");
					font-weight: 600;
					
					&.exceeded {
						border-color: rgba(color("red"), 0.4);
						color: color("red");
						font-weight: 900;
					}
				}
			}
		}
		
		.actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;
		}
		
		@include media(mobile) {
			display: block;
			
			.actions {
				margin-top: 16px;
			}
		}
	}
</style>