import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'
import { useEventListener } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'

export interface OnlineHelper {
	isOnline: ComputedRef<boolean>
}

/**
 * Helper for detecting whether the device is online or offline
 */
export const useOnline = (): OnlineHelper => {
	const isOnline = ref(navigator.onLine),
		isOnlineReadonly = computed(() => isOnline.value)
	
	const onOnlineStatusChange = () => {
		isOnline.value = navigator.onLine
	}
	
	useEventListener(window, 'online', onOnlineStatusChange)
	useEventListener(window, 'offline', onOnlineStatusChange)
	
	return {
		isOnline: isOnlineReadonly
	}
}