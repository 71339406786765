<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-state-inspector-screenshots-view default-wrapper">
		<StateInspectorHeader class="header">
			<template #default v-if="page">
				<h2>Screenshots: {{ page.data.name }}</h2>
			</template>
			
			<template #info>
				<span class="flag uid">{{ pageUid }}</span>
			</template>
		</StateInspectorHeader>
		
		<div class="image" v-for="image in screenshots">
			<img :src="image" alt="Screenshot" />
		</div>
		
		<acquisit-info-box type="plain" theme="dark">
			<a @click="visitPage" class="visit-page">Visit the page</a>, then click "Take screenshot" or press Ctrl+Shift+S to replace this screenshot.
		</acquisit-info-box>
	</div>
</template>

<script setup lang="ts">
	import { computed } from 'vue'
	import { useScreenshotsStore } from '@/stores/screenshots'
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { useProtocolStore } from '@/stores/protocol'
	import { EventBus } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useRouter } from 'vue-router'
	import { useAPIStore } from '@/stores/api'
	
	const props = defineProps({
		pageUid: {
			type: String,
			required: true
		}
	})
	
	const $router = useRouter()
	
	const screenshots = computed(() => useScreenshotsStore().screenshots[props.pageUid])
	const page = computed(() => useProtocolStore().pages_by_uid[props.pageUid] ?? undefined)
	
	const visitPage = () => {
		EventBus.$emit('inspector:close')
		
		$router.push({
			name: 'page',
			params: {
				token: useAPIStore().token,
				page: props.pageUid
			}
		})
	}
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-state-inspector-screenshots-view {
	
		.image {
			background: color("dark-background");
			border-radius: 12px;
			text-align: center;
			margin-bottom: 24px;
			
			img {
				object-fit: contain;
				object-position: center;
				max-width: 100%;
			}
		}
		
		a.visit-page {
			color: #fff;
			font-weight: 600;
			text-decoration: underline !important;
			cursor: pointer;
		}
	}
</style>