import type { UILabel, UILabelOptional, ModalCompound } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

export type Signature = string|null
export type SignatureType<T extends string> = {
	type: T,
	/**
	 * Configuration for the button to choose this signature type
	 */
	choice_button: {
		icon?: string,
		title: UILabel,
		subtitle?: UILabelOptional
	},
	reset_label?: UILabelOptional
	/**
	 * @deprecated Use reset_label instead
	 */
	refresh_button_label: string|null|undefined
	[prop: string]: any
}

export type SignatureTypeBankID = SignatureType<'bankid'|'bankid_mobile'> & {
	success_label: UILabel
	error_label: UILabel
}

export type SignatureTypeManual = SignatureType<'manual'> & {
	placeholder_label: UILabelOptional
}

export type AnySignatureType = SignatureTypeManual | SignatureTypeBankID

export interface ParsedSignature {
	type: string
}

export interface ParsedBankIDSignature extends ParsedSignature {
	name: string|null
	person_number: string|null
	timestamp: number|null
}

export interface ParsedImageSignature extends ParsedSignature {
	datauri: string
}

export const isSignatureTypeBankID = (sth): sth is SignatureTypeBankID => sth?.type == 'bankid' || sth?.type == 'bankid_mobile'
export const isSignatureTypeManual = (sth): sth is SignatureTypeManual => sth?.type == 'manual'

export const isParsedBankIDSignature = (sth): sth is ParsedBankIDSignature => sth?.type == 'bankid' && sth.name
export const isParsedImageSignature = (sth): sth is ParsedImageSignature => sth?.type == 'image' && sth.datauri

/**
 * A configuration for signing on behalf of someone else
 */
export interface SignatureOnBehalfCompound {
	init_header_label: UILabel
	init_description_label: UILabelOptional
	init_label: UILabel
	name_label: UILabel
	info_label: UILabelOptional
	explanation_label: UILabelOptional
	signing_on_behalf_label: UILabelOptional
	select_persons_label: UILabelOptional
	selection_confirmation_modal: ModalCompound|null
	signed_label: UILabelOptional
	signatures_completed_label: UILabelOptional
}

export interface SignaturePowerOfAttorneyCompound {
	type: string
}

export interface SignaturePowerOfAttorneyImageOnlyCompound extends SignaturePowerOfAttorneyCompound {
	type: 'image_only'
	upload_image_label: UILabel
	explanation_label: UILabelOptional
}

export interface SignaturePowerOfAttorneyQuestionCompound extends SignaturePowerOfAttorneyCompound {
	type: 'question'
	question_label: UILabel
	yes_label: UILabel
	no_label: UILabel
	power_of_attorney_required_label: UILabelOptional
}