<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-condition-tester default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>Condition Tester</h2>
			</template>
		
			<template #actions>
		
			</template>
		</StateInspectorHeader>
		
		<div class="form">
			<acquisit-textarea v-model="conditionString"
							   theme="dark"
			                   color="blue"
							   label="Condition" />
			
			<acquisit-button color-container="#080a0e"
							 color="blue"
							 @click="run"
			                 icon="acq/send"
			                 icon-position="top"
							 label="Run" />
		</div>
		
		<div class="result">
			<h5>Result:</h5>
		
			<span class="true" v-if="result === true">
				true
			</span>
		
			<span class="false" v-else-if="result === false">
				false
			</span>
		
			<span class="null" v-else-if="result === undefined">
				–
			</span>
			
			<span class="error" v-else>
				{{ result }}
			</span>
		</div>
		
		<div class="condition-debug" v-if="lastCondition">
			<StateInspectorJsonView :json="lastCondition" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref, toValue } from 'vue'
	import { useInjectedComponentsStore } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { evaluateCondition, parseStringIntoExpression } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
	import type { EvaluationContext, Expression } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { useComponentsStore } from '@/stores/components'
	import { useProtocolStore } from '@/stores/protocol'
	import { usePersonsStore } from '@/stores/persons'
	import { useProductsStore } from '@/stores/products'
	import StateInspectorJsonView from '@ui/dev/StateInspectorJsonView.vue'
	
	const conditionString = ref('')
	const result = ref<any>(undefined)
	
	const componentsStore = useComponentsStore()
	const protocolStore = useProtocolStore()
	const personsStore = usePersonsStore()
	const productsStore = useProductsStore()
	
	const lastCondition = ref<Expression|undefined>()
	
	const injectedComponentsStore = useInjectedComponentsStore()
	
	const run = () => {
		try {
			lastCondition.value = parseStringIntoExpression(conditionString.value) ?? undefined
			
			const context: EvaluationContext = {
				componentsByUID: toValue(injectedComponentsStore.byUID),
				pagesByUID: protocolStore.pages_by_uid,
				personsByID: personsStore.by_id,
				productsByUID: productsStore.by_uid,
				scope: {}
			}
			
			result.value = evaluateCondition(lastCondition.value!, context)
		} catch (e: any) {
			result.value = e.message
			console.error(e)
		}
	}
</script>

<style lang="scss">
	@import '@/assets/mixins.scss';
	
	.acquisit-condition-tester {
	 
		.form {
			display: flex;
			align-items: stretch;
			gap: 16px;
			
			.acquisit-textarea {
				flex: 1;
				
				textarea {
					background: transparent;
					color: #fff;
				}
				
				.label {
					background: #000;
				}
			}
			
			.acquisit-button {
				display: block;
				
				.wrapper {
					box-sizing: border-box;
					height: 100%;
				}
			}
		}
		
		.result {
			display: flex;
			align-items: center;
			gap: 8px;
			margin-top: 24px;
			
			h5 {
				font-weight: 600;
			}
			
			.true {
				background: darken(color("green"), 10%);
			}
			
			.false {
				background: darken(color("red"), 10%);
			}
			
			.true,
			.false,
			.null {
				display: block;
				padding: 2px 8px;
				border-radius: 6px;
				font-weight: 600;
			}
		}
		
		.condition-debug {
			margin-top: 24px;
		}
	}
</style>