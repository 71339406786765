<template>
	<div class="dev-menu">
		<acquisit-button class="dev-icon" uid="dev-menu-trigger" icon="acq/bone" type="icon" :size="26" :icon-size="24" :color="props.color" @click="onClick" />
		
		<transition name="scale">
			<div class="settings-wrapper" v-if="isMenuOpen">
				<div class="arrow"></div>
				
				<li @click="onClickToggleCreatePagesPDF" :class="[{ true: isCreatePagesPDFEnabled, false: !isCreatePagesPDFEnabled }]">
					<span class="label">Pages PDF</span>
					
					<v-svg class="check-icon" file="acq/checkmark" v-if="isCreatePagesPDFEnabled"/>
					<v-svg class="check-icon" file="acq/clear" v-if="!isCreatePagesPDFEnabled"/>
				</li>
				
				<li @click="onClickToggleBackupEnabled" :class="[{ true: isBackupEnabled, false: !isBackupEnabled }]">
					<span class="label">Backup</span>
					<v-svg class="check-icon" file="acq/checkmark" v-if="isBackupEnabled"/>
					<v-svg class="check-icon" file="acq/clear" v-if="!isBackupEnabled"/>
				</li>
				
				<li class="divider"></li>
				
				<li @click="takeScreenshot" :class="[{ disabled: !isCreatePagesPDFEnabled }]">
					<span class="label">Take Screenshot</span>
					<span class="shortcut">
						<span class="key">Ctrl</span>
						<span class="key">Shift</span>
						<span class="key">S</span>
					</span>
				</li>
				
				<li @click="openBrandingModal">
					<span class="label">Edit Branding…</span>
				</li>
				
				<li class="divider"></li>
				
				<template v-if="isSweetDeskVisible">
					<li @click="openSweetDesk">
						<span class="label">Open in SweetDesk…</span>
					</li>
					
				</template>
				
				<li @click="openVersionModal">
					<span class="label">Show versions…</span>
					<span class="shortcut">
						<span class="key">Ctrl</span>
						<span class="key">Shift</span>
						<span class="key">V</span>
					</span>
				</li>
				
				<li class="divider"></li>
				
				<li @click="exportPDF(false)">
					<span class="label">Export PDF…</span>
					<span class="shortcut">
						<span class="key">Ctrl</span>
						<span class="key">P</span>
					</span>
				</li>
				
				<li @click="openLogs">
					<span class="label">View Logs…</span>
					<span class="shortcut">
						<span class="key">Ctrl</span>
						<span class="key">L</span>
					</span>
				</li>
				
				<li @click="openSubmissionInspector">
					<span class="label">View Submission…</span>
					<span class="shortcut">
						<span class="key">Ctrl</span>
						<span class="key">K</span>
					</span>
				</li>
				
				<li @click="openStateInspector">
					<span class="label">View JSON…</span>
					<span class="shortcut">
						<span class="key">Ctrl</span>
						<span class="key">J</span>
					</span>
				</li>
			</div>
		</transition>
		
		<StateInspector ref="$stateInspector" @close="closeInspector" :is-open="isStateInspectorOpen" />
		
		<acquisit-modal ref="$brandingModal" class="branding-debug" type="sheet" title="Branding" show_close_button>
			<div class="colors">
				<div class="color primary">
					<div class="preview" :style="{ background: color.primary.value.cssHSLA, color: color.textPrimaryContrast.value.cssHSLA }">
						<h3>Primary</h3>
					</div>
					
					<acquisit-input label="Hex" :modelValue="debugBranding.primary.hex" @update:modelValue="updateColor('primary', $event)" />
					
					<div class="lines">
						<div class="line hue">
							<label><b>H</b>ue:</label>
							<span>{{ color.primary.value.hsl.h }}</span>
						</div>
						
						<div class="line hue">
							<label><b>S</b>at:</label>
							<span>{{ color.primary.value.hsl.s }}</span>
						</div>
						
						<div class="line hue">
							<label><b>L</b>ight:</label>
							<span>{{ color.primary.value.hsl.l }}</span>
						</div>
						
						<div :class="['line', 'is-red', 'boolean', { true: color.primary.value.isRedish() }]">
							<label>Red?</label>
							<v-svg :file="color.primary.value.isRedish() ? 'acq/checkmark' : 'acq/clear'" />
						</div>
						
						<div :class="['line', 'is-green', 'boolean', { true: color.primary.value.isGreenish() }]">
							<label>Green?</label>
							<v-svg :file="color.primary.value.isGreenish() ? 'acq/checkmark' : 'acq/clear'" />
						</div>
					</div>
				</div>
				
				<div class="color secondary">
					<div class="preview" :style="{ background: color.secondary.value.cssHSLA, color: color.textSecondaryContrast.value.cssHSLA }">
						<h3>Secondary</h3>
					</div>
					
					<acquisit-input label="Hex" :modelValue="debugBranding.secondary.hex" @update:modelValue="updateColor('secondary', $event)" />
					
					<div class="lines">
						<div class="line hue">
							<label><b>H</b>ue:</label>
							<span>{{ color.secondary.value.hsl.h }}</span>
						</div>
						
						<div class="line hue">
							<label><b>S</b>at:</label>
							<span>{{ color.secondary.value.hsl.s }}</span>
						</div>
						
						<div class="line hue">
							<label><b>L</b>ight:</label>
							<span>{{ color.secondary.value.hsl.l }}</span>
						</div>
						
						<div :class="['line', 'is-red', 'boolean', { true: color.secondary.value.isRedish() }]">
							<label>Red?</label>
							<v-svg :file="color.secondary.value.isRedish() ? 'acq/checkmark' : 'acq/clear'" />
						</div>
						
						<div :class="['line', 'is-green', 'boolean', { true: color.secondary.value.isGreenish() }]">
							<label>Green?</label>
							<v-svg :file="color.secondary.value.isGreenish() ? 'acq/checkmark' : 'acq/clear'" />
						</div>
					</div>
				</div>
			</div>
		</acquisit-modal>
		
		<acquisit-modal ref="$versionModal" class="version-debug" type="modal" title="Version" show_close_button>
			<div class="versions">
				<h3>Runtime</h3>
				
				<div class="version">
					<label>Acquisit:</label>
					<span class="value">{{ runningBuild }}</span>
				</div>
				
				<div class="version">
					<label>AcquisitUI:</label>
					<span class="value">{{ uiCurrentVersion }}</span>
				</div>
				
				<div class="version">
					<label>Origin:</label>
					<span class="value">{{ origin }}</span>
				</div>
				
				<div class="version">
					<label>Backend:</label>
					<span class="value">
						{{ backendLabel }}
						<span class="url">{{ backendURL }}</span>
					</span>
				</div>
				
				<h3>Backup</h3>
				
				<div class="version">
					<label>Acquisit:</label>
					<span class="value">{{ build }}</span>
				</div>
				
				<div class="version">
					<label>AcquisitUI:</label>
					<span class="value">{{ ui }}</span>
				</div>
			</div>
		</acquisit-modal>
		
		<Toast ref="screenshotToast" content="Screenshot saved to store" type="info" />
	</div>
</template>

<script setup lang="ts">
	import { computed, nextTick, reactive, ref } from 'vue'
	import { useColor, useColorProps, useEventListener, type Color } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { useLogger, VERSION as ACQUISIT_UI_VERSION } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useProtocolStore } from '@/stores/protocol'
	import { storeToRefs } from 'pinia'
	import { useGenericStore } from '@/stores/generic'
	import { Domain, DomainRouter } from '@/lib/domain-router'
	import { useScreenshotsStore } from '@/stores/screenshots'
	import { errorMessage } from '@/lib/ui'
	import { useBackup } from '@/stores/backup'
	import { useBrandingStore } from '@/stores/branding'
	import { usePageStore } from '@/stores/page'
	// @ts-ignore
	import StateInspector from '@ui/dev/StateInspector.vue'
	import Toast from '@ui/core/Toast.vue'
	import type { Modal } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useAPIStore } from '@/stores/api'
	import { useBackend } from '@/lib/backend'
	
	const props = defineProps({
		...useColorProps()
	})
	
	const color = useColor(props)
	const log = useLogger('components/ui/dev/DevMenu', useLogger.COLOR_COMPONENT)
	
	const $stateInspector = ref<typeof StateInspector|null>(),
		$screenshotToast = ref<typeof Toast|null>(),
		$brandingModal = ref<typeof Modal|null>(),
		$versionModal = ref<typeof Modal|null>()
	
	const protocolStore = useProtocolStore()
	const { create_pages_pdf: isCreatePagesPDFEnabled } = storeToRefs(protocolStore)
	
	const genericStore = useGenericStore()
	const { backup_enabled: isBackupEnabled, ui, build, origin } = storeToRefs(genericStore)
	const runningBuild = import.meta.env.BUILD
	
	const pageStore = usePageStore()
	const { current_uid: currentPageUID } = storeToRefs(pageStore)
	
	const apiStore = useAPIStore()
	const backend = useBackend()
	
	const uiCurrentVersion = ACQUISIT_UI_VERSION

	const backendLabel = computed(() => {
		return backend.NAME
	})
	
	const backendURL = computed(() => {
		return backend.getBaseURL({})
	})
	
	const debugBranding = reactive({
		primary: {
			hex: color.primary.value.cssHex as string|null|undefined,
		},
		secondary: {
			hex: color.secondary.value.cssHex as string|null|undefined
		}
	})
	
	const isMenuOpen = ref(false)
	const isStateInspectorOpen = ref(false)
	
	const isSweetDeskVisible = (() => {
		return (
			window.location.hostname.indexOf('acquisit.meglerfront') > -1 ||
		    window.location.hostname.indexOf('acquisit.staging.meglerfront') > -1
		) && !apiStore.isPreview
	})()
	
	const onClick = (e: Event) => {
		e.stopPropagation()
		toggleMenu()
	}
	
	const onClickToggleBackupEnabled = (e) => {
		e.stopPropagation()
		genericStore.setBackupEnabled(!isBackupEnabled.value)
	}
	
	const onClickToggleCreatePagesPDF = (e) => {
		e.stopPropagation()
		protocolStore.setCreatePagesPDF(!isCreatePagesPDFEnabled.value)
	}
	
	const onOutsideClick = () => {
		closeMenu()
	}
	
	const onKeyUp = (e: KeyboardEvent) => {
		let consumed = false
		
		if (e.ctrlKey) {
			const handlers = {
				'Space': () => toggleMenu(),
				'KeyJ': () => openStateInspector(),
				'KeyK': () => openSubmissionInspector(),
				'KeyL': () => openLogs(),
				'KeyP': () => exportPDF(),
				'KeyB': () => {
					printBackup()
					alert('Backup has been printed to the console')
				},
				'KeyS': () => {
					if (e.shiftKey) {
						takeScreenshot()
						return true
					}
					
					return false
				},
				'KeyV': () => {
					if (e.shiftKey) {
						openVersionModal()
					}
				}
			}
			
			if (handlers[e.code]) {
				const result = handlers[e.code](e)
				
				if (result !== false) {
					consumed = true
				}
			}
		} else if (e.key == 'Escape') {
			closeInspector()
			closeMenu()
		}
		
		if (consumed) {
			e.preventDefault()
		}
	}
	
	const toggleMenu = () => {
		isMenuOpen.value = !isMenuOpen.value
	}
	
	const openMenu = () => {
		isMenuOpen.value = true
	}
	
	const closeMenu = () => {
		isMenuOpen.value = false
	}
	
	const openStateInspector = async () => {
		isStateInspectorOpen.value = true
		closeMenu()
		
		await nextTick()
		await $stateInspector.value?.switchToPageDefinition(currentPageUID.value)
	}
	
	const openLogs = async () => {
		isStateInspectorOpen.value = true
		closeMenu()
		
		await nextTick()
		await $stateInspector.value?.switchToLogs()
	}
	
	const openSubmissionInspector = async () => {
		isStateInspectorOpen.value = true
		closeMenu()
		
		await nextTick()
		await $stateInspector.value?.switchToSubmission()
	}
	
	const closeInspector = async () => {
		isStateInspectorOpen.value = false
	}
	
	const openSweetDesk = () => {
		if (isSweetDeskVisible) {
			let domain = DomainRouter.get(Domain.SWEETDESK),
				clientShort = protocolStore.client!.short!,
				orderType = protocolStore.order!.type!,
				orderID = String(protocolStore.order!.external_id)
			
			window.open(domain.withPath(['orders', 'search', clientShort, orderType, orderID]))
		}
	}
	
	const exportPDF = async (force: boolean = false) => {
		// @ts-ignore
		const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || typeof InstallTrigger !== 'undefined'
		
		if (!isFirefox && !force) {
			if (confirm('Generating PDFs on the fly only works reliably in Firefox. Do you want to try anyway?')) {
				await exportPDF(true)
			}
			
			return false
		}
		
		log.info('Generating PDF from current screenshots…')
		
		const dataURI = await useScreenshotsStore().createPDF()
		window.open(dataURI as string, '_blank')
	}
	
	const takeScreenshot = async () => {
		if (!isCreatePagesPDFEnabled.value) {
			await errorMessage('Pages PDF is disabled')
		} else if (currentPageUID.value) {
			await useScreenshotsStore().takeForPage(currentPageUID.value)
			
			if ($screenshotToast.value) {
				$screenshotToast.value.open(3000)
			} else {
				alert('Screenshot stored')
			}
		} else {
			await errorMessage('There is no current page set.')
		}
	}
	
	const openBrandingModal = () => {
		$brandingModal.value?.openModal()
	}
	
	const openVersionModal = () => {
		$versionModal.value?.openModal()
	}
	
	const printBackup = () => {
		const backup = useBackup()
		const back_up = backup.create(),
			back_up_json = JSON.stringify(back_up)
		
		log.always.log('BACKUP', back_up)
		log.always.log('BACKUP JSON', back_up_json)
		log.always.info('BACKUP SIZE', back_up_json.length / 1024, 'kb')
	}
	
	const updateColor = (type: string, hex: string) => {
		const c = color.parse(hex)
		const brandingStore = useBrandingStore()
		
		if (type == 'primary') {
			brandingStore.setColors(c, brandingStore.color.secondary as Color)
		} else if (type == 'secondary') {
			brandingStore.setColors(brandingStore.color.primary as Color, c)
		} else {
			throw new Error('Unknown type: ' + type)
		}
	}
	
	useEventListener(window, 'click', onOutsideClick)
	useEventListener(document, 'keyup', onKeyUp)
	
	defineExpose({
		openMenu,
		closeMenu,
		toggleMenu,
		openStateInspector,
		openSubmissionInspector,
		closeInspector
	})
</script>

<style lang="scss" scoped>
	@import '@/assets/mixins.scss';
	
	.dev-menu {
		@include unselectable;
		position: relative;
		z-index: 999;
		
		.icon {
			position: relative;
			height: 28px;
			width: 28px;
		}
	}
	
	.settings-wrapper {
		position: absolute;
		top: 50px;
		right: 6px;
		width: 256px;
		
		border-radius: 12px;
		
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16),
					0px 4px 4px rgba(0, 0, 0, 0.05),
					0px 3px 8px rgba(0, 0, 0, 0.1);
		
		background: rgba(#272730, 0.94);
		-webkit-backdrop-filter: blur(6px);
		backdrop-filter: blur(6px);
		
		padding: {
			top: 12px;
			bottom: 12px;
		}
		
		transform: translate(0, 0);
		
		.arrow {
			content:'';
			width: 0px;
			height: 0px;
			
			position: absolute;
			top: -10px;
			right: 9px;
			
			border: 5px solid transparent;
			border-bottom-color: rgba(color("dark"), 0.82);
			
			opacity: 1;
		}
		
		li {
			display: flex;
			align-items: center;
			width: 100%;
			min-height: 22px + 2 * 8px;
			
			list-style: none;
			box-sizing: border-box;
			
			padding: {
				left: 24px;
				right: 20px;
				top: 8px;
				bottom: 8px;
			}
			
			background: transparent;
			color: rgba(#fff, 1.0);
			cursor: pointer;
			
			@include media(mobile) {
				min-height: 22px + 2 * 12px;
			}
			
			&:hover {
				background: rgba(#fff, 0.17);
				color: #fff;
			}
			
			.label {
				display: block;
				flex-grow: 1;
				color: inherit;
			}
			
			.check-icon {
				height: 22px;
				width: 22px;
			}
			
			.shortcut {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 4px;
				
				width: 42px;
				font-size: 0.8rem;
				opacity: 0.67;
				
				.key {
					padding: 2px;
					border: 1px solid rgba(#fff, 0.67);
					border-radius: 4px;
				}
			}
			
			&.true {
				color: color("green");
				
				&:hover {
					background: rgba(color("green"), 0.24);
					color: color("green");
				}
			}
			
			&.false {
				color: color("red");
				
				&:hover {
					background: rgba(color("red"), 0.24);
					color: color("red");
				}
			}
			
			&.disabled {
				pointer-events: none;
				opacity: 0.4;
			}
			
			&.divider {
				height: 1px;
				min-height: 0px;
				padding: 0;
				
				margin: 8px 0px;
				
				background: rgba(#fff, 0.13);
			}
		}
	}
	
	.data-submenu {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		height: 64px;
		
		li {
			font-size: 1rem;
			color: #fff;
			justify-content: center;
			cursor: pointer;
			white-space: nowrap;
			margin: 0 10px;
			padding: 10px;
			border-radius: 4px;
			
			&.selected {
				color: rgba(30, 30, 30, 1);
				background: white;
			}
		}
	}
	
	.data-menu-wrapper {
		position: fixed;
		width: 100%;
		background: rgba(30, 30, 30, 1);
		top: 0;
		left: 0;
		height: 100%;
		overflow: hidden;
		z-index: 999;
		
		.header {
			width: 100%;
			align-items: center;
			box-shadow: 0px 1px 0 white;
			height: 64px;
			transition: all ease 0.3s;
		}
		
		.open {
			height: 128px;
		}
		
		.data-menu {
			display: flex;
			top: 0;
			width: calc(100% - 48px);
			height: 64px;
			align-items: center;
			overflow-y: scroll;
			
			li {
				font-size: 1rem;
				color: #fff;
				justify-content: center;
				cursor: pointer;
				white-space: nowrap;
				margin: 0 10px;
				padding: 10px;
				border-radius: 4px;
				
				&.selected {
					color: rgba(30, 30, 30, 1);
					background: white;
				}
			}
			
			:last-child {
				position: absolute;
				width: 32px;
				height: 32px;
				right: 0;
				margin: auto 10px;
				padding: 0;
				border: none;
			}
		}
		
		.content {
			height: calc(100% - 64px);
			overflow: scroll;
			padding: 20px;
		}
	}
	
	.branding-debug {
		
		:deep(.header) {
			background: none !important;
			color: color("text") !important;
		}
		
		:deep(.modal-wrapper) {
			background: transparent;
		}
		
		.colors {
			display: flex;
			align-items: flex-start;
			
			> .color {
				width: calc(50% - 12px);
				
				.preview {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 48px;
					border-radius: 6px;
					margin-bottom: 24px;
					
					box-shadow: inset 0px 0px 0px 1px rgba(#000, 0.14);
				}
				
				& + .color {
					margin-left: 24px;
				}
				
				h3 {
					font-size: 1.2rem;
					font-weight: 600;
				}
				
				.lines {
					margin-top: 16px;
					line-height: 1.5;
					
					.line {
						padding: {
							top: 4px;
							bottom: 4px;
						}
						
						label {
							color: color("light-grey-blue");
							display: inline-block;
							min-width: 64px;
							
							b {
								font-weight: 900;
							}
						}
						
						> span {
							font-family: font(monospace);
						}
						
						.svg {
							display: inline-block;
							
							width: 20px;
							height: 20px;
							vertical-align: middle;
							margin-top: -6px;
							margin-left: -4px;
						}
						
						&.boolean {
							color: color("red");
							
							&.true {
								color: color("green");
							}
						}
					}
				}
			}
		}
	}
	
	.version-debug {
		
		.versions {
			display: flex;
			flex-direction: column;
			gap: 12px;
			
			h3 {
				font-size: 1.3rem;
				font-weight: 700;
				text-align: center;
			}
			
			.version {
				display: flex;
				align-items: baseline;
				gap: 8px;
				
				label {
					font-weight: 600;
					width: 45%;
					text-align: right;
				}
				
				.value {
					width: 55%;
					font-family: font(monospace);
					
					.url {
						display: block;
						font-family: font(main);
						color: color("light-grey-blue");
					}
				}
				
				+ h3 {
					margin-top: 24px;
				}
			}
		}
	}
	
	.scale-enter-active,
	.scale-leave-active {
		transition: all 0.2s ease;
	}
	
	.scale-enter-from {
		transform-origin: top;
		transform: scaleY(0.01);
		opacity: 0;
	}
	
	.scale-leave-to {
		transform-origin: top;
		transform: translateY(-42px) scaleY(0.1);
		opacity: 0;
	}
	
	.scale-leave-from,
	.scale-enter-to {
		transform-origin: top;
		transform: scaleY(1);
		opacity: 1;
	}
</style>
