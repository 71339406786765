import type { Page } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { normalizePage } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

/**
 * Normalize an array of pages
 * It modifies the original objects in the array.
 * Returns the original array typed to Page[] for TypeScript convenience
 *
 * @param {Record<string, any>[]} pages
 *
 * @returns {Page[]}
 */
export const normalizePages = (pages: Record<string, any>[]): Page[] => {
	for (let page of pages) {
		normalizePage(page)
	}
	
	return pages as Page[]
}