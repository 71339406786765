import { createRouter, createWebHistory } from 'vue-router'
import { EventBus } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useUIStore } from '@/stores/ui'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	
	routes: [
		{
			path: '/',
			name: 'index',
			component: () => import('../views/Index.vue'),
		},
		
		{
			path: '/:ticketID/:password',
			name: 'loader',
			component: () => import('../views/protocol/ProtocolLoader.vue'),
			
			props: $route => ({
				ticketID: $route.params.ticketID,
				password: $route.params.password
			})
		},
		
		{
			path: '/p/:token',
			name: 'protocol',
			component: () => import('../views/protocol/Protocol.vue'),
			
			props: $route => ({
				fetch_backup_enabled: $route.query.backup != '0',
				next_page_uid: $route.query.next || null,
				token: $route.params.token
			}),
			
			children: [
				{
					path: 'intro',
					name: 'intro',
					component: () => import('../views/protocol/Intro.vue'),
					
					props: $route => ({
						token: $route.params.token
					})
				},
				
				{
					path: 'outro',
					name: 'outro',
					component: () => import('../views/protocol/Outro.vue'),
					
					props: $route => ({
						token: $route.params.token
					})
				},
				
				{
					path: ':page',
					name: 'page',
					component: () => import('../views/protocol/Page.vue'),
					
					props: $route => ({
						token: $route.params.token,
						page: $route.params.page
					})
				}
			]
		},
		
		{
			path: '/preview/:token',
			name: 'preview',
			component: () => import('../views/protocol/Preview.vue'),
			
			props: $route => ({
				previewToken: $route.params.token,
				typeID: $route.params.typeID,
				versionID: $route.params.versionID,
				historyID: $route.params.historyID,
				apiToken: $route.query.s
			}),
			
			children: [
				{
					path: '',
					name: 'protocol-preview',
					component: () => import('../views/protocol/Protocol.vue'),
					
					props: $route => ({
						fetch_backup_enabled: false,
						token: $route.params.token,
					}),
					
					children: [
						{
							path: 'intro',
							name: 'intro-preview',
							component: () => import('../views/protocol/Intro.vue'),
							
							props: $route => ({
								token: $route.params.token,
							})
						},
						
						{
							path: 'outro',
							name: 'outro-preview',
							component: () => import('../views/protocol/Outro.vue'),
							
							props: $route => ({
								token: $route.params.token,
							})
						},
						
						{
							path: ':page',
							name: 'page-preview',
							component: () => import('../views/protocol/Page.vue'),
							
							props: $route => ({
								token: $route.params.token,
								page: $route.params.page,
							})
						}
					]
				},
			]
		},
		
		{
			path: '/preview/history/:token',
			name: 'history-preview',
			component: () => import('../views/protocol/Preview.vue'),
			
			props: $route => ({
				historyID: $route.params.token,
				apiToken: $route.query.s
			}),
			
			children: [
				{
					path: '',
					name: 'protocol-history-preview',
					component: () => import('../views/protocol/Protocol.vue'),
					
					props: $route => ({
						fetch_backup_enabled: false,
						token: $route.params.token,
					}),
					
					children: [
						{
							path: 'intro',
							name: 'intro-history-preview',
							component: () => import('../views/protocol/Intro.vue'),
							
							props: $route => ({
								token: $route.params.token,
							})
						},
						
						{
							path: 'outro',
							name: 'outro-history-preview',
							component: () => import('../views/protocol/Outro.vue'),
							
							props: $route => ({
								token: $route.params.token,
							})
						},
						
						{
							path: ':page',
							name: 'page-history-preview',
							component: () => import('../views/protocol/Page.vue'),
							
							props: $route => ({
								token: $route.params.token,
								page: $route.params.page,
							})
						}
					]
				},
			]
		},
		
		{
			path: '/:path(.*)*',
			name: 'not-found',
			component: () => import('../views/GlobalError.vue'),
			props: {
				type: 'not-found'
			}
		},
	],
})

router.beforeEach((to, from, next) => {
	useUIStore().resetMetaTitle()
	EventBus.$emit('beforeRouteChange', to)
	next()
})

router.afterEach((to) => {
	EventBus.$emit('routeChanged', to)
})

export default router
