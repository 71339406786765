import { defineStore } from 'pinia'
import { toRaw } from 'vue'
import { useLogger, useBrandingStore as useAcquisitUIBrandingStore } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { Color } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
import { cast, clone } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'

const log = useLogger('stores/branding', 'orange')

export interface BrandingStoreState {
	color: {
		primary: Color|null
		secondary: Color|null
	}
	
	logo: {
		primary: string|null
		secondary: string|null
	}
}

const appendSuffix = (url: string): string => {
	if (url) {
		if (url.indexOf('req=acquisit') > -1) {
			return url
		}
		
		let cacheBusterTimestamp = String(+new Date()).slice(-5),
			suffix = 'ts=' + cacheBusterTimestamp + '&req=acquisit'
		
		if (url.indexOf('?') > -1) {
			return url + '&' + suffix
		} else {
			return url + '?' + suffix
		}
	}
	
	return url
}

export const useBrandingStore = defineStore({
	id: 'branding',
	
	state: () => cast<BrandingStoreState>({
		color: {
			primary: null,
			secondary: null
		},
		
		logo: {
			primary: null,
			secondary: null
		}
	}),
	
	getters: {
		logo_suffixed: state => {
			let logos = clone(state.logo)
			
			logos.primary = appendSuffix(logos.primary!)
			logos.secondary = appendSuffix(logos.secondary!)
			
			return logos
		},
		
		backup: state => ({
			color: {
				primary: state.color.primary?.cssHSLA,
				secondary: state.color.secondary?.cssHSLA
			},
			
			logo: toRaw(state.logo)
		})
	},
	
	actions: {
		set(branding: { color: { primary: string|Color, secondary: string|Color }, logo: { primary: string, secondary: string } }) {
			this.color.primary = branding.color.primary instanceof Color ? branding.color.primary : Color.fromString(branding.color.primary)
			this.color.secondary = branding.color.secondary instanceof Color ? branding.color.secondary : Color.fromString(branding.color.secondary)
			this.logo = branding.logo
			
			const acquisitUIBrandingStore = useAcquisitUIBrandingStore()
			acquisitUIBrandingStore.setColors(this.color.primary as Color, this.color.secondary as Color)
			acquisitUIBrandingStore.setLogos(this.logo.primary!, this.logo.secondary!)
		},
		
		setColors(primary: Color, secondary: Color) {
			this.color.primary = primary
			this.color.secondary = secondary
			
			const acquisitUIBrandingStore = useAcquisitUIBrandingStore()
			acquisitUIBrandingStore.setColors(primary, secondary)
		},
		
		setMetaThemeColor(color) {
			let meta = document.querySelector('meta[name=theme-color]')
			
			if (meta && color) {
				if (color instanceof Color) {
					meta.setAttribute('content', color.cssHex)
				} else {
					meta.setAttribute('content', color)
				}
			}
		},
		
		restoreBackup(backup: Record<string, any>) {
			if (!backup.color || !backup.logo) {
				log.warn('Invalid branding backup', backup)
				throw new Error('Invalid backup: Incomplete branding')
			}
			
			this.color.primary = Color.fromString(backup.color.primary)
			this.color.secondary = Color.fromString(backup.color.secondary)
			
			this.logo.primary = backup.logo.primary
			this.logo.secondary = backup.logo.secondary
			
			const acquisitUIBrandingStore = useAcquisitUIBrandingStore()
			acquisitUIBrandingStore.setColors(this.color.primary as Color, this.color.secondary as Color)
			acquisitUIBrandingStore.setLogos(this.logo.primary!, this.logo.secondary!)
		}
	}
})