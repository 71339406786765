import { defineStore } from 'pinia'

export enum LogLevel {
	ERROR = 'e',
	WARNING = 'w',
	INFO = 'i'
}

export type LogMessageArgument = (string | number | boolean)

export interface LogMessage {
	time: number
	level: LogLevel
	args: LogMessageArgument[]
}

export interface LogEvent {
	time: number
	event: string
}

export const isLogMessage = (sth: any): sth is LogMessage =>
	sth !== null && sth !== undefined && sth.time && sth.level

export const isLogEvent = (sth: any): sth is LogEvent =>
	sth !== null && sth != undefined && sth.time && sth.event

/**
 * Convert an array of log arguments into an array safe for JSON storage
 */
const convertArguments = (args: any[]): LogMessageArgument[] => {
	return args.map(a => {
		if (Array.isArray(a)) {
			return JSON.stringify(convertArguments(a))
		}
		
		switch (typeof(a)) {
			case 'function':
				return 'Function ' + a.name + ', ' + a.length + ' args'
			
			case 'object':
				return JSON.stringify(a)
			
			default:
				return a
		}
	})
}

/**
 * Persistent logging,
 * will be saved with backups
 */
export const useLogsStore = defineStore('logs', {
	state: () => ({
		messages: [] as (LogMessage|LogEvent)[]
	}),
	
	actions: {
		/**
		 * Log an error to the store
		 */
		error(..._: any[]) {
			this.record(LogLevel.ERROR, ..._)
		},
		
		/**
		 * Log an info to the store
		 */
		info(..._: any[]) {
			this.record(LogLevel.INFO, ..._)
		},
		
		/**
		 * Log a warning to the store
		 */
		warning(..._: any[]) {
			this.record(LogLevel.WARNING, ..._)
		},
		
		/**
		 * Log an event
		 */
		event(name: string) {
			this.messages.push({
				time: Math.round(+new Date() / 1000),
				event: name,
			})
		},
		
		/**
		 * Log something to the store
		 */
		record(level: LogLevel, ...args: any[]) {
			this.messages.push({
				time: Math.round(+new Date() / 1000),
				level,
				args: convertArguments(args)
			})
		}
	}
})